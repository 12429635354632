import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoaderService } from './../../core/service/loader/loader.service';
import { ScreamService } from './../../core/service/screamService/scream.service';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';

@Component({
  selector: 'app-scream-status-confirmation',
  templateUrl: './scream-status-confirmation.component.html',
  styleUrls: ['./scream-status-confirmation.component.scss'],
})
export class ScreamStatusConfirmationComponent {
  screamData!: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private screamService: ScreamService,
    private loaderService: LoaderService,
    private snackbarService: SnackbarService,
    private matDialogRef: MatDialog
  ) {
    this.screamData = data;
  }

  updateScreamStatus() {
    this.loaderService.show();
    this.screamService.updateScreamStatus(this.screamData).subscribe({
      next: (response: any) => {
        {
          if (response.body.responseCode === 200) {
            this.snackbarService.openSnackBar(
              'Scream resolved successfully',
              'success-snackbar'
            );
          }
          this.loaderService.hide();
          this.closeDialog();
        }
      },
      error: (error: any) => {
        if (error.error.responseCode === 400) {
          this.snackbarService.openSnackBar(
            error.error.responseMessage,
            'error-snackbar'
          );
        }
        this.loaderService.hide();
        this.closeDialog();
      },
    });
  }

  closeDialog() {
    this.matDialogRef.closeAll();
  }
}
