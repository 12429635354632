import { Injectable } from '@angular/core';
import { AppConfig } from '../../config/app.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponseService {
  private api = AppConfig.getAPIURI() + 'response';
  constructor(private http: HttpClient) {}

  postResponse(request: any, suid: any): Observable<any> {
    return this.http.post(this.api + `/post-response/${suid}`, request, {
      observe: 'response',
    });
  }
}
