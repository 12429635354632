<div class="container p-4 border">
  <div class="mb-2 p-0">
    <h3 class="d-flex align-items-center fw-500 fs-23">Mark as resolved?</h3>
    <h3 class="mt-3 fs-16 fw-400">
      Do you want to mark the scream as resolved.
    </h3>
  </div>
  <div class="d-flex justify-content-end py-1">
    <button
      mat-raised-button
      class="rounded-pill active-link br-color lato-font-family fw-600 fs-14"
      (click)="closeDialog()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2"
      type="submit"
      #btnFocus="matButton"
      [autofocus]="btnFocus.focus()"
      (click)="updateScreamStatus()"
    >
      Confirm
    </button>
  </div>
</div>
