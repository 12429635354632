import { Component, Input, ViewChild, OnInit } from '@angular/core';
import {
  NgbCarousel,
  NgbCarouselConfig,
  NgbCarouselModule,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { Tile } from '../../core/interfaces/tile';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  providers: [NgbCarouselConfig, NgbCarousel, NgbCarouselModule],
})
export class ImagePreviewComponent implements OnInit {
  @ViewChild('imagePreview') imagePreview!: NgbCarousel;
  @Input() scream!: any;
  blobImage!: string[];
  tiles: Tile[] = [];
  activeIndex!: string;
  blobStorageLink = this.dataTransferService.blobUrl;

  constructor(
    public modalService: NgbModal,
    private readonly dataTransferService: DataTransferService
  ) {}

  ngOnInit() {
    this.getBlobImages();
    this.blobPreview();
  }

  showImagePreviewCarousel(imageUrl: any) {
    this.activeIndex = 'ngb-slide-' + imageUrl;
    this.modalService.open(this.imagePreview, { size: 'lg', centered: true });
  }
  getBlobImages() {
    const imageUrls = (this.scream && this.scream.images) || [];
    const chunks = [];
    let startIndex = 0;
    let endIndex = 2;
    while (startIndex < imageUrls.length) {
      endIndex = imageUrls.indexOf(',', startIndex);
      if (endIndex === -1) {
        endIndex = imageUrls.length;
      }
      const subset = imageUrls.substring(startIndex, endIndex);
      const imageUrl = this.blobStorageLink + subset.trim();
      chunks.push(imageUrl);
      startIndex = endIndex + 1;
    }
    this.blobImage = chunks;
  }

  blobPreview() {
    if (this.blobImage && this.blobImage.length > 0) {
      if (this.blobImage.length === 1) {
        this.tiles = [{ cols: 4, rows: 3, image: this.blobImage[0] }];
      } else if (this.blobImage.length === 2) {
        this.tiles = [
          { cols: 2, rows: 2, image: this.blobImage[0] },
          { cols: 2, rows: 2, image: this.blobImage[1] },
        ];
      } else if (this.blobImage.length === 3) {
        this.tiles = [
          { cols: 2, rows: 2, image: this.blobImage[0] },
          { cols: 2, rows: 1, image: this.blobImage[1] },
          { cols: 2, rows: 1, image: this.blobImage[2] },
        ];
      }
    }
  }
}
