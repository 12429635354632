import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { LoginResponse } from './../../core/interfaces/login-response';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { ScreamService } from './../../core/service/screamService/scream.service';
import { TokenService } from './../../core/service/tokenService/token.service';
import { UserService } from './../../core/service/userService/user.service';
import { AppConfig } from './../../core/config/app.config';
import { LogoutComponent } from '../logout/logout.component';
import { FollowersFollowing } from './../../core/interfaces/followers-following';
import { BrandService } from './../../core/service/brandService/brand.service';

@Component({
  selector: 'app-scream-ups',
  templateUrl: './scream-ups.component.html',
  styleUrls: ['./scream-ups.component.scss'],
})
export class ScreamUpsComponent implements OnInit {
  screamUpShimmer = true;
  userAndBrandScreamups: FollowersFollowing[] = [];
  pageIndex = 0;
  pageSize = 15;
  profile!: any;
  brandProfile!: any;
  totalScreams: any;
  errorMessage: any;
  screamUps: LoginResponse[] = [];
  screamsUpsCount: any;
  error: any;
  filteredUser: any;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  blobURL = AppConfig.getBLOBURI();
  successMessage: any;
  isBusinessLogin: any;
  isUserLogin: any;
  constructor(
    private readonly screamService: ScreamService,
    private brandService: BrandService,
    private readonly tokenService: TokenService,
    public readonly dataTransferService: DataTransferService,
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ScreamUpsComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    this.isUserLogin = this.dataTransferService.isLoggedin();
    this.isBusinessLogin = this.dataTransferService.isBusinessLoggedin();
    if (this.isUserLogin) {
      this.profile = JSON.parse(
        this.dataTransferService.getUserDetails() || ' '
      );
    } else {
      this.brandProfile = JSON.parse(
        this.dataTransferService.getBrandDetails() || ''
      );
    }
    this.getScreamUps();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  navigateToBrandProfile(brandName: string) {
    this.router.navigate([`/brand/${brandName}`]);
    this.dialogRef.close();
  }
  navigateToUserProfile(userName: string) {
    this.router.navigate([`/${userName}`]);
    this.dialogRef.close();
  }
  isBrandVerified(brandDTO: any): boolean {
    return brandDTO && brandDTO.documentVerificationStatus === 'VERIFIED';
  }
  fetchScreamsUps(pageIndex: number, pageSize: number) {
    return this.screamService
      .fetchScreamUps(this.data.user, { pageIndex, pageSize })
      ?.pipe(
        map((data: any) => {
          this.totalScreams = data.body.responseBody.totalItems;

          if (data && data.body && data.body.responseBody) {
            const responseBody = data.body.responseBody;
            const documentVerificationStatusList: any[] = [];
            if (responseBody.userFollowDTO) {
              responseBody.userFollowDTO.forEach(
                (userFollow: {
                  brandDTO: { documentVerificationStatus: any };
                }) => {
                  if (userFollow.brandDTO) {
                    const documentVerificationStatus =
                      userFollow.brandDTO.documentVerificationStatus;
                    if (documentVerificationStatus) {
                      documentVerificationStatusList.push(
                        documentVerificationStatus
                      );
                    }
                  }
                }
              );
            }
            return data.body.responseBody;
          }
        }),
        catchError((error) => {
          this.errorMessage = error.error?.responseMessage;
          return of(this.errorMessage);
        })
      );
  }

  getScreamUps() {
    this.fetchScreamsUps(this.pageIndex, this.pageSize)?.subscribe(
      (screams) => {
        this.screamsUpsCount = screams.totalItems;
        this.userAndBrandScreamups = screams.userFollowDTO;
        const filteredUserIndex = this.userAndBrandScreamups?.findIndex(
          (user: any) =>
            user.loginResponseDTO?.userName === this.profile?.userName
        );
        if (filteredUserIndex !== -1) {
          this.filteredUser = this.userAndBrandScreamups?.splice(
            filteredUserIndex,
            1
          )[0];

          this.userAndBrandScreamups?.unshift(this.filteredUser);
        }
        this.screamUpShimmer = false;
      }
    );
  }
  onScroll() {
    if (this.screamUps.length < this.totalScreams) {
      this.screamUpShimmer = true;
      this.fetchScreamsUps(++this.pageIndex, this.pageSize)?.subscribe(
        (screams) => {
          this.screamUpShimmer = false;
          this.userAndBrandScreamups?.push(...screams?.userFollowDTO);
        }
      );
    }
  }

  follow(user: any) {
    if (user.following) {
      const dialogRef = this.dialog.open(LogoutComponent, {
        width: '300px',
        panelClass: ['border-radius'],
        autoFocus: false,
      });
      this.tokenService.setContent(true);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'unfollow') {
          user.following = false;
          this.userService
            .followOrUnfollowUser(user.uuid)
            .then((data: any) => {
              if (data.responseCode === 201) {
                this.successMessage = data.responseMessage;
              }
            })
            .catch((error) => {
              this.error = error.error?.responseMessage;
              user.following = true;
            });
        }
      });
    } else {
      user.following = true;
      this.userService
        .followOrUnfollowUser(user.uuid)
        .then((data: any) => {
          if (data.responseCode === 200) {
            this.successMessage = data.responseMessage;
          }
        })
        .catch((error) => {
          this.error = error.error?.responseMessage;
          user.following = false;
        });
    }
  }
  followBrand(brand: any) {
    if (brand.following) {
      const dialogRef = this.dialog.open(LogoutComponent, {
        width: '300px',
        panelClass: ['border-radius'],
        autoFocus: false,
      });
      this.tokenService.setContent(true);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'unfollow') {
          brand.following = false;
          this.brandService
            .followOrUnfollowBrand(brand.ubid)
            .then((data: any) => {
              if (data.responseCode === 201) {
                this.successMessage = data.responseMessage;
              }
            })
            .catch((error: any) => {
              this.error = error.error?.responseMessage;
              brand.following = true;
            });
        }
      });
    } else {
      brand.following = true;
      this.brandService
        .followOrUnfollowBrand(brand.ubid)
        .then((data: any) => {
          if (data.responseCode === 200) {
            this.successMessage = data.responseMessage;
          }
        })
        .catch((error: any) => {
          this.error = error.error?.responseMessage;
          brand.following = false;
        });
    }
  }
}
