import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { LoaderService } from './../../core/service/loader/loader.service';
import { TokenService } from './../../core/service/tokenService/token.service';
import { UserService } from './../../core/service/userService/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { OtpTimerService } from './../../core/service/otpTimer/otp-timer.service';
import { Router } from '@angular/router';
import { BrandService } from './../../core/service/brandService/brand.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  currentRoutePath: any;
  errorMessage: any;
  currentState: boolean = true;
  sendForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  response: any;
  userLogin = '/login';
  businessLogin = '/business/login';
  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    public dialog: MatDialog,
    private userService: UserService,
    private brandService: BrandService,
    private tokenService: TokenService,
    public loaderService: LoaderService,
    public snackBarService: SnackbarService,
    private otpTimerService: OtpTimerService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.currentRoutePath = this.router.url;
  }

  closeDialog() {
    this.dialogRef.close(ForgotPasswordComponent);
  }

  requestBody() {
    return {
      email: this.sendForm.value['email'],
      purpose: 'forgot-password',
    };
  }

  sendMail() {
    if (this.sendForm.valid) {
      this.loaderService.show();
      const serviceMethod = this.getCurrentServiceMethod();
      localStorage.setItem(
        'useremail',
        JSON.stringify(this.sendForm.value.email)
      );
      serviceMethod(this.requestBody()).subscribe({
        next: (response) => {
          if (response.body.responseCode === 200) {
            this.loaderService.hide();
            let token = response.body.token;
            this.currentState = false;
            this.otpTimerService.startTimer();
            this.tokenService.setToken(token);
            this.tokenService.setContent(true);
            this.tokenService.setPurpose('forgot-password');
          }
        },
        error: (data) => {
          this.loaderService.hide();
          if (data.status == 0) {
            this.snackBarService.openSnackBar(
              'Internal server error. Please try again!',
              'error-snackbar'
            );
          } else {
            this.snackBarService.openSnackBar(
              data.error.responseMessage,
              'error-snackbar'
            );
          }
        },
      });
    }
  }

  getCurrentServiceMethod(): (brand: any) => Observable<any> {
    if (this.currentRoutePath === this.userLogin) {
      return this.userService.getForgotPasswordOtp.bind(this.userService);
    } else if (this.currentRoutePath === this.businessLogin) {
      return this.brandService.businessForgotPasswordOtp.bind(
        this.brandService
      );
    } else {
      return this.userService.getForgotPasswordOtp.bind(this.userService);
    }
  }
}
