import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BottomNavBarService } from 'src/app/core/service/bottomNavBar/bottom-nav-bar.service';
import { BusinessTimingsService } from 'src/app/core/service/businessTimings/businessTimings.service';
import { FirebaseService } from 'src/app/core/service/firebase.service';
import { HeaderService } from 'src/app/core/service/header/header.service';
import { LoaderService } from 'src/app/core/service/loader/loader.service';

@Component({
  selector: 'app-book-slot',
  templateUrl: './book-slot.component.html',
  styleUrl: './book-slot.component.scss',
})
export class BookSlotComponent implements OnInit {
  slotForm!: FormGroup;

  availableTimes: string[] = [];
  selectedDate: string = '';
  selectedTime: string = '';
  type: any;
  businessName: any;
  appointmentDate: any;
  appointmentTime: any;

  constructor(
    private cd: ChangeDetectorRef,
    private readonly headerService: HeaderService,
    private readonly bottomService: BottomNavBarService,
    private route: ActivatedRoute,
    public firebaseService: FirebaseService,
    private router: Router,
    private businessTimingsService: BusinessTimingsService,
    private loaderService: LoaderService,
  ) {
    this.slotForm = new FormGroup({
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
    });

    // this.generateTimeSlots();
  }
  hideHeader() {
    setTimeout(() => {
      this.headerService.hide();
      this.bottomService.hide();
    }, 0);
  }

  ngOnInit() {
    this.firebaseService.registerServiceWorker();
    //this.getAvailableSlots();

    this.route.queryParams.subscribe((params) => {
      this.type = params['type'];
    });
    this.hideHeader();
    this.slotForm.valueChanges.subscribe((values) => {
      // these are the  user selected time and date from the hrml
      this.selectedDate = values.date;
      this.selectedTime = values.time;
    });

    this.slotForm.get('date')?.valueChanges.subscribe((date) => {
      if (date) {
        this.appointmentDate = this.formatDate(date); // Convert date to YYYY-MM-DD
        this.getAvailableSlots();
      }
    });
  }

  generateTimeSlots() {
    const startHour = 9;
    const endHour = 21;
    //Here we have to set time according to business design because based on business setup the slots and duration time we need to change
    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minutes of ['00', '15', '30', '45']) {
        const amPm = hour < 12 ? 'AM' : 'PM';
        const formattedHour = hour > 12 ? hour - 12 : hour;
        this.availableTimes.push(`${formattedHour}:${minutes} ${amPm}`);
      }
    }
  }
  getAvailableSlots() {
    const businessName = localStorage.getItem('brandName');
    const vari = this.convertAndStoreData(this.slotForm.value);
    console.log(vari);
    this.businessName = businessName ? JSON.parse(businessName) : null;
    this.businessTimingsService
      .getdaySlotsAppointments(this.businessName, this.appointmentDate)
      .subscribe({
        next: (response) => {
          console.log(response);
          if (response.body.responseCode === 200) {
            this.loaderService.hide();
            this.availableTimes = response.body.responseBody.map(
              (time: string) => this.convertTo12HourFormat(time),
            );
          }
        },
        error: (error: any) => {
          this.loaderService.hide();
        },
      });
  }

  formatDate(date: any): string {
    const dateObj = new Date(date);
    return `${String(dateObj.getDate()).padStart(2, '0')}/${String(dateObj.getMonth() + 1).padStart(2, '0')}/${String(dateObj.getFullYear()).slice(-2)}`;
  }

  convertTo12HourFormat(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  filterSingleDate = (date: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date ? date >= today : false;
  };

  clicked() {
    this.convertAndStoreData(this.slotForm.value);
    this.router.navigate(['queue-hospital/doctor'], {
      queryParams: { type: this.type },
    });
  }

  convertAndStoreData(data: any) {
    const dateObj = new Date(data.date);
    const timeParts = data.time.match(/(\d+):(\d+) (\w+)/);

    if (!timeParts) {
      console.error('Invalid time format');
      return;
    }

    let hours = parseInt(timeParts[1], 10);
    const minutes = timeParts[2];
    const period = timeParts[3];

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    this.appointmentDate = `${dateObj.getFullYear()}-${String(
      dateObj.getMonth() + 1,
    ).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;

    const appointmentTime = `${String(hours).padStart(2, '0')}:${minutes}`;

    localStorage.setItem(
      'appointmentDate',
      JSON.stringify(this.appointmentDate),
    );
    localStorage.setItem('appointmentTime', JSON.stringify(appointmentTime));
  }
}
