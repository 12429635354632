<div *ngIf="headerForBusinessBeforeLogin()">
  <mat-toolbar
    class="header-size center p-0 background-white-color w-100 active-link fixed"
  >
    <div
      class="container p-0 d-flex justify-content-between max-width-header header-padding"
    >
      <div
        class="d-flex align-items-center pointer"
        (click)="goToBusinessLanding()"
      >
        <img src="assets/images/ScremerBlue.svg" alt="scremer-logo.svg" />

        <p class="fs-23 fw-500 ms-2 m-0 mt-business">for Business</p>
      </div>
      <div
        class="d-flex justify-content-around align-items-center display-desktop-only"
      >
        <div class="d-flex mx-3 business px-4 py-1 pointer" (click)="claim()">
          <mat-icon class="material-icons-outlined pointer fs-18 mt-2"
            >add_business</mat-icon
          >
          <div class="fw-700 fs-16 lato-font-family">Claim your business</div>
        </div>
        <button
          (click)="businessLogin()"
          mat-raised-button
          class="btn hover-color fw-700 lato-font-family py-2 fs-16"
        >
          <div class="active-link">Log in</div>
        </button>
        <div class="ms-3">
          <button
            (click)="businessSignup()"
            mat-raised-button
            class="btn btn-primary background-color fw-700 fs-16 lato-font-family py-2 white-color"
          >
            Sign up
          </button>
        </div>
      </div>
      <div class="display-mobile-only">
        <mat-icon
          class="fs-24 material-icons-outlined pointer mt-3 carousel-indicators"
          >menu</mat-icon
        >
      </div>
    </div>
  </mat-toolbar>
</div>
<div *ngIf="businessLoggedIn()">
  <mat-toolbar
    class="header-size background-color w-100 px-0 display-none-header display-inline-header fixed"
  >
    <div class="container p-0 pt-2 max-width">
      <div class="d-flex justify-content-between">
        <div
          class="fixed-width col-12 col-sm-4 col-md-3 col-lg-3 d-flex align-items-center pointer"
          (click)="goToBusinessHome()"
        >
          <img src="assets/images/Scremer-Logo.svg" alt="scremer-logo.svg" />
          <p class="fs-23 fw-500 ms-2 m-0 mt-business white-color">
            for Business
          </p>
        </div>
        <div>
          <img
            class="pointer background-white-color profile-image-40 rounded-object"
            (click)="openCard()"
            [src]="this.businessProfileImage"
            alt="Business-Image"
          />
        </div>
      </div>
    </div>
  </mat-toolbar>
  <div *ngIf="isCardOpen" class="container p-0 max-width">
    <div class="d-flex justify-content-end">
      <div class="overlay pointer" (click)="goToLogout()">
        <div class="card-content background-white-color p-3 d-flex">
          <div>
            <span class="material-symbols-outlined"> logout </span>
          </div>
          <div>
            <p class="ms-2 fw-400 fs-16">Logout</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
