import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../core/service/userService/user.service';
import { DataTransferService } from '../../core/service/data-transfer/data-transfer.service';
import { BottomNavBarService } from '../../core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from '../../core/service/header/header.service';
import { LoaderService } from '../../core/service/loader/loader.service';
@Component({
  selector: 'app-welcome-queue',
  templateUrl: './welcome-queue.component.html',
  styleUrls: ['./welcome-queue.component.scss'],
})
export class WelcomeQueueComponent implements OnInit {
  welcomeForm!: FormGroup;

  platformId!: Object;
  isLoggedIn: any;
  isMobileNumberAssociated!: boolean;
  userDetails: any;
  mobileNumber: any;
  brandName: any;
  welcomePayload!: any;
  fuid: any;
  type: any;

  constructor(
    private readonly userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    private dataTransferService: DataTransferService,
    private readonly bottomService: BottomNavBarService,
    private readonly headerService: HeaderService,
    private loaderService: LoaderService,
  ) {}

  ngOnInit() {
    if (!this.router.url?.includes('queue-hospital')) {
      localStorage.clear();
    }
    this.route.queryParams.subscribe((params) => {
      this.fuid = params['flash'];
    });

    this.route.paramMap.subscribe((params) => {
      this.brandName = params.get('brandname');
    });

    this.route.queryParams.subscribe((params) => {
      this.type = params['type'];
    });

    this.welcomeForm = this.createFormGroup();
    this.hideHeader();
    this.checkUserLoginStatus();
  }

  hideHeader() {
    setTimeout(() => {
      this.headerService.hide();
      this.bottomService.hide();
    }, 0);
  }
  createFormGroup(): FormGroup {
    return new FormGroup({
      fullName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z\s]+$/),
        Validators.maxLength(30),
      ]),
      mobileNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10}$'),
      ]),
    });
  }
  onFullNameInput(): void {
    const fullNameControl = this.welcomeForm.get('fullName');
    fullNameControl?.updateValueAndValidity();
  }

  onMobileNumberInput(): void {
    const mobileNumberControl = this.welcomeForm.get('mobileNumber');
    mobileNumberControl?.updateValueAndValidity();
  }

  private checkUserLoginStatus(): void {
    this.isLoggedIn = this.dataTransferService.isLoggedin();

    if (this.isLoggedIn) {
      this.loggedInUser();
    } else {
      this.loggedOutUser();
    }
  }

  private loggedInUser(): void {
    const userDetails = this.dataTransferService.getUserDetails();
    const userDetailsData = JSON.parse(userDetails);

    if (userDetailsData) {
      this.updateWelcomeForm(userDetailsData);
      this.fetchMobileNumberAndUpdateForm();
    } else {
      this.welcomeForm.reset();
      this.isMobileNumberAssociated = false;
    }
  }

  public loggedOutUser(): void {
    this.welcomeForm.reset();
  }

  private updateWelcomeForm(userDetailsData: any): void {
    const fullName = userDetailsData?.fullName;
    this.isMobileNumberAssociated = true;
    this.welcomeForm.patchValue({ fullName: fullName });
  }

  private fetchMobileNumberAndUpdateForm(): void {
    this.loaderService.show();
    this.userService.fetchMobileNumber().subscribe({
      next: (response) => {
        this.loaderService.hide();
        if (response?.body?.responseCode === 200) {
          this.mobileNumber = response.body.responseBody;
          this.welcomeForm.patchValue({ mobileNumber: this.mobileNumber });
        }
      },
      error: () => {
        this.loaderService.hide();
      },
    });
  }
  continue() {
    this.loaderService.hide();
    this.welcome();
    if (this.router.url?.includes('queue-hospital')) {
      this.router.navigateByUrl('queue-hospital/service');
      this.router.navigate(['queue-hospital/service'], {
        queryParams: { type: this.type },
      });
    } else if (this.router.url?.includes('queue')) {
      this.router.navigateByUrl('queue/service');
    }
  }
  welcome() {
    if (this.welcomeForm.valid) {
      const fuid = localStorage.getItem('fuid');
      const storedFuid = fuid ? JSON.parse(fuid) : null;
      this.welcomePayload = {
        fullName: this.welcomeForm.value.fullName,
        mobileNumber: this.welcomeForm.value.mobileNumber,
        brandName: this.brandName,
        fuid: storedFuid ? storedFuid : this.fuid,
      };
      localStorage.setItem(
        'mobileNumber',
        JSON.stringify(this.welcomeForm.value.mobileNumber),
      );
      localStorage.setItem(
        'stepOneFormData',
        JSON.stringify(this.welcomePayload),
      );
    }
  }
}
