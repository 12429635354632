import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTransferService } from './../../../core/service/data-transfer/data-transfer.service';
import { HeaderService } from './../../../core/service/header/header.service';
import { SearchBarService } from './../../../core/service/search/search-bar.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnInit {
  isInputEmpty: boolean = true;
  isSearchEmpty: boolean = true;
  userInput: boolean = false;
  inputLength: number = 0;
  searchControl: FormControl = new FormControl('');
  userProfileImage: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  showSearchCard!: boolean;
  isDesktop!: boolean;
  constructor(
    public headerService: HeaderService,
    private router: Router,
    private readonly dataTransferService: DataTransferService,
    private readonly searchBar: SearchBarService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.headerService.imageSubject$.subscribe((imageUrl) => {
      this.userProfileImage = imageUrl;
    });
    this.userProfileImage = this.dataTransferService.getEditProfileImage();
    this.dataTransferService.enableSearchCard.subscribe((value) => {
      this.showSearchCard = value;
    });
  }
  goToMyProfile() {
    this.router.navigate(['/my-profile']);
    window.scroll(0, 0);
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  loggedIn(): boolean {
    return this.dataTransferService.isLoggedin();
  }
  businessLoggedIn(): boolean {
    return this.dataTransferService.isBusinessLoggedin();
  }
  login() {
    this.searchControl.reset();
    return this.router.navigate(['/login']);
  }
  signUp() {
    return this.router.navigate(['/sign-up']);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.closeSearchCard();
    }
  }

  toggleSearchCard() {
    this.showSearchCard = true;
  }

  closeSearchCard() {
    this.showSearchCard = false;
  }

  searchResults(event: any) {
    this.isInputEmpty = this.searchControl.value?.trim() === '';
    if (this.isInputEmpty) {
      this.showSearchCard = false;
    } else {
      this.showSearchCard = true;
      setTimeout(() => {
        this.searchBar.changeSearch(this.searchControl.value, event);
      }, 100);
    }

    if (this.searchControl.value) {
      this.isSearchEmpty = false;
    } else {
      this.isSearchEmpty = true;
    }
  }
  clearAllSearchResults() {
    this.searchControl.reset();
    this.searchBar.removeSearch();
    this.isInputEmpty = true;
  }
  resetSearch() {
    this.searchControl.setValue('');
    this.isInputEmpty = true;
  }
  goToBusinessLanding() {
    return this.router.navigate(['/business']);
  }
}
