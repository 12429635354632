import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OtpTimerService } from '../../core/service/otpTimer/otp-timer.service';
import { OtpTransferComponent } from '../../user/otp-transfer/otp-transfer.component';
import { LoaderService } from '../../core/service/loader/loader.service';
import { TokenService } from '../../core/service/tokenService/token.service';
import { BrandService } from '../../core/service/brandService/brand.service';
import { DataTransferService } from '../../core/service/data-transfer/data-transfer.service';
import { Location } from '@angular/common';
import { SnackbarService } from '../../core/service/snackBar/snackbar.service';
import { SubscriptionService } from '../../core/service/subscriptionService/subscriptionService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss'],
})
export class CancelPlanComponent implements OnInit {
  currentState!: boolean;
  subscriptionId: any;
  expireTime: any;
  constructor(
    public dialog: MatDialog,
    private otpTimerService: OtpTimerService,
    public loaderService: LoaderService,
    private brandService: BrandService,
    public dataTransferService: DataTransferService,
    private tokenService: TokenService,
    private location: Location,
    private snackBarService: SnackbarService,
    private subscriptionService: SubscriptionService,
    public router: Router
  ) {}

  ngOnInit() {
    this.fetchSubscriptionId();
    this.expireTime = localStorage.getItem('expireTime');
  }

  requestBody() {
    const brandDetails = this.dataTransferService.getBrandDetails();
    if (brandDetails) {
      let businessMail = JSON.parse(brandDetails).email;
      return {
        email: businessMail,
        purpose: 'cancel-plan',
      };
    }
    return;
  }

  sendMail() {
    this.loaderService.show();
    this.brandService.businessForgotPasswordOtp(this.requestBody()).subscribe({
      next: (response: any) => {
        if (response.body.responseCode === 200) {
          let token = response.body.token;
          this.currentState = false;
          this.tokenService.setToken(token);
          this.tokenService.setContent(true);
          this.tokenService.setPurpose('cancel-plan');
        }
      },
      error: (data) => {
        this.loaderService.hide();
        let errorMessage =
          data.status == 0
            ? 'Internal server error. Please try again!'
            : data.error.responseMessage;
        this.snackBarService.openSnackBar(errorMessage, 'error-snackbar');
      },
    });
  }

  confirmCancel() {
    this.sendMail();
    this.loaderService.hide();
    this.otpTimerService.resetTimer();
    this.otpTimerService.startTimer();
    this.dialog.open(OtpTransferComponent, {
      maxWidth: '464px',
      height: '365px',
      panelClass: ['cancel-plan-dialog'],
    });
  }

  navigateBack() {
    this.location.back();
  }

  fetchSubscriptionId() {
    this.subscriptionService.fetchCurrentPlans()?.subscribe({
      next: (res: any) => {
        if (res.body.responseCode === 200) {
          this.subscriptionId = res.body?.responseBody?.razorpaySubscriptionId;
          this.subscriptionService.setSubscriptionId(this.subscriptionId);
        }
      },
      error: () => {
        return;
      },
    });
  }
}
