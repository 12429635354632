<div class="container p-3">
  <app-queue-header></app-queue-header>

  <app-shared [currentState]="currentState" [locationName]="locationName">
  </app-shared>
  <app-estimated-time
    *ngIf="currentState === 'waitlist' || currentState === 'next'"
    [placeInLine]="placeInLine"
    [estimatedTime]="estimatedTime"
    [serviceNumber]="serviceNumber"
    [ticketNumber]="ticketNumber"
  >
  </app-estimated-time>

  <app-timer *ngIf="currentState === 'turn'"></app-timer>
</div>
