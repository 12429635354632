<div class="p-2 d-flex my-3">
  <div class="col-6 px-2" *ngFor="let number of [0, 1]">
    <scremer-shimmer
      [shape]="'rect'"
      width="100"
      [height]="'150px'"
      [borderRadius]="'10px'"
    ></scremer-shimmer>
  </div>
</div>
