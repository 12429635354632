import { Component, Input, OnInit } from '@angular/core';
import { DataTransferService } from '../../core/service/data-transfer/data-transfer.service';

@Component({
  selector: 'app-response-list',
  templateUrl: './response-list.component.html',
  styleUrls: ['./response-list.component.scss'],
})
export class ResponseListComponent implements OnInit {
  @Input() response: any;
  @Input() profile: any;
  @Input() image: any;
  userProfileImage: any;
  isVisible = false;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  verficication: any;
  constructor(public readonly dataTransferService: DataTransferService) {}

  ngOnInit(): void {
    this.verficication =
      this.response?.brandResponseDTO?.documentVerificationStatus;
  }

  setProfileImage() {
    if (this.response?.brandResponseDTO) {
      let profileImage = this.response.brandResponseDTO.logoImage
        ? this.blobStorageLink + this.response?.brandResponseDTO?.logoImage
        : this.businessDefaultImage;
      return profileImage;
    } else if (this.response?.loginResponseDTO) {
      let profileImage = this.response?.loginResponseDTO?.profileImage
        ? this.blobStorageLink + this.response?.loginResponseDTO?.profileImage
        : this.defaultImage;
      return profileImage;
    }
    return this.defaultImage;
  }
}
