<mat-divider class="my-3"></mat-divider>
<div
  class="container-fluid d-flex d-block sticky bg-white p-0 px-3 align-items-center"
  *ngIf="responseField"
>
  <div class="me-2">
    <img
      [src]="setProfileImage()"
      alt="user-Image"
      class="profile-image-40 rounded-object profile-border"
    />
  </div>

  <div class="d-flex w-100 px-3 py-1 my-2 align-items-center response-div">
    <textarea
      #responseTextarea
      [(ngModel)]="responseText"
      maxlength="300"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      (input)="this.isTooltipVisible ? closeTooltip() : null"
      placeholder="response..."
      class="flex-grow-1 defaults-none overflow invisible-scrollbar"
    ></textarea>

    <div class="d-flex">
      <div class="pe-2">
        <mat-icon
          class="active-link pointer"
          (click)="uploadImageToResponse()"
          [class.disabled]="
            dataTransferService.isBusinessLoggedin() && !isBusinessSubscribed
          "
          ><i class="material-icons">image</i></mat-icon
        >
      </div>
      <div class="ps-2">
        <mat-icon
          *ngIf="!showLoader"
          class="active-link pointer"
          [class.disabled]="
            !isValid() ||
            (dataTransferService.isBusinessLoggedin() && !isBusinessSubscribed)
          "
          (click)="submitResponse()"
        >
          send</mat-icon
        >
        <mat-spinner *ngIf="showLoader" matSuffix diameter="20"></mat-spinner>
      </div>
    </div>
  </div>
</div>
<div
  class="p-3"
  *ngIf="dataTransferService.isBusinessLoggedin() && !isBusinessSubscribed"
>
  <div class="bdr profile-border px-3">
    <h2 class="text-center fw-500 fs-23 mt-3">Respond to Screams!</h2>
    <p class="text-center sub-text-color">
      Upgrade to our premium plan to connect and reply to customer screams
      seamlessly.
    </p>
    <div class="w-100 d-flex align-items-center justify justify-content-center">
      <button
        mat-raised-button
        class="btn-primary background-color lato-font-family white-color fw-500 fs-16 mb-3"
        (click)="this.router.navigate(['/business/get-premium'])"
      >
        Get Premium
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="isTooltipVisible"
  class="tooltip-container fw-400 d-flex text-center background-color white-color tooltip-position px-3 py-2"
  data-toggle="tooltip"
  data-placement="bottom"
>
  <div class="tooltip-font-container text-start">
    <p class="fs-13 m-0">
      Please respond to the scream or it will be marked as resolved and cannot
      be further reopened.
    </p>
  </div>

  <div class="w-12 text-center h-100 d-flex align-items-center ml-16">
    <button mat-icon-button (click)="closeTooltip()" class="mt-2 p-0 w-12">
      <mat-icon aria-label="Close tooltip" class="fs-12 w-12">close</mat-icon>
    </button>
  </div>
  <div class="tooltip-caret tooltip-position"></div>
</div>
<app-upload-image
  [noOfImages]="noOfUploadImages"
  (newImageEvt)="onUploadImage($event)"
></app-upload-image>
