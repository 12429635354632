<div class="container px-4 py-4">
  <span
    class="material-symbols-outlined pointer mb-3"
    (click)="goBackToPreviousPage()"
    >arrow_back</span
  >
  <div>
    <h1 class="fw-700 fs-20">Shipping & Delivery Policy</h1>
    <p class="mb-3">Shipping is not applicable for Business.</p>
  </div>
</div>
