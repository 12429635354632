import { Component, Input } from '@angular/core';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';

@Component({
  selector: 'app-scream-tag',
  templateUrl: './scream-tag.component.html',
  styleUrls: ['./scream-tag.component.scss'],
})
export class ScreamTagComponent {
  @Input() brands: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  constructor(private readonly dataTransferService: DataTransferService) {}
}
