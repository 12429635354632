<div class="p-0 pb-6 pt-7 pd">
  <div class="container-fluid pb-0 p-3">
    <app-back-arrow *ngIf="screamBySuid"></app-back-arrow>
  </div>

  <app-response-shimmer *ngIf="screamShimmer"></app-response-shimmer>
  <div>
    <div *ngIf="screamBySuid">
      <app-scream
        [scream]="screamBySuid"
        [image]="userImage"
        [profile]="userProfile"
      ></app-scream>
    </div>
    <div *ngIf="!screamShimmer">
      <mat-divider *ngIf="screamUpCount !== 0" class="my-3"></mat-divider>
      <div
        class="d-flex pointer px-3"
        *ngIf="screamUpCount !== 0"
        (click)="openDialogScreamUps()"
      >
        <h5 class="px-2 m-0 fs-14 pointer">
          {{ screamUpCount || "" }}
        </h5>
        <div>
          {{ screamUpCount === 1 ? "Scream up" : "Scream ups" }}
        </div>
      </div>
    </div>
    <app-create-response
      *ngIf="!screamShimmer"
      [suid]="suid"
      [responseField]="responseField"
      (refreshScreamEvt)="getScreamBySuid()"
    ></app-create-response>
    <div *ngFor="let response of responses">
      <app-response-list [response]="response"></app-response-list>
    </div>
  </div>
  <app-resolve-scream
    [scream]="screamBySuid"
    (feedbackResponseEvent)="sendExperienceAndStatus($event)"
    *ngIf="isScreamResolvable()"
  ></app-resolve-scream>
</div>
