import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { AddedImage } from '../../core/interfaces/added-Image';
import { NgxImageCompressService, DOC_ORIENTATION } from 'ngx-image-compress';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  providers: [NgxImageCompressService],
})
export class UploadImageComponent {
  orientation!: DOC_ORIENTATION;
  @Input() addedImages: AddedImage[] = [];
  @ViewChild('addImage', { static: false }) addImage!: ElementRef;
  @Input() noOfImages!: Number;
  @Output() newImageEvt = new EventEmitter<any>();
  constructor(
    private snackBarService: SnackbarService,
    public imageCompress: NgxImageCompressService
  ) {}
  public addImageEvt(event: any) {
    if (
      event.target.files &&
      this.addedImages.length + event.target.files.length <= this.noOfImages
    ) {
      for (let i = 0; i < event.target.files.length; i++) {
        const imageName = event.target.files[i].name.replace(/\s/g, '');
        const fileSize = event.target.files[i].size;
        if (fileSize <= 1048576) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.addedImages.push({
              imageFile: event.target.files[i],
              imageName: imageName,
              imageUrl: e.target.result,
              imageSize: fileSize,
            });
          };
          reader.readAsDataURL(event.target.files[i]);
        } else {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.imageCompress
              .compressFile(e.target.result, this.orientation, 50, 50)
              .then((result: any) => {
                const compressedFile = this.dataURItoBlob(result) as File;
                this.addedImages.push({
                  imageFile: compressedFile,
                  imageName: imageName,
                  imageUrl: result,
                  imageSize: fileSize,
                });
              })
              .catch((error: any) => {});
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      }
      this.newImageEvt.emit(this.addedImages);
    } else {
      this.snackBarService.openSnackBar(
        'You can add up to ' + this.noOfImages + ' picture(s) only',
        'success-snackbar'
      );
    }
  }
  removeImageFromList(index: any) {
    this.addedImages.splice(index, 1);
    localStorage.removeItem('screamDraft');
    const inputEl = this.addImage.nativeElement;
    inputEl.value = ''; // reset file input element
  }

  resetAddedImages() {
    this.addedImages = [];
    const inputEl = this.addImage.nativeElement;
    inputEl.value = '';
    inputEl.dispatchEvent(new Event('change'));
  }

  getAddImg() {
    return this.addImage;
  }

  dataURItoBlob = (dataURI: string): Blob => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
}
