import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { SearchService } from './../../core/service/searchService/search.service';
import { HeaderService } from './../../core/service/header/header.service';
import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchBarService } from './../../core/service/search/search-bar.service';
import { LoaderService } from './../../core/service/loader/loader.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements AfterViewInit, OnInit {
  @Input() searchItem: string = '';
  @Output() resetEvt = new EventEmitter<any>();
  pageIndex = 0;
  pageSize = 10;
  searching: boolean = false;
  searchResults: any;
  userName: any;
  userInput: boolean = false;
  profile: any;
  inputLength: number = 0;
  searchHistory: any = [];
  getSearchHistory: any;
  names: Name[] = [];
  @ViewChild('myInput') myInput!: ElementRef;
  selectedResultIndex: number = -1;
  selectedRecentSearchIndex: number = -1;
  show = true;
  showSearchCard!: boolean;
  constructor(
    public readonly headerService: HeaderService,
    private readonly searchService: SearchService,
    public readonly router: Router,
    public readonly route: ActivatedRoute,
    public readonly dataTransferService: DataTransferService,
    public readonly searchBar: SearchBarService,
    private readonly loaderService: LoaderService,
    private searchbarService: SearchBarService
  ) {}
  ngOnInit(): void {
    this.searchbarService.userBlocked$?.subscribe((userName: string) => {
      this.removeBlockedUserFromSearchHistory(userName);
    });

    this.getSearchHistoryResults();
    this.dataTransferService.enableSearchCard.subscribe((value) => {
      this.showSearchCard = value;
    });
    this.searchBar.search$.subscribe((searchResults) => {
      if (searchResults.message === 'remove') {
        this.clearAllSearchResults();
      } else {
        this.searchItem = searchResults.message;
        this.getAllAppropriateSearchResults(searchResults.event);
        this.search(searchResults, 0, 10);
      }
    });
  }
  getSearchHistoryResults() {
    const searchHistory = this.dataTransferService.getSearchHistory();
    if (!searchHistory) {
      return;
    }
    try {
      this.getSearchHistory = JSON.parse(searchHistory).slice(0, 5);
    } catch (error) {
      return;
    }
  }
  ngAfterViewInit() {
    this.myInput.nativeElement.focus();
  }
  goBackToPreviousPage() {
    this.dataTransferService.updateShowSearchCard(false);
    this.show = false;
    this.router.navigate([`/${this.router.url}`]);
  }
  clearAllSearchResults() {
    this.searchItem = '';
    this.searchResults = [];
    this.searching = false;
    this.getSearchHistoryResults();
    this.dataTransferService.updateShowSearchCard(true);
  }

  navigateToUserProfile(
    userName: string,
    fullName: string,
    profile: string,
    isUser: string,
    isVerified: string,
    subscription: string
  ) {
    this.profile = JSON.parse(this.dataTransferService.getUserDetails() || '');
    const isMyProfile =
      userName === this.profile?.userName &&
      fullName === this.profile?.fullName;
    if (isVerified === 'user') {
      if (isMyProfile) {
        this.show = false;
        this.router.navigate(['/my-profile']);
        this.dataTransferService.updateShowSearchCard(false);
        this.resetEvt.emit();
      } else if (userName !== 'null' && userName !== this.profile?.userName) {
        this.show = false;
        this.searchItem = fullName;
        this.router.navigate([`/${userName}`]);
        this.dataTransferService.updateShowSearchCard(false);
        this.resetEvt.emit();
      } else {
        this.show = false;
        this.router.navigate(['page-not-found']);
        this.dataTransferService.updateShowSearchCard(false);
        this.resetEvt.emit();
      }
    } else if (isVerified === 'brand') {
      this.show = false;
      this.router.navigate([`/brand/${userName}`]);
      this.dataTransferService.updateShowSearchCard(false);
      this.resetEvt.emit();
    } else {
      this.show = false;
      this.loaderService.show();
      setTimeout(() => {
        this.loaderService.hide();
        this.router.navigate(['/no-results-found']);
      }, 500);
      this.dataTransferService.updateShowSearchCard(false);
      this.resetEvt.emit();
    }

    this.updateSearchHistory({
      fullName: fullName,
      userName: userName,
      profile: profile,
      isUser: isUser,
      isVerified: isVerified,
      subscription: this.dataTransferService.setVerificationIconColor({
        subscription: subscription,
      }),
    });
  }

  submitForm() {
    if (this.searchResults.length === 0) {
      this.show = false;
      this.loaderService.show();
      setTimeout(() => {
        this.loaderService.hide();
        this.router.navigate(['/no-results-found']);
      }, 500);
    }
    this.dataTransferService.updateShowSearchCard(false);
    this.resetEvt.emit();
  }

  getAllAppropriateSearchResults(event: any) {
    if (this.searchItem?.trim() != '' && event?.key === 'Enter') {
      this.search(event, this.pageIndex, this.pageSize);
      this.updateSearchHistory({
        fullName: this.searchItem,
        userName: 'null',
        profile: 'null',
        isUser: 'null',
      });
      this.resetEvt.emit();
    }
    if (
      event?.key === 'Enter' ||
      (this.searchItem?.trim() != '' && !this.inputLength)
    ) {
      this.show = true;
      this.search(event, this.pageIndex, this.pageSize);
    } else if (this.searchItem?.trim().length < this.inputLength) {
      this.resetAll();
      this.search(event, this.pageIndex, this.pageSize);
    }
    this.searchResults = [];
    if (this.searchItem?.trim() != '') {
      this.searching = true;
    } else {
      this.searching = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (this.searching) {
        this.selectNextSearchResult();
      } else {
        this.selectNextRecentSearch();
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (this.searching) {
        this.selectPreviousSearchResult();
      } else {
        this.selectPreviousRecentSearch();
      }
    } else if (event.key === 'Enter') {
      if (this.searching) {
        this.navigateToSelectedSearchResult();
      } else {
        this.navigateToSelectedRecentSearch();
      }
    }
  }
  navigateToSelectedRecentSearch() {
    const selectedRecentSearch =
      this.getSearchHistory[this.selectedRecentSearchIndex];
    if (selectedRecentSearch) {
      this.navigateToUserProfile(
        selectedRecentSearch.userName,
        selectedRecentSearch.fullName,
        selectedRecentSearch.profile,
        selectedRecentSearch.isUser,
        selectedRecentSearch.isVerified,
        selectedRecentSearch.subscription
      );
    }
  }

  navigateToSelectedSearchResult() {
    const selectedSearchResult = this.searchResults[this.selectedResultIndex];
    if (selectedSearchResult) {
      this.navigateToUserProfile(
        selectedSearchResult[1],
        selectedSearchResult[2],
        selectedSearchResult[3],
        selectedSearchResult[4],
        selectedSearchResult[6],
        selectedSearchResult[5]
      );
    } else {
      this.router.navigate(['/no-results-found']);
    }
  }
  selectNextRecentSearch() {
    const maxIndex = this.getSearchHistory?.length - 1;
    if (this.selectedRecentSearchIndex < maxIndex) {
      this.selectedRecentSearchIndex++;
    } else {
      this.selectedRecentSearchIndex = 0;
    }
  }

  selectPreviousRecentSearch() {
    const maxIndex = this.getSearchHistory?.length - 1;
    if (this.selectedRecentSearchIndex > 0) {
      this.selectedRecentSearchIndex--;
    } else {
      this.selectedRecentSearchIndex = maxIndex;
    }
  }
  selectNextSearchResult() {
    const maxIndex = this.searchResults?.length - 1;
    if (this.selectedResultIndex < maxIndex) {
      this.selectedResultIndex++;
    } else {
      this.selectedResultIndex = 0;
    }
  }
  selectPreviousSearchResult() {
    const maxIndex = this.searchResults?.length - 1;
    if (this.selectedResultIndex > 0) {
      this.selectedResultIndex--;
    } else {
      this.selectedResultIndex = maxIndex;
    }
  }
  resetAll() {
    this.inputLength = 0;
    this.userInput = false;
  }

  search(event: any, pageIndex: number, pageSize: number) {
    if (this.searchItem?.trim() != '') {
      this.searchService
        .fetchSearchResults({ pageIndex, pageSize }, this.searchItem?.trim())
        .subscribe((data: any) => {
          if (data.body.responseCode == 200) {
            this.searching = true;
            this.searchResults = data.body.responseBody.content;
            this.searchResults?.forEach((result: any) => {
              if (Array.isArray(result)) {
                if (result[5] != null) {
                  result.push(
                    this.dataTransferService.setVerificationIconColor({
                      subscription: result[5],
                    })
                  );
                } else {
                  result.push('verification-color-blue');
                }
              }
            });
          } else {
            if (event?.key === 'Enter') {
              this.resetAll();
              this.submitForm();
              this.show = false;
              this.searching = true;
            } else {
              this.userInput = true;
              this.searching = true;
              this.show = true;
              this.inputLength = this.searchItem?.length;
            }
          }
        });
    }
  }

  updateSearchHistory(searchObject: any) {
    if (searchObject.fullName.trim() === '') {
      return;
    }
    const searchHistory = JSON.parse(
      localStorage.getItem('searchHistory') || '[]'
    ) as Name[];
    const searchItemIndex = searchHistory.findIndex(
      (item) =>
        item.fullName === searchObject.fullName &&
        item.userName === searchObject.userName &&
        item.profile === searchObject.profile &&
        item.isUser === searchObject.isUser
    );

    if (searchItemIndex !== -1) {
      searchHistory.splice(searchItemIndex, 1);
    }
    searchHistory.unshift(searchObject);
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }

  removeBlockedUserFromSearchHistory(userName: string) {
    const searchHistory = JSON.parse(
      localStorage.getItem('searchHistory') || '[]'
    ) as Name[];
    const updatedSearchHistory = searchHistory.filter((item) => {
      return item.userName !== userName;
    });
    localStorage.setItem('searchHistory', JSON.stringify(updatedSearchHistory));
    this.getSearchHistoryResults();
  }
}

export interface Name {
  fullName: string;
  userName: string;
  profile: string;
  isUser: string;
  isVerified: string;
}
