<div class="sticky-container" *ngIf="isBranchesExist">
  <div *ngIf="!selectedBranch && !isDashboard()">
    <div class="dropdown" id="dropdown">
      <button
        class="btn btn-secondary background-light-green-color"
        (click)="toggleDropdown()"
        type="button"
        id="dropdown"
        aria-expanded="false"
      >
        <span class="px-2 active-link fw-600" id="dropdown">Location</span>
        <span class="dropdown-icon"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            height="16px"
            viewBox="0 -960 960 960"
            width="16px"
            fill="#2b60af"
            id="dropdown"
          >
            <path d="M480-384 288-576h384L480-384Z" id="dropdown" /></svg
        ></span>
      </button>
      <ul
        class="dropdown-menu px-3 pb-3 pt-0"
        id="dropdown"
        aria-labelledby="dropdownMenuButton"
        [class.show]="isDropdownOpen"
      >
        <div class="pt-3 sticy-search" id="dropdown">
          <div
            class="px-2 py-1 w-100 search-container d-flex align-items-center"
            id="dropdown"
          >
            <span class="d-flex align-items-center" id="dropdown"
              ><mat-icon
                class="me-2 fs-16 grey-disable-color search-icon"
                id="dropdown"
                >search</mat-icon
              ></span
            >
            <span id="dropdown">
              <input
                id="dropdown"
                type="text"
                placeholder="Search for location"
                class="search-branch-input"
                [formControl]="searchControl"
              />
            </span>
          </div>
        </div>
        <li *ngFor="let branch of branches">
          <p
            class="dropdown-item pointer m-0 p-2 active-link fs-14 fw-600"
            (click)="selectBranch(branch)"
          >
            {{ branch }}
          </p>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="selectedBranch && !isDashboard()">
    <span class="d-flex align-items-center chip width text-center py-6">
      <p class="my-0 fs-14 fw-600 ml-16">{{ selectedBranch }}</p>
      <span
        class="material-symbols-outlined mx-2 fs-18 fw-500 pointer lh-0"
        (click)="clearBranch()"
      >
        close
      </span>
    </span>
  </div>
  <div *ngIf="isDashboard()">
    <div class="dropdown" id="dropdown">
      <button
        class="btn btn-secondary background-light-green-color"
        (click)="toggleDropdown()"
        type="button"
        id="dropdown"
        aria-expanded="false"
      >
        <span class="px-2 active-link fw-600" id="dropdown">{{
          selectedBranch ? selectedBranch : "All Locations"
        }}</span>
        <span class="dropdown-icon"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            height="16px"
            viewBox="0 -960 960 960"
            width="16px"
            fill="#2b60af"
            id="dropdown"
          >
            <path d="M480-384 288-576h384L480-384Z" id="dropdown" /></svg
        ></span>
      </button>
      <ul
        class="dropdown-menu px-3 pb-3 pt-0"
        id="dropdown"
        aria-labelledby="dropdownMenuButton"
        [class.show]="isDropdownOpen"
      >
        <div class="pt-3 sticy-search" id="dropdown">
          <div
            class="px-2 py-1 w-100 search-container d-flex align-items-center"
            id="dropdown"
          >
            <span class="d-flex align-items-center" id="dropdown"
              ><mat-icon
                class="me-2 fs-16 grey-disable-color search-icon"
                id="dropdown"
                >search</mat-icon
              ></span
            >
            <span id="dropdown">
              <input
                id="dropdown"
                type="text"
                placeholder="Search for location"
                class="search-branch-input"
                [formControl]="searchControl"
              />
            </span>
          </div>
        </div>
        <li *ngFor="let branch of branches">
          <p
            class="dropdown-item pointer m-0 p-2 active-link fs-14 fw-600"
            (click)="selectBranch(branch)"
          >
            {{ branch }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
