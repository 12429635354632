import { ScreamService } from './../../core/service/screamService/scream.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { Router } from '@angular/router';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { UserService } from './../../core/service/userService/user.service';
import { TokenService } from './../../core/service/tokenService/token.service';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { LoaderService } from '../../core/service/loader/loader.service';
@Component({
  selector: 'app-delete-scream',
  templateUrl: './delete-scream.component.html',
  styleUrls: ['./delete-scream.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class DeleteScreamComponent implements OnInit {
  error: any;

  constructor(
    public dialogRef: MatDialogRef<DeleteScreamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { scream: any },
    private screamService: ScreamService,
    private location: Location,
    private readonly userService: UserService,
    private readonly snackBarService: SnackbarService,
    private router: Router,
    private dataTransfer: DataTransferService,
    public readonly loaderService: LoaderService,
    public tokenService: TokenService
  ) {}
  ngOnInit() {
    this.data.scream;
  }
  closeDialog() {
    this.dialogRef.close(DeleteScreamComponent);
    document.body.classList.remove('cdk-global-scrollblock');
  }
  confirm() {
    const currentPage = this.router.url;
    this.screamService.deleteScream(this.data.scream.suid).subscribe({
      next: (data: any) => {
        if (data.body.responseCode == 200) {
          this.dialogRef.close(DeleteScreamComponent);
          this.snackBarService.openSnackBar(
            data.body.responseMessage,
            'success-snackbar'
          );
          this.screamService.setScream(this.data.scream);
          this.dataTransfer.changeMessage('deleting scream');
          if (currentPage.includes('/scream')) {
            this.location.back();
          }

          this.dataTransfer.screamDeleted.emit();
        }
      },
      error: (data) => {
        if (data.status == 0) {
          this.snackBarService.openSnackBar(
            'Internal server error. Please try again!',
            'error-snackbar'
          );
        } else {
          this.snackBarService.openSnackBar(
            data.error.responseMessage,
            'error-snackbar'
          );
        }
      },
    });
  }
  confirmAddress() {
    this.loaderService.show();
    this.userService
      .updateUserAddress({ purpose: this.data.scream })
      .subscribe({
        next: (data: any) => {
          if (data.body.responseCode == 201) {
            this.dialogRef.close(DeleteScreamComponent);
            let address = this.tokenService.getAddress();
            this.userService.updateAddress(address);
            this.snackBarService.openSnackBar(
              data.body.responseMessage,
              'success-snackbar'
            );
            this.tokenService.setContent(true);
          }
          this.loaderService.hide();
        },
        error: (data) => {
          if (data.status == 0) {
            this.snackBarService.openSnackBar(
              'Internal server error. Please try again!',
              'error-snackbar'
            );
          } else {
            this.snackBarService.openSnackBar(
              data.error.responseMessage,
              'error-snackbar'
            );
          }
        },
      });
  }
}
