<div class="d-flex justify-content-between px-2 pt-2 pb-3">
  <div
    class="d-flex justify-content-start w-100 py-2 scream-icon-color pointer"
  >
    <div class="d-flex justify-content-center pb-1">
      <div class="d-flex justify-content-start">
        <button
          mat-icon-button
          matTooltip="{{ isScreamUp ? '' : 'Scream Up' }}"
          matTooltipPosition="below"
          (click)="screamUp()"
          class="screamUp-icon me-2"
        >
          <img *ngIf="!isScreamUp" src="assets/images/ScreamUp_Outline.svg" />
          <img *ngIf="isScreamUp" src="assets/images/ScreamUp_filled.svg" />
        </button>
        <h5 class="m-0 pt-1 fs-14 pointer">
          {{ screamUpCount || "" | shortNumberSuffix }}
        </h5>
      </div>
    </div>
  </div>
  <div
    class="d-flex justify-content-center align-items-center w-100 scream-icon-color pointer"
  >
    <div class="d-flex justify-content-center pt-1">
      <div class="d-flex justify-content-start">
        <button
          mat-icon-button
          class="screamtool-icon me-2"
          (click)="goToResponses()"
        >
          <mat-icon class="material-icons fs-20">chat_bubble_outline</mat-icon>
        </button>

        <h5 class="m-0 fs-14">{{ (scream && scream.responseCount) || "" }}</h5>
      </div>
    </div>
  </div>
  <div
    class="d-flex justify-content-end align-items-center w-100 scream-icon-color pointer"
  >
    <div class="d-flex justify-content-center">
      <div class="d-flex justify-content-start">
        <button
          mat-icon-button
          (click)="openShare()"
          class="screamtool-icon me-2"
          [disabled]="scream?.privacy"
        >
          <mat-icon class="material-symbols-outlined fs-20"
            >ios_share
          </mat-icon>
        </button>
        <h5 class="m-0 fs-14">{{ scream && scream.shares }}</h5>
      </div>
    </div>
  </div>
</div>
