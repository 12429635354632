<div class="d-flex align-items-center py-1">
  <div class="pe-4">
    <mat-icon class="material-icons-outlined mat-icon-height">search</mat-icon>
  </div>
  <div class="d-flex align-items-center">
    <img
      class="profile-image-40 me-2 rounded-object profile-border {{
        result?.[6] === 'brand' ? 'object-contain' : ''
      }}"
      [src]="
        result?.[3] !== null && result?.[6] === 'brand'
          ? blobStorageLink + result[3]
          : result?.[3] !== null && result?.[6] === 'user'
            ? blobStorageLink + result[3]
            : result?.[3] === null && result?.[6] === 'user'
              ? defaultImage
              : businessDefaultImage
      "
      alt="profile"
    />
    <div class="name-table-wrapper">
      <div class="name-table">
        <div class="d-flex align-items-center">
          <p
            *ngIf="result?.[6] === 'user'"
            class="fw-600 fs-16 m-0 truncate-ellipsis"
          >
            {{ result?.[2] }}
          </p>
          <p
            *ngIf="result?.[6] === 'brand'"
            class="fw-600 fs-16 m-0 truncate-ellipsis"
          >
            {{ result?.[1] }}
          </p>
          <span
            class="ms-1 material-icons fs-16"
            [ngClass]="result[7]"
            *ngIf="result?.[4] === 'VERIFIED'"
          >
            verified
          </span>
        </div>
        <div class="scream-icon-color name-table-wrapper">
          <div class="name-table">
            <p
              *ngIf="result?.[6] === 'brand'"
              class="fw-400 fs-12 m-0 truncate-ellipsis"
            >
              {{ result?.[2] }}
            </p>
            <p
              *ngIf="result?.[6] === 'user'"
              class="fw-400 fs-12 m-0 truncate-ellipsis"
            >
              {{ result?.[1] }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
