<div class="px-3 pt-7">
  <div class="py-3">
    <app-back-arrow></app-back-arrow>
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <label class="hoverable profile-image-88 pointer" for="fileInput">
      <img
        [src]="
          this.selectedImage
            ? this.selectedImage
            : getProfileImage()
            ? blobStorageLink + getProfileImage()
            : businessDefaultImage
        "
        alt="upload_image"
        class="w-100 h-100 rounded-object profile-border"
      />
      <div class="hover-text">
        <span class="material-symbols-outlined white-color">
          photo_camera
        </span>
      </div>
    </label>
    <input id="fileInput" type="file" (change)="onSelect($event)" />
  </div>
  <div>
    <div>
      <div>
        <form
          [formGroup]="editProfile"
          (ngSubmit)="isImageUpdated ? uploadImage() : update()"
        >
          <mat-form-field class="w-100">
            <mat-icon
              class="material-icons-outlined grey-disable-color"
              matPrefix
              >add_business</mat-icon
            >
            <mat-label>Business Name</mat-label>
            <input
              matInput
              placeholder="Business Name"
              formControlName="brandName"
            />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-icon matPrefix class="grey-disable-color"
              >mail_outline</mat-icon
            >
            <mat-label>Business Email</mat-label>
            <input
              matInput
              placeholder="Business Email"
              formControlName="email"
            />
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-icon matPrefix class="grey-disable-color">language</mat-icon>
            <mat-label>Website</mat-label>
            <input
              matInput
              placeholder="Website"
              formControlName="websiteLink"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-100">
            <mat-icon
              matPrefix
              class="grey-disable-color material-icons-outlined"
              >call</mat-icon
            >
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              placeholder="Phone number"
              formControlName="mobileNumber"
              maxlength="15"
            />
            <mat-error
              *ngIf="
                this.editProfile.controls['mobileNumber'].hasError('required')
              "
              >Please enter phone number</mat-error
            >
            <mat-error
              *ngIf="
                this.editProfile.controls['mobileNumber'].hasError('pattern')
              "
              >Please enter valid phone number</mat-error
            >
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-icon
              matPrefix
              class="grey-disable-color material-icons-outlined"
              >layers</mat-icon
            >
            <mat-label>Select category</mat-label>
            <mat-select formControlName="category">
              <mat-option
                *ngFor="let category of categories"
                [value]="category?.id"
                >{{ category?.categoryName }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="this.editProfile.controls['category'].hasError('required')"
              >Please select category</mat-error
            >
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-icon
              matPrefix
              class="grey-disable-color material-icons-outlined"
              >layers</mat-icon
            >
            <mat-label>Select sub-category</mat-label>
            <mat-select formControlName="subCategory">
              <mat-option
                *ngFor="let subCategory of subCategories"
                [value]="subCategory?.id"
                >{{ subCategory?.subCategoryName }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                this.editProfile.controls['subCategory'].hasError('required')
              "
              >Please select subcategory</mat-error
            >
          </mat-form-field>

          <div class="d-flex justify-content-center mt-3">
            <button
              mat-raised-button
              type="submit"
              [disabled]="!isFormValid()"
              [ngClass]="{ 'disabled-button': !isFormValid() }"
              class="fw-500 fs-16 btn-primary background-color white-color lato-font-family mt-2 margin px-5"
            >
              Update profile
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
