import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DownloadScremerComponent } from './download-scremer/download-scremer.component';

const routes: Routes = [
  {
    path: 'download-app/:referralCode',
    component: DownloadScremerComponent,
    title: 'Download the App',
  },
  {
    path: 'business',
    loadChildren: () =>
      import('./business/business.module').then((m) => m.BusinessModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./feature/feature.module').then((m) => m.FeatureModule),
  },
  {
    path: 'business',
    loadChildren: () =>
      import('./business/business.module').then((m) => m.BusinessModule),
  },
  {
    path: 'scream',
    loadChildren: () =>
      import('./scream/scream.module').then((m) => m.ScreamModule),
  },
  {
    path: 'brand',
    loadChildren: () =>
      import('./brand/brand.module').then((m) => m.BrandModule),
  },

  {
    path: '',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'response',
    loadChildren: () =>
      import('./response/response.module').then((m) => m.ResponseModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
