<app-queue-header></app-queue-header>
<div class="p-3 mt-2">
  <div>
    <app-back-arrow></app-back-arrow>
  </div>

  <div class="container">
    <img
      class="join-queue mb-20 mt-32"
      src="assets/images/join-queue.svg"
      alt="join-queue"
    />
    <p class="fw-500 fs-24 fonti" *ngIf="waitingData">
      {{ waitingData?.availabilityStatus }}!
    </p>
    <p
      *ngIf="waitingData?.waitingCount > 1 && waitingData"
      class="fw-700 fs-16 fontii"
    >
      {{ waitingData?.waitingCount }} people are waiting
    </p>
    <p class="fw-700 fs-16 fontii" *ngIf="waitingData?.waitingCount === 1">
      {{ waitingData?.waitingCount }} person is waiting
    </p>
    <p class="fw-700 fs-16 fontii" *ngIf="waitingData">
      ETA : {{ waitingData?.totalTimeEstimate }}
    </p>

    <button
      class="btn-primary w-100 p-10 mt-2 pointer background-color lato-font-family white-color fw-500 fs-16"
      (click)="joinQueue()"
    >
      Join the Queue
    </button>
  </div>
</div>
<app-queue-footer></app-queue-footer>
