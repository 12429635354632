import { Component, OnInit } from '@angular/core';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { TokenService } from './../../core/service/tokenService/token.service';
import { ScreamService } from './../../core/service/screamService/scream.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-back-arrow',
  templateUrl: './back-arrow.component.html',
  styleUrls: ['./back-arrow.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class BackArrowComponent implements OnInit {
  previousUrl: Observable<string> = this.screamService.previousUrl$;

  constructor(
    private location: Location,
    private token: TokenService,
    private screamService: ScreamService,
  ) {}

  ngOnInit(): void {
    this.token.setContent(true);
  }

  goBackToPreviousPage() {
    this.location.back();
  }

  getHeaderText() {
    const path = this.location.path();
    const paths = [
      { link: '/edit-profile', heading: 'Edit profile' },
      { link: '/brand/brands', heading: 'E-Commerce' },
      { link: '/brand/brand-request', heading: 'Request a new brand' },
      { link: '/categories', heading: 'Categories' },
      { link: '/edit-address', heading: 'Add new address' },
      { link: '/address', heading: 'Address' },
      { link: '/change-password', heading: 'Change password' },
      { link: '/FAQ', heading: "FAQ's" },
      { link: '/about', heading: 'About Scremer' },
      { link: '/invite', heading: 'Invite' },
      { link: '/business/account', heading: 'Settings' },
      { link: '/delete-account', heading: 'Delete account' },
      { link: '/user-feedback', heading: 'Delete account' },
      { link: '/confirm-delete', heading: 'Delete account' },
      { link: '/blocked-account', heading: 'Blocked accounts' },
      { link: '/manage', heading: 'Manage Premium' },
      { link: '/business/manage-premium', heading: 'Manage premium' },
      { link: '/business/cancel-plan', heading: 'Cancel Plan' },
      { link: '/business/menu', heading: 'Add menu' },
      { link: '/payment-history', heading: 'Payment History' },
      { link: '/events', heading: 'Events' },
      { link: '/how-events-work', heading: 'How Events Work' },
      { link: 'thub-review', heading: 'Reviews' },
      { link: '/slot-booking', heading: ' ' },
    ];
    const header = paths.find((x) => path.includes(x.link));
    return header ? header.heading : '';
  }
}
