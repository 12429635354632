<div class="p-4">
  <div class="d-flex flex-column align-items-start">
    <p
      class="m-0 fs-23 fw-600 lato-font-family text-center w-100"
      *ngIf="contentVisible || !contentVisible"
    >
      Report
    </p>
    <p
      class="m-0 fs-18 fw-500 lato-font-family pt-3 scream-icon-color pl-15px"
      *ngIf="contentVisible"
    >
      Select a reason for reporting the scream
    </p>
    <p
      class="m-0 fs-18 fw-500 lato-font-family name-wrap lh-1 pt-4 pl-15px"
      *ngIf="!contentVisible"
    >
      <span class="scream-icon-color">Select a reason for reporting</span>
      <span class="font-color fw-500 ml-10px">{{
        data.profile?.userName
      }}</span>
    </p>
  </div>

  <div class="my-3">
    <ng-container *ngIf="contentVisible">
      <form [formGroup]="reporter">
        <mat-radio-group formControlName="report">
          <mat-radio-button value="Offensive language or Hate speech">
            Offensive language or Hate speech
          </mat-radio-button>
          <mat-radio-button value="Irrelevant or Spam">
            Irrelevant or Spam
          </mat-radio-button>
          <mat-radio-button value="Copied or Plagiarized content">
            Copied or Plagiarized content
          </mat-radio-button>
          <mat-radio-button value="Promotional content">
            Promotional content
          </mat-radio-button>
          <mat-radio-button
            value="Factually inaccurate or Misleading information"
          >
            Factually inaccurate or Misleading information
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </ng-container>
    <ng-container *ngIf="!contentVisible">
      <form [formGroup]="reporter">
        <mat-radio-group formControlName="report">
          <mat-radio-button value="Spam"> Spam </mat-radio-button>
          <mat-radio-button value="Impersonation">
            Impersonation
          </mat-radio-button>
          <mat-radio-button value="Suspicious Activity">
            Suspicious Activity
          </mat-radio-button>
          <mat-radio-button value="Inappropriate Behavior">
            Inappropriate Behavior
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </ng-container>
  </div>
  <div class="d-flex justify-content-end">
    <button
      mat-raised-button
      class="rounded-pill active-link cancel-button-border lato-font-family fw-600 fs-14 w-100 mx-2"
      (click)="closeDialog()"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2 w-100"
      *ngIf="contentVisible"
      type="submit"
      (click)="reportScrem()"
      [class.disabled-button]="!isReportValid()"
      [disabled]="!isReportValid()"
    >
      Report
    </button>
    <button
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2 w-100"
      *ngIf="!contentVisible"
      type="submit"
      (click)="reportUser()"
      [class.disabled-button]="!isReportValid()"
      [disabled]="!isReportValid()"
    >
      Report
    </button>
  </div>
</div>
