import { LoaderService } from './../../core/service/loader/loader.service';
import { HeaderService } from './../../core/service/header/header.service';
import { BottomNavBarService } from './../../core/service/bottomNavBar/bottom-nav-bar.service';
import { UserService } from './../../core/service/userService/user.service';
import { AfterContentInit, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OtpTransferComponent } from '../otp-transfer/otp-transfer.component';
import { TokenService } from './../../core/service/tokenService/token.service';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { OtpTimerService } from './../../core/service/otpTimer/otp-timer.service';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, AfterContentInit {
  isContentVisible = true;
  referralCode: any;
  verifyUserNameResponseMessage: any;
  verifyEmailResponseMessage: any;
  title: any;
  isHidePassword = true;
  signupForm!: FormGroup;
  isUserNameUnique!: boolean;
  userNameLoader = false;
  isFormValid: boolean = false;
  constructor(
    private userService: UserService,
    public router: Router,
    public readonly bottomService: BottomNavBarService,
    public readonly headerService: HeaderService,
    private readonly loaderService: LoaderService,
    public service: DataTransferService,
    private tokenService: TokenService,
    private otpTimerService: OtpTimerService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private snackBarService: SnackbarService
  ) {}
  ngOnInit(): void {
    this.signupForm = this.createSignupForm();
    this.signupForm.valueChanges.subscribe(() => {
      this.isFormValid =
        this.signupForm.valid && this.isUserNameUnique !== false;
    });
    this.route.paramMap?.subscribe((params) => {
      this.referralCode = params.get('referralCode') ?? null;
      if (this.referralCode !== null) {
        this.validateReferralCode();
      }
    });
  }
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.bottomService.hide();
    }, 0);
    setTimeout(() => {
      this.headerService.show();
    }, 0);
  }

  createSignupForm(): FormGroup {
    return new FormGroup({
      fullName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z\s]+$/),
        Validators.maxLength(30),
      ]),
      userName: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
        this.customUsernameValidator(),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(
          '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
        ),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
        Validators.pattern(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,}$/
        ),
      ]),
    });
  }

  customUsernameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string;
      if (value.startsWith('.') || value.endsWith('.')) {
        return { periodAtStartEnd: true };
      } else if (/\.\./.test(value)) {
        return { successivePeriods: true };
      } else if (/\s/.test(value)) {
        return { spacesNotAllowed: true };
      } else if (!/^(?!^\.|\.$)(?!.*\.{2})[a-zA-Z0-9_.]+$/.test(value)) {
        return { invalidCharacters: true };
      }
      return null;
    };
  }
  createUserBody() {
    const userBody = {
      signUpType: 'SCREMER',
      userDetails: {
        fullName: this.signupForm.value['fullName'],
      },
      userAccount: {
        userName: this.signupForm.value['userName'],
        email: this.signupForm.value['email'],
        password: this.signupForm.value['password'],
      },
      referralCode: this.referralCode,
    };
    return userBody;
  }

  checkUser() {
    if (this.signupForm.controls['userName'].valid) {
      this.userNameLoader = true;
      this.userService.checkUserName(this.createUserBody()).subscribe({
        next: (res: any) => {
          if (res.body.responseCode == 200) {
            this.userNameLoader = false;
            this.isUserNameUnique = true;
            this.verifyUserNameResponseMessage = res.body.responseMessage;
          }
        },
        error: (res) => {
          this.userNameLoader = false;
          this.isUserNameUnique = false;
          this.verifyUserNameResponseMessage = res.error.responseMessage;
        },
      });
    }
  }

  signup() {
    if (this.signupForm.valid && this.isUserNameUnique != false) {
      this.loaderService.show();
      this.userService.registerUser(this.createUserBody()).subscribe({
        next: (response: any) => {
          this.loaderService.hide();
          if (
            response.body.responseCode === 201 ||
            response.body.responseCode === 200
          ) {
            let token = response.body.token;
            this.tokenService.setToken(token);
            this.tokenService.setContent(false);
            this.tokenService.setPurpose('sign-up');
            this.otpTimerService.resetTimer();
            this.otpTimerService.startTimer();
            this.dialog.open(OtpTransferComponent, {
              panelClass: ['dialog-box'],
            });
          }
        },
        error: (data) => {
          this.loaderService.hide();
          this.verifyEmailResponseMessage = data.error.responseMessage;
        },
      });
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
      this.loaderService.hide();
      this.signupForm.markAllAsTouched();
    }
    localStorage.setItem('userDetails', JSON.stringify(this.createUserBody()));
  }

  togglePasswordVisibility() {
    this.isHidePassword = !this.isHidePassword;
  }
  validateReferralCode() {
    this.loaderService.show();
    this.userService.checkingReferralCode(this.referralCode).subscribe({
      next: (response: any) => {
        this.loaderService.hide();
        if (response.responseCode === 200) {
          this.loaderService.hide();
          this.isContentVisible = true;
        }
      },
      error: (data) => {
        this.loaderService.hide();
        this.isContentVisible = false;
      },
    });
  }
  goToSignup() {
    this.router.navigate(['/sign-up']);
  }

  clearVerifyEmailResponseMessage() {
    this.verifyEmailResponseMessage = null;
  }
}
