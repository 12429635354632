<input
  type="file"
  #addImage
  (change)="addImageEvt($event)"
  accept="image/*"
  multiple
  hidden
/>
<div class="p-2" *ngIf="addedImages.length > 0">
  <div class="row px-3">
    <mat-grid-list cols="3" rowHeight="1:1" [gutterSize]="'10px'">
      <mat-grid-tile *ngFor="let uploadImage of addedImages; let i = index">
        <div class="previewImage">
          <img [src]="uploadImage.imageUrl" />
          <button
            mat-icon-button
            class="previewRemoveIcon"
            (click)="removeImageFromList(i)"
          >
            <mat-icon class="p-0 m-0">close</mat-icon>
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
