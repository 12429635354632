<div class="container pt-4 px-4 pe-4">
  <div class="mb-0 p-0">
    <h3 class="m-0 fs-23 fw-500 lato-font-family pb-2 responsive-heading">
      Block {{ this.userData.profile?.fullName }}?
    </h3>
  </div>
  <div class="mb-0 p-0">
    <p class="m-0 fw-400 fs-16 lato-font-family responsive-heading">
      If you block {{ this.userData.profile?.fullName }} you can’t interact or
      see screams of the {{ this.userData.profile?.fullName }}
    </p>
  </div>
</div>
<div class="d-flex justify-content-end p-4">
  <button
    mat-raised-button
    class="rounded-pill active-link br-color lato-font-family fw-600 fs-14"
    (click)="closeDialog()"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2"
    type="submit"
    #btnFocus="matButton"
    [autofocus]="btnFocus.focus()"
    (click)="block()"
  >
    Block
  </button>
</div>
