<div>
  <ng-container>
    <div class="container-fluid p-4" *ngIf="isSubscribed">
      <div class="row">
        <div class="col-10">
          <h4 class="fw-600 fs-16 truncate">
            {{ scream.fullName }}
          </h4>
        </div>

        <div class="col-2">
          <mat-icon
            class="material-icons pointer m-0 float-right"
            (click)="closeDialog()"
            >close</mat-icon
          >
        </div>
      </div>
      <div class="row d-flex align-items-center justify-content-center">
        <div class="row wh p-0">
          <img
            src="../../../../assets/images/view-contact.svg"
            alt="view-user-contact"
            class="p-0"
          />
        </div>
        <div class="row d-flex py-3">
          <h4 class="fs-18 fw-600 text-center m-0">Contact info</h4>
        </div>
        <div class="row">
          <div class="col-1 p-0 d-flex align-items-center">
            <mat-icon class="material-symbols-outlined pointer m-0"
              >mail_outline</mat-icon
            >
          </div>
          <div class="col-11">
            <div class="row">
              <h5 class="fs-12 fw-400 m-0">Email</h5>
            </div>
            <div class="row">
              <h5 class="fs-16 fw-400 m-0 pl">
                <a
                  href="mailto:{{ contactDetails?.email }}"
                  class="active-link text-decoration-none"
                  >{{ contactDetails?.email }}</a
                >
              </h5>
            </div>
          </div>
        </div>
        <div
          class="row py-3"
          *ngIf="contactDetails?.mobileNumber !== (null || undefined)"
        >
          <div class="col-1 p-0 d-flex align-items-end">
            <mat-icon class="material-symbols-outlined pointer m-0"
              >phone</mat-icon
            >
          </div>
          <div class="col-11">
            <div class="row">
              <div>
                <h5 class="fs-12 fw-400 m-0">Mobile Number</h5>
              </div>
            </div>
            <div class="row">
              <h5 class="fs-16 fw-400 m-0">
                <a
                  href="tel:{{ contactDetails?.mobileNumber }}"
                  class="active-link text-decoration-none"
                  >{{ contactDetails?.mobileNumber }}</a
                >
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-4" *ngIf="!isSubscribed">
      <h2 class="text-center fw-500 fs-23 mt-3">Unlock User Contact</h2>
      <p class="text-center sub-text-color">
        Upgrade plan to view consented user contacts
      </p>
      <div
        class="w-100 d-flex align-items-center justify justify-content-center"
      >
        <button
          mat-raised-button
          class="btn-primary background-color lato-font-family white-color fw-500 fs-16 mb-3"
          (click)="navigateToPremium()"
        >
          Get Premium
        </button>
      </div>
    </div>
  </ng-container>
</div>
