import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shared',
  standalone: false,
  templateUrl: './shared.component.html',
  styleUrl: './shared.component.scss',
})
export class SharedComponent {
  @Input() currentState!: 'waitlist' | 'next' | 'turn';
  @Input() locationName!: string;

  sharedData = {
    waitlist: {
      icon: 'verified',
      title: 'You’re on the waitlist!',
      subtitle: 'Thanks for waiting!',
    },
    next: {
      icon: 'doorbell',
      title: 'You’re next!',
      subtitle: 'Please make your way back to {location}',
    },
    turn: {
      icon: 'doorbell',
      title: 'It’s your turn!',
      subtitle: 'Please make your way back to {location}',
    },
  };
}
