import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../config/app.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QueueService {
  private api = AppConfig.getQUEUEAPIURI() + 'saloon';

  constructor(private http: HttpClient) {}

  fetchOfferings(businessName: string): Observable<any> {
    return this.http.get(`${this.api}/offering/${businessName}/fetch/all`, {
      observe: 'response',
    });
  }
  fetchStylists(businessName: string): Observable<any> {
    return this.http.get(`${this.api}/stylist/${businessName}/fetch/all`, {
      observe: 'response',
    });
  }

  postService(service: any): Observable<any> {
    return this.http.post(`${this.api}/offering/create`, service, {
      observe: 'response',
    });
  }
  addCustomerToQueue(customerJoinQueueDTO: any): Observable<any> {
    return this.http.post(
      `${this.api}/customer/queue/join`,
      customerJoinQueueDTO,
      {
        observe: 'response',
      },
    );
  }
  postStaff(staff: any): Observable<any> {
    return this.http.post(`${this.api}/stylist/create`, staff, {
      observe: 'response',
    });
  }
  deleteStaff(uuid: any): Observable<any> {
    return this.http.delete(`${this.api}/stylist/delete/${uuid}`, {
      observe: 'response',
    });
  }
  deleteService(uuid: any): Observable<any> {
    return this.http.delete(`${this.api}/offering/delete/${uuid}`, {
      observe: 'response',
    });
  }
  updateServiceStatus(uuid: any, status: any): Observable<any> {
    return this.http.put(`${this.api}/offering/update/${uuid}/${status}`, {
      observe: 'response',
    });
  }
  updateStaffStatus(uuid: any, status: any): Observable<any> {
    return this.http.put(`${this.api}/stylist/update/${uuid}/${status}`, {
      observe: 'response',
    });
  }
  joinQueue(queueData: any): Observable<any> {
    return this.http.post(`${this.api}/customer/queue/join`, queueData, {
      observe: 'response',
    });
  }
  fetchWaitingStatus(businessName: string): Observable<any> {
    return this.http.get(
      `${this.api}/queue/${businessName}/fetch/waiting-stats/WAITING`,
      {
        observe: 'response',
      },
    );
  }
}
