import { LoginGuard } from './../core/_guard/login.guard';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { AuthGuard } from '../core/_guard/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BusinessAuthGuard } from '../core/_guard/businessauth.guard';
import { RefundPolicyComponent } from '../shared/refund-policy/refund-policy.component';

export const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivate: [BusinessAuthGuard, AuthGuard],
    title: 'Tell Us and We Will Make It Heard',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [BusinessAuthGuard, LoginGuard],
    title: 'Home',
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    title: 'Page Not Found',
  },
  {
    path: 'refund-policy',
    component: RefundPolicyComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeatureRoutingModule {}
