<span *ngIf="brands?.length <= 4" class="d-flex">
  <div *ngFor="let screamBrand of brands">
    <div class="flex-col rounded-circle border" *ngIf="screamBrand?.brandName">
      <img
        mat-card-avatar
        src="{{
          screamBrand?.logoImage
            ? blobStorageLink + screamBrand?.logoImage
            : businessDefaultImage
        }}"
        alt=""
        class="my-0 fit p-1"
      />
    </div>
  </div>
</span>

<span *ngIf="brands?.length > 4" class="d-flex">
  <div
    *ngFor="let screamBrand of brands.slice(0, 3)"
    class="flex-col rounded-circle border"
  >
    <img
      mat-card-avatar
      src="{{
        screamBrand?.logoImage
          ? blobStorageLink + screamBrand?.logoImage
          : businessDefaultImage
      }}"
      alt=""
      class="my-0 fit p-1"
    />
  </div>
  <div class="flex-col rounded-circle border">
    <span
      mat-card-avatar
      alt=""
      class="my-0 fit d-flex align-items-center justify-content-center ft-16"
      ><strong>+{{ brands?.length - 3 }}</strong></span
    >
  </div>
</span>
