import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { DataTransferService } from '../service/data-transfer/data-transfer.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly CONTENT_TYPE = 'Content-type';
  private readonly CONTENT_TYPE_VALUE = 'application/json';
  private readonly AUTHORIZATION = 'Authorization';
  private readonly CONTENT_TYPE_FILE_VALUE = 'multipart/form-data';

  constructor(private dataTransfer: DataTransferService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addAuthenticationToken(request);
    return next.handle(request);
  }

  addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const usertoken = this.dataTransfer.getUserToken();
    const businesstoken = this.dataTransfer.getBusinessToken();
    if (this.dataTransfer.isLoggedin()) {
      if (!request.headers.has(this.CONTENT_TYPE)) {
        request = request.clone({
          headers: request.headers.set(
            this.CONTENT_TYPE,
            this.CONTENT_TYPE_VALUE
          ),
        });
        if (!request.headers.has(this.AUTHORIZATION) && usertoken) {
          request = request.clone({
            headers: request.headers.set(this.AUTHORIZATION, usertoken),
          });
        }
      } else if (
        request.headers.get(this.CONTENT_TYPE) == this.CONTENT_TYPE_FILE_VALUE
      ) {
        request = request.clone({
          headers: request.headers.delete(this.CONTENT_TYPE),
        });
        if (usertoken) {
          request = request.clone({
            headers: request.headers.set(this.AUTHORIZATION, usertoken),
          });
        }
      }
    } else if (this.dataTransfer.isBusinessLoggedin()) {
      if (!request.headers.has(this.CONTENT_TYPE)) {
        request = request.clone({
          headers: request.headers.set(
            this.CONTENT_TYPE,
            this.CONTENT_TYPE_VALUE
          ),
        });
        if (!request.headers.has(this.AUTHORIZATION) && businesstoken) {
          request = request.clone({
            headers: request.headers.set(this.AUTHORIZATION, businesstoken),
          });
        }
      } else if (
        request.headers.get(this.CONTENT_TYPE) == this.CONTENT_TYPE_FILE_VALUE
      ) {
        request = request.clone({
          headers: request.headers.delete(this.CONTENT_TYPE),
        });
        if (businesstoken) {
          request = request.clone({
            headers: request.headers.set(this.AUTHORIZATION, businesstoken),
          });
        }
      }
    }
    return request;
  }
}
