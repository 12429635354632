import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class HeaderService {
  visible: boolean;
  private imageSubject = new Subject<string>();
  imageSubject$: Observable<string> = this.imageSubject.asObservable();
  private fullNameSubject = new Subject<string>();
  fullNameSubject$: Observable<string> = this.fullNameSubject.asObservable();
  constructor() {
    this.visible = true;
  }
  hide() {
    this.visible = false;
  }
  show() {
    this.visible = true;
  }
  updateImage(imageUrl: string) {
    this.imageSubject.next(imageUrl);
  }
  updatefullName(fullName: string) {
    this.fullNameSubject.next(fullName);
  }
}
