import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Scream } from './../../core/interfaces/scream';
import { LoaderService } from './../../core/service/loader/loader.service';
import { ScreamService } from './../../core/service/screamService/scream.service';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { SliderService } from './../../core/service/sliderService/slider.service';
import { DeleteScreamComponent } from '../delete-scream/delete-scream.component';

@Component({
  selector: 'app-positive-feedback',
  templateUrl: './positive-feedback.component.html',
  styleUrls: ['./positive-feedback.component.scss'],
})
export class PositiveFeedbackComponent implements OnInit {
  max = 99;
  min = 0;
  step = 1;
  value = 0;
  emotionIndex = 0;
  scream!: Scream;
  suid: any;
  feedback = ['satisfied', 'happy', 'joyful', 'delighted', 'blissful'];
  constructor(
    public dialogRef: MatDialogRef<DeleteScreamComponent>,
    private loaderService: LoaderService,
    public screamService: ScreamService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private sliderService: SliderService,
    public snackBarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    this.suid = this.activatedRoute.snapshot.queryParams['suid'];
  }
  reset() {
    this.value = 0;
    this.emotionIndex = 0;
  }
  getState(value: any) {
    this.emotionIndex = this.sliderService.getSliderValue(value);
  }

  submit(status: string) {
    this.screamService.resolveScream(this.suid, this.emotionIndex).subscribe({
      next: (data: any) => {
        if (data.body.responseCode == 200) {
          this.loaderService.show();

          setTimeout(() => {
            this.loaderService.hide();
            this.dialogRef.close({
              experience: this.feedback[this.emotionIndex],
              status,
            });
            this.snackBarService.openSnackBar(
              data.body.responseMessage,
              'success-snackbar'
            );
          }, 1000);
        }
      },
      error: (data) => {
        if (data.status == 0) {
          this.snackBarService.openSnackBar(
            'Internal server error. Please try again!',
            'error-snackbar'
          );
        } else {
          this.snackBarService.openSnackBar(
            data.error.responseMessage,
            'error-snackbar'
          );
        }
      },
    });
  }

  skip(suid: string, status: string) {
    this.updateScreamStatus(suid, status);
    this.loaderService.show();
    setTimeout(() => {
      this.dialogRef.close({ status: status });
      this.loaderService.hide();
      this.snackBarService.openSnackBar(
        'Your scream has been resolved Successfully',
        'success-snackbar'
      );
    }, 1000);
  }
  updateScreamStatus(suid: string, status: string) {
    this.screamService.updateScreamStatus({ suid, status }).subscribe({
      next: (data: any) => {
        if (data.body.responseCode === 200 && this.scream) {
          this.scream.screamStatus = status;
        }
        this.loaderService.hide();
      },
      error: () => {
        this.loaderService.hide();
      },
    });
  }
}
