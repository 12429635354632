import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-queue-header',
  templateUrl: './queue-header.component.html',
  styleUrls: ['./queue-header.component.scss'],
})
export class QueueHeaderComponent implements OnInit {
  brandName: any;
  welcomePayload: any;
  brand!: 'fernandez' | 'openai' | 'brandC' | 'default';

  brandLogos: Record<'fernandez' | 'openai' | 'brandC' | 'default', string> = {
    fernandez: 'assets/images/fernandez.svg',
    openai: 'assets/images/naturals-logo.svg',
    brandC: 'assets/images/brandC-logo.svg',
    default: 'assets/images/default-logo.svg',
  };

  setBrand(brand: 'fernandez' | 'openai' | 'brandC' | 'default'): void {
    this.brand = brand;
  }
  get currentLogo(): string {
    return this.brandLogos[this.brand];
  }

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    // this.route.paramMap.subscribe((params: { get: (arg0: string) => any }) => {
    //   this.brandName = params.get('brandname');
    // });
    // this.retrieveStepOneData();
    // this.setBrand(this.brandName || 'default');

    this.route.paramMap.subscribe((params) => {
      const brandFromRoute = params.get('brandname');
      if (brandFromRoute) {
        this.brandName = brandFromRoute.toLowerCase();
        this.setBrand(this.brandName);
      }
    });

    // Retrieve brand from local storage if available
    this.retrieveStepOneData();
  }

  retrieveStepOneData() {
    const stepOneData = localStorage.getItem('stepOneFormData');
    const brand = localStorage.getItem('brandName');
    if (brand) {
      this.brandName = JSON.parse(brand)?.toLowerCase();
      if (this.brandName) {
        this.setBrand(this.brandName);
      }
    } else if (stepOneData) {
      this.welcomePayload = JSON.parse(stepOneData || '');
      this.brandName = this.welcomePayload?.brandName?.toLowerCase();
      if (this.brandName) {
        this.setBrand(this.brandName);
      }
    }
  }
}
