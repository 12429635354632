import { Component, OnInit } from '@angular/core';
import { BottomNavBarService } from '../../core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from '../../core/service/header/header.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrl: './thank-you.component.scss',
})
export class ThankYouComponent implements OnInit {
  constructor(
    private readonly headerService: HeaderService,
    private readonly bottomNavService: BottomNavBarService,
  ) {}

  ngOnInit(): void {
    this.headerService.hide();
    this.bottomNavService.hide();
  }
}
