<div class="fixed-width bottom py-2" *ngIf="isScreamResolved">
  <span class="d-flex py-2 px-3 align-items-center">
    <h4 class="col-6 d-flex fw-600 m-0">Is your scream resolved?</h4>
    <span class="col-6 d-flex justify-content-end">
      <button
        mat-raised-button
        class="btn hover-color btn-height active-link lato-font-family fw-700 fs-14 px-4 mx-1"
        (click)="
          updateScreamStatus(scream.suid, 'PENDING'); isScreamResolved = false
        "
      >
        No
      </button>
      <button
        mat-raised-button
        class="background-color btn-primary btn-height lato-font-family white-color fw-700 fs-14 px-4 mx-1"
        (click)="getFeedback(); isScreamResolved = false"
      >
        Yes
      </button>
    </span>
  </span>
</div>
