import { HeaderService } from './../../core/service/header/header.service';
import { BottomNavBarService } from './../../core/service/bottomNavBar/bottom-nav-bar.service';
import { AfterContentInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [NgbCarouselConfig, NgbCarousel],
})
export class LandingComponent implements AfterContentInit {
  constructor(
    public readonly router: Router,
    public readonly bottomService: BottomNavBarService,
    public readonly headerService: HeaderService
  ) {}
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.headerService.show();
    }, 0);
    setTimeout(() => {
      this.bottomService.show();
    }, 0);
  }
  goToSignUp() {
    this.router.navigate(['/sign-up']);
  }
}
