import { TokenService } from './../../../core/service/tokenService/token.service';
import {
  AfterContentInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTransferService } from './../../../core/service/data-transfer/data-transfer.service';
import { HeaderService } from './../../../core/service/header/header.service';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../../logout/logout.component';
@Component({
  selector: 'app-business-header',
  templateUrl: './business-header.component.html',
  styleUrls: ['./business-header.component.scss'],
})
export class BusinessHeaderComponent implements OnInit, AfterContentInit {
  isInputEmpty: boolean = true;
  userInput: boolean = false;
  inputLength: number = 0;
  searchControl: FormControl = new FormControl('');
  userProfileImage: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  showSearchCard = false;
  verificationStatus!: string;
  brandDetails: any;
  businessProfileImage: any;
  isCardOpen = false;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  businessVerificationStatus = false;

  constructor(
    public headerService: HeaderService,
    private router: Router,
    private readonly dataTransferService: DataTransferService,
    public tokenService: TokenService,
    public dialog: MatDialog,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.brandDetails = JSON.parse(
      localStorage.getItem('BrandDetails') || '{}',
    );
    if (this.brandDetails) {
      this.businessProfileImage = this.brandDetails.logoImage
        ? this.blobStorageLink + this.brandDetails.logoImage
        : this.dataTransferService.businessDefaultImage;

      this.verificationStatus = this.brandDetails.documentVerificationStatus;
    }
  }

  ngAfterContentInit(): void {
    this.headerService.imageSubject$.subscribe((imageUrl) => {
      if (
        JSON.parse(this.dataTransferService.getBrandDetails())
          ?.documentVerificationStatus === 'VERIFIED' &&
        imageUrl
      ) {
        this.businessProfileImage = this.blobStorageLink + imageUrl;
      } else {
        this.businessProfileImage = this.businessDefaultImage;
      }
    });
  }
  loggedIn(): boolean {
    return this.dataTransferService.isLoggedin();
  }
  businessLoggedIn(): boolean {
    return this.dataTransferService.isBusinessLoggedin();
  }

  claim() {
    return this.router.navigate(['/business/claim-business']);
  }
  goToLogout() {
    this.isCardOpen = false;
    this.tokenService.setContent(false);
    const dialogRef = this.dialog.open(LogoutComponent, {
      width: '300px',
      panelClass: ['border-radius'],
      autoFocus: false,
    });
    document.body.classList.add('cdk-global-scrollblock');
    dialogRef.afterClosed().subscribe(() => {
      document.body.classList.remove('cdk-global-scrollblock');
    });
  }

  openCard() {
    if (this.isCardOpen) {
      this.isCardOpen = false;
    } else {
      this.isCardOpen = true;
    }
  }
  businessSignup() {
    return this.router.navigate(['/business/sign-up']);
  }
  businessLogin() {
    return this.router.navigate(['/business/login']);
  }
  goToBusinessLanding() {
    return this.router.navigate(['/business']);
  }
  goToBusinessHome() {
    if (this.verificationStatus === 'VERIFIED') {
      this.router.navigate(['/business/home']);
    }
  }
  headerForBusinessBeforeLogin(): boolean {
    const beforeLoginPaths = [
      '/business',
      '/business/claim-business',
      '/business/login',
      '/business/sign-up',
    ];
    return beforeLoginPaths.includes(this.router.url);
  }
  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isCardOpen = false;
    }
  }
}
