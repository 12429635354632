<form [formGroup]="form">
  <div class="d-flex justify-content-center align-items-center">
    <div class="form-group px-2" *ngFor="let input of formInput; index as i">
      <input
        #formRow
        inputmode="numeric"
        type="text"
        class="border-0 border-bottom"
        formControlName="{{ input }}"
        maxlength="1"
        (keyup)="keyUpEvent($event, i)"
      />
    </div>
  </div>
</form>
