<div class="text-center pt-4">
  <mat-icon
    class="fs-80 active-link"
    [ngClass]="
      sharedData[currentState].icon === 'verified'
        ? 'material-icons'
        : 'material-icons-outlined'
    "
  >
    {{ sharedData[currentState]?.icon }}
  </mat-icon>
  <h1 class="fw-700 fs-24">{{ sharedData[currentState]?.title }}</h1>
  <p class="fw-700 fs-18 text-color">
    {{ sharedData[currentState].subtitle.replace("{location}", locationName) }}
  </p>
</div>
