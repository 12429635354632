import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DataTransferService } from '../service/data-transfer/data-transfer.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private dataTransferService: DataTransferService
  ) {}

  canActivate(): boolean {
    const isLoggedIn = this.dataTransferService.isLoggedin();

    if (isLoggedIn) {
      this.router.navigate(['/home']);

      return false;
    }

    return true;
  }
}
