import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BottomNavBarService } from '../../../core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from '../../../core/service/header/header.service';

@Component({
  selector: 'app-t-hub-landing',
  templateUrl: './t-hub-landing.component.html',
  styleUrl: './t-hub-landing.component.scss',
})
export class THubLandingComponent implements OnInit {
  constructor(
    public router: Router,
    private readonly headerService: HeaderService,
    private readonly bottomNavService: BottomNavBarService,
  ) {}
  ngOnInit(): void {
    this.headerService.hide();
    this.bottomNavService.hide();
  }

  navigateToReview() {
    this.router.navigate(['t-hub-review']);
  }
  navigateToThubEvent() {
    window.open('https://t-hub.co/host-an-event/', '_blank');
  }
}
