<div
  class="d-flex container align-items-center py-2"
  *ngFor="let number of [0, 1, 2]"
>
  <div class="container-fluid p-0">
    <div class="d-flex container-fluid m-0 p-0">
      <div class="px-3">
        <scremer-shimmer [shape]="'circle'" [width]="'40px'"> </scremer-shimmer>
      </div>
      <div class="d-flex flex-column px-0 w-100">
        <div class="px-0">
          <scremer-shimmer
            [shape]="'rect'"
            [width]="'156px'"
            [height]="'19px'"
            [borderRadius]="'20px'"
          ></scremer-shimmer>
        </div>
        <div class="px-0">
          <scremer-shimmer
            [shape]="'rect'"
            [width]="'92px'"
            [height]="'19px'"
            [borderRadius]="'20px'"
          ></scremer-shimmer>
        </div>
      </div>
      <div class="mt-2 px-0">
        <scremer-shimmer
          [shape]="'rect'"
          [width]="'109px'"
          [height]="'32px'"
          [borderRadius]="'20px'"
        ></scremer-shimmer>
      </div>
    </div>
  </div>
</div>
