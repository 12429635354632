import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceSelectionComponent } from './service-selection/service-selection.component';
import { SharedModule } from '../shared/shared.module';
import { QueueRoutingModule } from './queue-routing.module';
import { WaitingComponent } from './waiting/waiting.component';
import { SharedComponent } from './waiting/shared/shared.component';
import { TimerComponent } from './waiting/timer/timer.component';
import { EstimatedTimeComponent } from './waiting/estimated-time/estimated-time.component';
import { MatIconModule } from '@angular/material/icon';
import { StylistSelectionComponent } from '../user/stylist-selection/stylist-selection.component';
import { WelcomeQueueComponent } from '../shared/welcome-queue/welcome-queue.component';
import { JoinQueueComponent } from '../user/join-queue/join-queue.component';

@NgModule({
  declarations: [
    ServiceSelectionComponent,
    WaitingComponent,
    SharedComponent,
    TimerComponent,
    EstimatedTimeComponent,

    StylistSelectionComponent,
    WelcomeQueueComponent,
    JoinQueueComponent,
  ],
  exports: [],
  providers: [],
  imports: [QueueRoutingModule, CommonModule, SharedModule, MatIconModule],
})
export class QueueModule {}
