<div class="row">
  <div class="col-8 pointer mt-3">
    <mat-icon
      class="material-icons active-link"
      (click)="uploadImageToScream()"
    >
      filter
    </mat-icon>
    <mat-icon class="material-icons active-link ms-4 carousel-indicators">
      sentiment_very_satisfied
    </mat-icon>
    <mat-icon
      class="material-icons-outlined active-link ms-4 carousel-indicators"
    >
      location_on
    </mat-icon>
    <mat-icon
      class="material-icons-outlined active-link ms-4 carousel-indicators"
    >
      local_offer
    </mat-icon>
  </div>
</div>
