<div
  class="overlay d-flex justify-content-center align-items-center"
  *ngIf="showContent"
>
  <div class="spinner-container">
    <div class="mat-spinner"></div>
  </div>
</div>

<div *ngIf="!showContent">
  <app-user-header *ngIf="showHeader"></app-user-header>
  <app-business-header *ngIf="showHeader"></app-business-header>
  <app-loader></app-loader>

  <div
    [ngClass]="
      loggedIn() || isBusinessLoggedIn()
        ? 'container max-width  p-0'
        : 'container-fluid p-0'
    "
  >
    <div
      [ngClass]="
        loggedIn() || isBusinessLoggedIn()
          ? 'd-flex justify-content-between'
          : checkRouteBeforeLogin()
      "
    >
      <div
        *ngIf="(isBusinessLoggedIn() || loggedIn()) && showSideNav"
        class="d-none d-sm-block col-12 col-sm-2 col-md-3 col-lg-3 p-0 layout-max-width"
      >
        <app-side-nav-bar
          *ngIf="isBusinessLoggedIn() || loggedIn()"
          [ngClass]="hideSideNav()"
        >
        </app-side-nav-bar>
      </div>
      <div [ngClass]="routerOutletClassFromRouter()">
        <router-outlet></router-outlet>
      </div>
      <div
        *ngIf="(loggedIn() || isBusinessLoggedIn()) && showSideNav"
        class="d-none d-lg-block col-12 col-lg-3 p-0"
        [ngClass]="
          this.isDashboard() || this.isPaymentHistory()
            ? 'dashboard-right-layout-width'
            : 'right-layout-max-width'
        "
      ></div>
    </div>
  </div>
  <app-bottom-nav-bar [ngClass]="businessBottomNav()"></app-bottom-nav-bar>
</div>
