<div class="container p-4">
  <h1 class="fw-600">How are you feeling?</h1>
  <h4 class="fs-16">
    Tell us how you are feeling about the brand response to your scream
  </h4>

  <div>
    <div
      class="d-flex justify-content-start"
      id="{{ 'container-' + emotionIndex }}"
    >
      <mat-slider #slider [max]="max" [min]="min" [disableRipple]="true">
        <input matSliderThumb [(ngModel)]="value" (change)="getState(value)" />
      </mat-slider>
    </div>
    <p class="d-flex justify-content-end fs-16 resolved-state">
      {{ feedback[emotionIndex] }}
    </p>
  </div>

  <div class="d-flex justify-content-end py-1 pt-4 mt-2">
    <button
      mat-raised-button
      class="rounded-pill active-link br-color lato-font-family fw-600 fs-14 buttons"
      (click)="skip(suid, 'CLOSED')"
    >
      Skip
    </button>
    <button
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2 buttons"
      type="submit"
      (click)="submit('CLOSED')"
    >
      Submit
    </button>
  </div>
</div>
