import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './core/service/loader/loader.service';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BottomNavBarComponent } from './shared/bottom-nav-bar/bottom-nav-bar.component';
import { BottomNavBarListerComponent } from './shared/bottom-nav-bar/bottom-nav-bar-lister/bottom-nav-bar-lister.component';
import { MaterialModule } from './core/material/material.module';
import { DataTransferService } from './core/service/data-transfer/data-transfer.service';
import { BottomNavBarService } from './core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from './core/service/header/header.service';
import { TokenInterceptor } from './core/interceptor/token.interceptor';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { APP_ID, NgModule } from '@angular/core';
import { LoginGuard } from './core/_guard/login.guard';
import { WebSocketService } from './core/service/websocketService/websocket.service';
import { SearchBarService } from './core/service/search/search-bar.service';
import { SideNavBarComponent } from './shared/side-nav-bar/side-nav-bar.component';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AppConfig } from './core/config/app.config';
import { UserHeaderComponent } from './shared/header/user-header/user-header.component';
import { BusinessHeaderComponent } from './shared/header/business-header/business-header.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import {
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { SharedModule } from './shared/shared.module';
import { DownloadScremerComponent } from './download-scremer/download-scremer.component';
import { TitleStrategy } from '@angular/router';
import { CustomTitleStratergyService } from './core/service/custom-titleService/custom-title-stratergy.service';

@NgModule({
  declarations: [
    AppComponent,
    UserHeaderComponent,
    BusinessHeaderComponent,
    BottomNavBarComponent,
    BottomNavBarListerComponent,
    LoaderComponent,
    SideNavBarComponent,
    DownloadScremerComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    // provideAuth(() => getAuth()),
    // provideFirestore(() => getFirestore()),
  ],
  providers: [
    DataTransferService,
    BottomNavBarService,
    SearchBarService,
    HeaderService,
    LoaderService,
    LoginGuard,
    WebSocketService,
    AppConfig,
    SsrCookieService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: APP_ID, useValue: 'scremerServer' },
    { provide: TitleStrategy, useClass: CustomTitleStratergyService },
    // provideHttpClient(() => withFetch()),
    ScreenTrackingService,
    UserTrackingService,
    provideHttpClient(withFetch()),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
