import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Scream } from '../../core/interfaces/scream';
import { LoaderService } from '../../core/service/loader/loader.service';
import { ScreamService } from '../../core/service/screamService/scream.service';
import { MatDialog } from '@angular/material/dialog';
import { PositiveFeedbackComponent } from '../positive-feedback/positive-feedback.component';
import { SnackbarService } from '../../core/service/snackBar/snackbar.service';
@Component({
  selector: 'app-resolve-scream',
  templateUrl: './resolve-scream.component.html',
  styleUrls: ['./resolve-scream.component.scss'],
})
export class ResolveScreamComponent {
  @Input() scream!: Scream;
  isScreamResolved: boolean = true;
  @Output() feedbackResponseEvent = new EventEmitter<any>();
  constructor(
    public loaderService: LoaderService,
    public screamService: ScreamService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService
  ) {}
  updateScreamStatus(suid: string, status: string) {
    this.screamService.updateScreamStatus({ suid, status }).subscribe({
      next: (data: any) => {
        if (data.body.responseCode === 200 && this.scream) {
          this.scream.screamStatus = status;
          if (status === 'CLOSED') {
            this.snackbarService.openSnackBar(
              'Your scream has been resolved successfully',
              'success-snackbar'
            );
          } else if (status === 'PENDING') {
            this.snackbarService.showTooltip(true);
            this.snackbarService.focusTextarea();
          }
        }
        this.loaderService.hide();
      },
      error: () => {
        this.loaderService.hide();
      },
    });
  }
  getFeedback() {
    this.dialog
      .open(PositiveFeedbackComponent, {
        width: '348px',
        panelClass: ['dialog-boxes'],
        disableClose: true,
      })
      .afterClosed()
      .subscribe((response) => {
        this.feedbackResponseEvent.emit(response);
      });
  }
}
