import { AfterContentInit, Component } from '@angular/core';
import { HeaderService } from './../../core/service/header/header.service';
import { BottomNavBarService } from './../../core/service/bottomNavBar/bottom-nav-bar.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements AfterContentInit {
  constructor(
    private readonly headerService: HeaderService,
    private readonly bottomService: BottomNavBarService,
    private location: Location
  ) {}
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.bottomService.hide();
    }, 0);
    setTimeout(() => {
      this.headerService.hide();
    }, 0);
  }
  goBackToPreviousPage() {
    this.location.back();
  }
}
