import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';
import { Category } from '../../interfaces/category';
import { SubCategory } from '../../interfaces/sub-category';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private api = AppConfig.getAPIURI() + 'category/';

  constructor(private http: HttpClient) {}
  getCategories(): Observable<any> {
    return this.http.get<Category[]>(this.api + 'get-categories', {
      observe: 'response',
    });
  }
  getSubCategories(categoryId: any): Observable<any> {
    return this.http.get<SubCategory[]>(
      this.api + `get-subcategories/${categoryId}`,
      {
        observe: 'response',
      }
    );
  }
}
