import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class StorageBlobService {
  imageUrl: any;
  private api = AppConfig.getAPIURI() + 'blob';

  constructor(private http: HttpClient) {}
  uploadImage(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    return this.http.post(this.api + '/upload-images', formData, {
      observe: 'response',
      headers: headers,
    });
  }
  uploadDoc(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    return this.http.post(this.api + '/upload-business-document', formData, {
      observe: 'response',
      headers: headers,
    });
  }
}
