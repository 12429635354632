import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { UserService } from './../../core/service/userService/user.service';
import { TokenService } from './../../core/service/tokenService/token.service';
import { ScreamService } from './../../core/service/screamService/scream.service';
import { BrandService } from '../../core/service/brandService/brand.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  suid: any;
  @Input() userName: any;
  @Input() scream: any;
  currentUserName: any;
  platformId: Object;
  constructor(
    private bottomSheetRef: MatBottomSheetRef<ShareComponent>,
    public router: Router,
    public screamService: ScreamService,
    private snackBarService: SnackbarService,
    public userService: UserService,
    public tokenService: TokenService,
    public brandService: BrandService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId;
  }

  brandName: any;
  ngOnInit(): void {
    this.suid = this.data.suid;
    this.profile = this.data.profile;
  }
  uuid: any;
  profile: any;
  copyLink() {
    if (isPlatformBrowser(this.platformId)) {
      let url = window.location.origin;
      if (this.suid !== null && this.suid !== undefined) {
        url += '/scream?suid=' + this.data.suid;
      } else if (
        this.profile.brandName !== null &&
        this.profile.brandName !== undefined
      ) {
        url += '/brand/' + this.profile?.brandName;
      } else if (
        this.profile.userName !== null &&
        this.profile.userName !== undefined
      ) {
        url += '/' + this.profile?.userName;
      }
      const input = document.createElement('input');
      input.setAttribute('value', url);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.bottomSheetRef.dismiss();
      this.snackBarService.openSnackBar('Link Copied', 'success-snackbar');
    }
  }
  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }
}
