<div class="d-flex align-items-center justify-content-center mx-md-5 mb-3">
  <div class="flex-grow-1 seperation-line-signUp"></div>
  <div class="px-3 fs-14 fw-600">Or</div>
  <div class="flex-grow-1 seperation-line-signUp"></div>
</div>
<div class="d-flex justify-content-center mt-2 mx-md-5">
  <a class="w-100" (click)="signUpWithGoogle()">
    <button
      class="py-2 px-3 fw-600 w-100 fs-16 google black-color lato-font-family flex-shrink-0 background-white-color"
    >
      <img src="assets/images/google-icon.svg" class="pe-2" alt="" />
      Continue with Google
    </button>
  </a>
</div>
