import { Component, HostListener, OnInit } from '@angular/core';
import { HeaderService } from '../../core/service/header/header.service';
import { QueueService } from 'src/app/core/service/queue/queue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/core/service/loader/loader.service';

@Component({
  selector: 'app-stylist-selection',
  templateUrl: './stylist-selection.component.html',
  styleUrls: ['./stylist-selection.component.scss'],
})
export class StylistSelectionComponent implements OnInit {
  welcomePayload: any;
  stylistUid: any;

  constructor(
    private readonly headerService: HeaderService,
    private router: Router,
    private queueService: QueueService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
  ) {}
  list: any[] = [];
  selectedStylist: string | null = null;
  isDropdownOpen: boolean = false;
  brandName: any;
  errorMessage: any;
  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  ngOnInit() {
    this.hideHeader();
    this.retrieveStepOneData();
    this.loadSelectedStylist();
  }

  private loadSelectedStylist() {
    const stylistData = localStorage.getItem('stepThreeFormData');
    if (stylistData) {
      const parsedStylistData = JSON.parse(stylistData);
      this.selectedStylist = parsedStylistData.name;
      this.stylistUid = parsedStylistData.uuid;
    }
  }

  private retrieveStepOneData() {
    const stepOneData = localStorage.getItem('stepOneFormData');
    if (stepOneData) {
      this.welcomePayload = JSON.parse(stepOneData || '');
      this.fetchStylists(this.welcomePayload.brandName);
    }
  }
  hideHeader() {
    setTimeout(() => {
      this.headerService.hide();
    }, 0);
  }
  selectStylist(stylist: any): void {
    this.selectedStylist = stylist.name;
    this.stylistUid = stylist.uuid;
    this.isDropdownOpen = false;
    const stylistData = {
      name: this.selectedStylist,
      uuid: this.stylistUid,
    };

    localStorage.setItem('stepThreeFormData', JSON.stringify(stylistData));
  }
  continue() {
    this.router.navigateByUrl('/queue/join-queue');
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const dropdownElement = document.querySelector('.dropdown-container');
    if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
      this.isDropdownOpen = false;
    }
  }
  onSkipClick() {
    this.router.navigateByUrl('queue/join-queue');
  }
  fetchStylists(brandName: any): void {
    this.loaderService.show();
    this.queueService.fetchStylists(brandName)?.subscribe({
      next: (response) => {
        if (response && response.body.responseCode === 200) {
          this.loaderService.hide();
          this.list = (response.body.responseBody || []).filter(
            (item: { status: string }) => item.status === 'AVAILABLE',
          );
        }
      },
      error: (error) => {
        this.loaderService.hide();
        console.error('Error fetching stylists:', error);
        this.errorMessage = error.message || 'An unknown error occurred';
      },
    });
  }
}
