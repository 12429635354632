import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
@Component({
  selector: 'app-save-draft',
  templateUrl: './save-draft.component.html',
  styleUrls: ['./save-draft.component.scss'],
  providers: [MatButtonModule],
})
export class SaveDraftComponent {
  urlWithoutQueryParam!: any;
  constructor(
    public dialog: MatDialog,
    private readonly snackBarService: SnackbarService,
    public location: Location,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeDialog() {
    localStorage.removeItem('screamDraft');
    this.dialog.closeAll();
    this.queryParamNavigation();
  }

  saveDraft() {
    const serializedData = JSON.stringify(this.data.screamDetails);
    localStorage.setItem('screamDraft', serializedData);

    this.snackBarService.openSnackBar(
      'Scream saved as draft',
      'success-snackbar'
    );
    this.dialog.closeAll();
    this.queryParamNavigation();
  }

  queryParamNavigation() {
    if (this.route.url?.includes('qrCode=true') && !this.data.brandRequest) {
      this.removeQrCodeQueryParam();
    } else if (
      this.route.url?.includes('scream_it=true') &&
      !this.data.brandRequest
    ) {
      this.removeQrCodeQueryParam();
      this.location.back();
    } else if (
      this.route.url?.includes('qrCode=true') &&
      this.data.brandRequest
    ) {
      this.removeQrCodeQueryParam();
      this.navigateToBrandRequest();
    } else if (
      this.route.url?.includes('scream_it=true') &&
      this.data.brandRequest
    ) {
      this.removeQrCodeQueryParam();
      this.navigateToBrandRequest();
    } else if (this.data.brandRequest) {
      this.navigateToBrandRequest();
    }
  }

  removeQrCodeQueryParam() {
    if (this.route.url?.includes('qrCode=true')) {
      this.urlWithoutQueryParam = this.route.url?.replace(
        /(\?|\&)qrCode=true/,
        ''
      );
      this.location.replaceState(this.urlWithoutQueryParam);
    } else if (this.route.url?.includes('scream_it=true')) {
      this.urlWithoutQueryParam = this.route.url?.replace(
        /(\?|\&)scream_it=true/,
        ''
      );
      this.location.replaceState(this.urlWithoutQueryParam);
    }
  }

  navigateToBrandRequest() {
    this.route.navigate(['/brand/brand-request']);
  }
}
