<div *ngFor="let number of [0, 1, 2]">
  <div class="container-fluid d-flex m-0 my-3">
    <div class="p-0 mt-2">
      <scremer-shimmer [shape]="'circle'" [width]="'40px'"> </scremer-shimmer>
    </div>
    <div class="px-2 mt-2 p-0 container-fluid">
      <scremer-shimmer
        [shape]="'rect'"
        width="100"
        [height]="'15px'"
        [borderRadius]="'10px'"
      ></scremer-shimmer>
      <scremer-shimmer
        [shape]="'rect'"
        [width]="'50px'"
        [height]="'15px'"
        [borderRadius]="'10px'"
      ></scremer-shimmer>
    </div>
  </div>
</div>
