import { Component, Input } from '@angular/core';
import { HospitalPatientQueueService } from 'src/app/core/service/hospitalPatientQueue/hospital-patient-queue.service';
import { HospitalService } from 'src/app/core/service/hospitalService/hospital.service';
import { LoaderService } from 'src/app/core/service/loader/loader.service';

@Component({
  selector: 'app-estimated-time',
  standalone: false,
  templateUrl: './estimated-time.component.html',
  styleUrl: './estimated-time.component.scss',
})
export class EstimatedTimeComponent {
  @Input() placeInLine!: number;
  @Input() estimatedTime: any;
  @Input() serviceNumber!: string;
  @Input() ticketNumber!: number;
  errorMessage: any;

  constructor(
    private hospitalService: HospitalService,
    private loaderService: LoaderService,
    private hospitalPatientQueue: HospitalPatientQueueService,
  ) {}

  quitQueue() {
    this.loaderService.show();
    console.log('quit called');
    const quid = localStorage.getItem('queueId');
    const queueId = quid ? JSON.parse(quid) : null;

    this.hospitalPatientQueue.quitQueue(queueId).subscribe({
      next: (response) => {
        if (response && response.body.responseCode === 200) {
          this.loaderService.hide();
          console.log('quit done');
          return true;
        }
      },
      error: (error) => {
        console.log('quit error');
        this.loaderService.hide();
        this.errorMessage = error.message || 'An unknown error occurred';
      },
    });
  }
}
