import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-submit',
  templateUrl: './report-submit.component.html',
  styleUrls: ['./report-submit.component.scss'],
})
export class ReportSubmitComponent {
  constructor(private dialog: MatDialog) {}

  closeDialog() {
    this.dialog.closeAll();
  }
}
