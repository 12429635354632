<div class="container pt-md-3 pt-8">
  <div class="row">
    <div
      class="col-12 col-lg-6 d-flex align-items-center justify-content-center display-desktop display-desktop-only"
    >
      <img
        src="assets/images/Sign-Up-Image.svg"
        alt="login-image"
        class="img-fluid"
      />
    </div>
    <div
      class="col-12 col-lg-6 d-flex align-items-center justify-content-center"
    >
      <mat-card
        class="p-3 w-100 p-0 mt-5 mt-lg-0 shadow box-shadow py-3 py-md-4 border-radius max-width"
      >
        <div class="d-flex justify-content-center">
          <img
            class="pt-3"
            src="assets/images/ScremerLogo.svg"
            alt="scremer-logo.svg"
          />
        </div>
        <form
          class="m-md-5 mt-md-4 mb-md-0"
          [formGroup]="loginForm"
          (ngSubmit)="login()"
        >
          <mat-form-field class="w-100">
            <mat-icon class="grey-disable-color" matPrefix
              >mail_outline</mat-icon
            >

            <mat-label>Email</mat-label>

            <input
              matInput
              placeholder="Email"
              type="email"
              formControlName="email"
              autofocus
            />

            <mat-error
              *ngIf="
                this.loginForm.controls['email'].hasError('pattern') &&
                !this.loginForm.controls['email'].hasError('required')
              "
              class="lh-1"
            >
              Please enter a valid email address
            </mat-error>
            <mat-error
              *ngIf="this.loginForm.controls['email'].hasError('required')"
              class="lh-1"
            >
              Email address <strong> cannot be empty</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-icon matPrefix class="grey-disable-color"
              >lock_outline</mat-icon
            >
            <mat-label>Password</mat-label>
            <input
              matInput
              placeholder="Password"
              formControlName="password"
              [type]="isHidePassword ? 'password' : 'text'"
            />
            <mat-icon
              matSuffix
              class="grey-disable-color"
              (click)="togglePasswordVisibility()"
              >{{ isHidePassword ? "visibility_off" : "visibility" }}</mat-icon
            >
            <mat-error
              *ngIf="this.loginForm.controls['password'].hasError('required')"
              class="lh-1"
            >
              password <strong> cannot be empty</strong>
            </mat-error>
            <mat-error
              *ngIf="
                this.loginForm.controls['password'].hasError('minlength') &&
                !loginForm.hasError('pattern')
              "
              class="lh-1"
            >
              Password should be 6 characters long
            </mat-error>

            <mat-error
              class="lh-1"
              *ngIf="
                this.loginForm.controls['password'].hasError('pattern') &&
                !loginForm.controls['password'].hasError('minlength')
              "
              >Password should contain one uppercase, one lowercase, one special
              character and one number
            </mat-error>
            <mat-error
              *ngIf="
                this.loginForm.controls['password'].hasError('maxlength') &&
                !loginForm.controls['password'].hasError('pattern')
              "
              class="lh-1"
              >Password should contain less than 30 characters
            </mat-error>
          </mat-form-field>

          <div class="d-flex align-content-start flex-wrap">
            <div class="col-6"></div>
            <div class="col-6 d-flex justify-content-end">
              <a
                class="pointer m-0 my-2 active-link"
                (click)="openForgotPasswordDialog()"
              >
                Forgot password?
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <button
              mat-raised-button
              class="button btn-primary w-100 background-color lato-font-family white-color fw-500 fs-16 mb-4 px-5 py-1"
              type="submit"
              [disabled]="loginForm.invalid"
              [ngClass]="{ 'disabled-button': loginForm.invalid }"
            >
              Login
            </button>
          </div>
        </form>
        <app-google-sso-button></app-google-sso-button>
        <div class="d-flex mt-4 justify-content-center px-3">
          <p class="center">Don't have an account ?</p>
          <a class="pointer mx-2 active-link" [routerLink]="['/sign-up']"
            >Sign up</a
          >
        </div>
      </mat-card>
    </div>
  </div>
</div>
