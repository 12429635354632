import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
  timeLeft: number = 10 * 60 + 32; // Total time in seconds (3:32)
  displayTime: string = '';
  intervalId: any;

  ngOnInit() {
    this.updateDisplayTime();
    this.startTimer();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startTimer() {
    this.intervalId = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.updateDisplayTime();
      } else {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  updateDisplayTime() {
    const minutes = Math.floor(this.timeLeft / 60);
    const seconds = this.timeLeft % 60;
    this.displayTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  getProgress(): string {
    const radius = 15.9155; // Radius used in the circle path
    const circumference = 2 * Math.PI * radius; // Circumference of the circle

    const progress = (this.timeLeft / (10 * 60 + 32)) * circumference; // Calculate the stroke-dasharray as per the progress

    return `${progress.toFixed(2)} ${circumference.toFixed(2)}`; // Return the stroke-dasharray value as "progress circumference"
  }
}
