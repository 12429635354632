<div class="d-flex align-items-center py-3">
  <div>
    <mat-icon class="material-icons-outlined mat-icon-height me-3"
      >history</mat-icon
    >
  </div>
  <div *ngIf="search?.userName === 'null'" class="fw-400 fs-16">
    <div class="name-table-wrapper">
      <div class="name-table">
        <p class="m-0 ellipsis">{{ search?.fullName }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="search?.userName !== 'null'" class="d-flex align-items-center">
    <img
      class="profile-image-40 rounded-object profile-border {{
        search?.isVerified === 'brand' ? 'object-contain' : ''
      }}"
      [src]="
        search?.isVerified === 'user'
          ? search?.profile
            ? blobStorageLink + search?.profile
            : defaultImage
          : search?.isVerified === 'brand'
          ? search?.profile
            ? blobStorageLink + search?.profile
            : businessDefaultImage
          : null
      "
      alt="profile"
      mat-card-avatar
    />
    <div class="ps-2 name-table-wrapper">
      <div class="name-table">
        <div class="d-flex align-items-center">
          <p
            *ngIf="search?.isVerified === 'user'"
            class="fw-600 fs-16 m-0 ellipsis"
          >
            {{ search?.fullName }}
          </p>
          <p
            *ngIf="search?.isVerified === 'brand'"
            class="fw-600 fs-16 m-0 ellipsis"
          >
            {{ search?.userName }}
          </p>
          <span
            class="material-icons active-link fs-16"
            [ngClass]="search.subscription"
            *ngIf="search?.isUser === 'VERIFIED'"
          >
            verified
          </span>
        </div>
        <p
          *ngIf="search?.isVerified === 'user'"
          class="fw-400 fs-12 m-0 ellipsis scream-icon-color"
        >
          {{ search?.userName }}
        </p>
        <p
          *ngIf="search?.isVerified === 'brand'"
          class="fw-400 fs-12 m-0 ellipsis scream-icon-color"
        >
          {{ search?.fullName }}
        </p>
      </div>
    </div>
  </div>
</div>
