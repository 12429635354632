import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { DataTransferService } from './core/service/data-transfer/data-transfer.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { isPlatformServer } from '@angular/common';
import { HeaderService } from './core/service/header/header.service';
import { BottomNavBarService } from './core/service/bottomNavBar/bottom-nav-bar.service';
import { filter } from 'rxjs/operators';
import { ScreamService } from './core/service/screamService/scream.service';
import { CreateAScreamComponent } from './scream/create-a-scream/create-a-scream.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'scremer-fe';
  showHeader: boolean = true;
  showSideNav: boolean = true;
  routerLink: String[] = [
    '/',
    '/login',
    '/sign-up',
    '/wait-list',
    '/about-us',
    '/page-not-found',
    '/business/claim-business',
    '/business/sign-up',
    '/business',
    '/business/get-verified',
    '/business/login',
    '/business/verify-account',
    '/business/onboard',
    '/business/get-premium',
  ];
  showContent = true;
  previousUrl: any;
  currentUrl: any;
  dialogCheck: any;
  private _platformId: object;
  constructor(
    private readonly service: DataTransferService,
    public router: Router,
    private cdRef: ChangeDetectorRef,
    public cookieService: SsrCookieService,
    private headerService: HeaderService,
    private bottomService: BottomNavBarService,
    private screamService: ScreamService,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) platformId: object,
  ) {
    this._platformId = platformId;
    this.cookieService.deleteAll();
    if (this.isBusinessLoggedIn()) {
      service.setBusinessToken(localStorage.getItem('BusinessToken'));
    } else if (this.loggedIn()) {
      service.setLoginToken(localStorage.getItem('ScremerToken'));
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.service.updateShowSearchCard(false);
      }
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd | any) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.screamService.setPreviousUrl(this.previousUrl);
        this.handleQueryParam();
        this.hideSideNav();
      });

    if (isPlatformServer(this.platformId)) {
      this.showContent = false;
      setTimeout(() => {
        this.showContent = true;
        setTimeout(() => {
          if ((this.showContent = true)) {
            this.headerService.hide();
            this.bottomService.hide();
          }
        }, 1000);
      }, 0);
    } else {
      this.showContent = false;
      setTimeout(() => {
        this.showContent = false;
      }, 0);
    }
  }

  handleQueryParam() {
    if (this.loggedIn()) {
      this.route.queryParams.subscribe((params) => {
        if (params['scream_it']) {
          this.openScreamDialogs();
        }
      });
    }
  }
  openScreamDialogs() {
    if (!this.dialogCheck) {
      this.dialogCheck = this.dialog.open(CreateAScreamComponent, {
        panelClass: ['scream-dialog-box', 'border-radius'],
        disableClose: true,
      });

      this.dialogCheck?.afterClosed().subscribe(() => {
        this.dialogCheck = null;
      });
    }
  }

  get platformId(): Object {
    return this._platformId;
  }

  loggedIn(): boolean {
    return this.service.isLoggedin();
  }
  isBusinessLoggedIn(): boolean {
    return this.service.isBusinessLoggedin();
  }

  isDashboard(): boolean {
    if (this.router.url == '/business/dashboard') {
      return true;
    }
    return false;
  }
  isPaymentHistory(): boolean {
    if (this.router.url == '/business/payment-history') {
      return true;
    }
    return false;
  }
  routerOutletClassFromRouter() {
    if (this.isDashboard() || this.isPaymentHistory()) {
      return 'col-12 col-sm-10 col-md-9 col-lg-6 p-0 dashboard-fixed-width vertical-line disable-right-vertical-line ';
    } else if (
      (!this.routerLink.includes(this.router.url) &&
        !this.router.url.startsWith('/sign-up/')) ||
      (this.router.url.startsWith('/page-not-found') &&
        (this.loggedIn() || this.isBusinessLoggedIn()))
    ) {
      return 'col-12 col-sm-10 col-md-9 col-lg-6 p-0 fixed-width vertical-line ';
    } else {
      return 'container-fluid p-0';
    }
  }
  checkRouteBeforeLogin() {
    if (
      (!this.routerLink.includes(this.router.url) &&
        !this.router.url.startsWith('/sign-up/')) ||
      (this.router.url.startsWith('/page-not-found') &&
        (this.loggedIn() || this.isBusinessLoggedIn())) ||
      this.router.url.startsWith('/business/sign-up') ||
      this.router.url.startsWith('/business/get-verified') ||
      this.router.url.startsWith('/business/onboard') ||
      this.router.url.startsWith('/business/get-premium') ||
      this.router.url.startsWith('/business/events') ||
      this.router.url.startsWith('/business/login')
    ) {
      return 'd-flex justify-content-center min-vh-90 ';
    } else {
      return 'd-flex align-items-center justify-content-center min-vh-90';
    }
  }
  businessBottomNav() {
    if (
      this.router.url.startsWith('/business/get-verified') ||
      this.router.url.startsWith('/business/onboard') ||
      this.router.url.startsWith('/business/get-premium')
    ) {
      return 'd-none';
    }
  }

  hideSideNav() {
    const url: string = this.router.url || ' ';
    if (
      typeof url == 'string' &&
      (url.startsWith('/business/get-verified') ||
        url.startsWith('/business/onboard') ||
        url.startsWith('/business/get-premium'))
    ) {
      this.showSideNav = false;
      this.cdRef.detectChanges();
      return 'd-none';
    } else {
      this.showSideNav = true;
      return 'd-block';
    }
  }
}
