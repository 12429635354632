import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DataTransferService } from '../service/data-transfer/data-transfer.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private dataTransferService: DataTransferService
  ) {}

  canActivate(): boolean {
    const isBusinessLoggedin = this.dataTransferService.isBusinessLoggedin();

    if (isBusinessLoggedin) {
      this.router.navigate(['business/home']);

      return false;
    }

    return true;
  }
}
