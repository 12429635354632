import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { SnackbarService } from '../../core/service/snackBar/snackbar.service';

@Component({
  selector: 'app-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss'],
})
export class UploadDocComponent {
  @ViewChild('addDoc', { static: false }) addDoc!: ElementRef;
  @Output() newDocEvt = new EventEmitter<any>();

  constructor(private readonly snackbarService: SnackbarService) {}
  addedDoc!: File;
  addDocEvt(event: any) {
    if (
      event.target.files &&
      event.target.files.length == 1 &&
      event.target.files[0].size < 5000000
    ) {
      this.addedDoc = event;
      this.newDocEvt.emit(this.addedDoc);
    } else if (event.target.files[0].size >= 5000000) {
      this.snackbarService.openSnackBar(
        'Document size should be less than 5 MB',
        'error-snackbar'
      );
    }
    this.addDoc.nativeElement.value = '';
  }
}
