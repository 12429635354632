<div class="non-scrollable">
  <div class="pt-3 px-3 pb-2">
    <mat-icon class="material-icons pointer px-1" (click)="closeDialog()"
      >close</mat-icon
    >
    <p *ngIf="screamsUpsCount === 1" class="fs-16 fw-400">Scream up</p>
    <p *ngIf="screamsUpsCount > 1" class="fs-16 fw-400">Scream ups</p>
  </div>
  <div
    class="scrolling"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScroll()"
  >
    <div
      class="d-flex justify-content-between py-2 px-3"
      *ngFor="let screamUp of userAndBrandScreamups; let i = index"
    >
      <div
        *ngIf="screamUp?.brandDTO === null"
        class="d-flex pointer"
        (click)="navigateToUserProfile(screamUp.loginResponseDTO.userName)"
      >
        <img
          class="profile-image-40 rounded-object profile-color object-fill profile-border"
          [src]="
            screamUp.loginResponseDTO.profileImage !== null
              ? blobURL + screamUp.loginResponseDTO.profileImage
              : 'assets/images/no-profile.png'
          "
          mat-card-avatar
        />
        <div class="name-table-wrapper px-2">
          <div class="name-table">
            <p class="fw-600 fs-16 m-0 hoverable truncate-ellipsis">
              {{ screamUp?.loginResponseDTO?.fullName }}
            </p>
            <p class="fw-400 fs-12 m-0 scream-icon-color truncate-ellipsis">
              &#64;{{ screamUp?.loginResponseDTO?.userName }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="d-flex pointer"
        *ngIf="screamUp?.brandDTO !== null"
        (click)="navigateToBrandProfile(screamUp.brandDTO.brandName)"
      >
        <img
          class="profile-image-40 rounded-object profile-border"
          [src]="
            screamUp.brandDTO.logoImage
              ? blobURL + screamUp.brandDTO.logoImage
              : businessDefaultImage
          "
          mat-card-avatar
          alt="brand-profile"
        />
        <div class="name-table-wrapper px-2">
          <div class="name-table">
            <div class="d-flex">
              <p class="fw-600 fs-16 m-0 hoverable truncate-ellipsis">
                {{ screamUp.brandDTO.brandName }}
              </p>
              <span
                class="ms-1 d-flex align-self-center material-icons active-link fs-16"
                [ngClass]="
                  this.dataTransferService.setVerificationIconColor(
                    screamUp?.brandDTO
                  )
                "
                *ngIf="
                  screamUp?.brandDTO && isBrandVerified(screamUp?.brandDTO)
                "
              >
                verified
              </span>
            </div>
            <p class="fw-400 fs-12 m-0 scream-icon-color">
              &#64;{{ screamUp?.brandDTO?.categoryName }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="mt-1 ms-4 text-center"
        *ngIf="screamUp.loginResponseDTO !== null && !isBusinessLogin"
      >
        <div *ngIf="profile?.userName !== screamUp.loginResponseDTO.userName">
          <button
            mat-raised-button
            class="btn background-white-color"
            type="button"
            *ngIf="!screamUp.loginResponseDTO?.following"
            (click)="follow(screamUp.loginResponseDTO)"
          >
            <div class="d-flex">
              <div
                class="fw-600 lato-font-family follow-padding py-2 blue-color"
              >
                Follow
              </div>
            </div>
          </button>
          <button
            mat-raised-button
            class="btn background-color white-color w-100"
            type="button"
            *ngIf="screamUp.loginResponseDTO.following"
            (click)="follow(screamUp.loginResponseDTO)"
          >
            <div class="d-flex">
              <div class="fw-600 lato-font-family follow-padding py-2">
                Following
              </div>
            </div>
          </button>
        </div>
      </div>
      <div
        class="mt-1 ms-4 text-center"
        *ngIf="screamUp.brandDTO !== null && !isBusinessLogin"
      >
        <button
          mat-raised-button
          class="btn background-white-color"
          type="button"
          *ngIf="!screamUp.brandDTO.following"
          (click)="followBrand(screamUp.brandDTO)"
        >
          <div class="d-flex">
            <div class="fw-600 lato-font-family follow-padding py-2 blue-color">
              Follow
            </div>
          </div>
        </button>
        <button
          mat-raised-button
          class="btn background-color white-color w-100"
          type="button"
          *ngIf="screamUp.brandDTO.following"
          (click)="followBrand(screamUp.brandDTO)"
        >
          <div class="d-flex">
            <div class="fw-600 lato-font-family follow-padding py-2">
              Following
            </div>
          </div>
        </button>
      </div>
    </div>
    <app-follower-shimmer *ngIf="screamUpShimmer"></app-follower-shimmer>
    <div class="box"></div>
  </div>
</div>
