import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class HospitalPatientQueueService {
  private api = AppConfig.getQUEUEAPIURI() + 'hospital/patient/queue';

  constructor(private http: HttpClient) {}

  fetchAppointmentStatus(data: String): Observable<any> {
    return this.http.get(this.api + `/fetch/${data}`, {
      observe: 'response',
    });
  }

  quitQueue(data: String): Observable<any> {
    return this.http.delete(this.api + `/leave/${data}`, {
      observe: 'response',
    });
  }

  joinQueue(queueData: any): Observable<any> {
    return this.http.post(this.api + `/join`, queueData, {
      observe: 'response',
    });
  }
}
