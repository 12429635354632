import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../../../core/service/loader/loader.service';
import { ScreamService } from '../../../core/service/screamService/scream.service';
import { SnackbarService } from '../../../core/service/snackBar/snackbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-contact',
  templateUrl: './view-contact.component.html',
  styleUrls: ['./view-contact.component.scss'],
})
export class ViewContactComponent {
  scream!: any;
  contactDetails!: any;
  isSubscribed: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private screamService: ScreamService,
    private loaderService: LoaderService,
    private snackbarService: SnackbarService,
    private matDialogRef: MatDialog,
    public router: Router
  ) {
    this.scream = data;
    this.fetchContactDetails();
  }

  fetchContactDetails() {
    this.loaderService.show();
    this.screamService.fetchContact(this.scream).subscribe({
      next: (response: any) => {
        {
          if (response.body.responseCode === 200) {
            this.contactDetails = response.body.responseBody;
          }
          this.loaderService.hide();
        }
      },
      error: (error: any) => {
        if (error.error.responseCode === 400) {
          this.snackbarService.openSnackBar(
            error.error.responseMessage,
            'error-snackbar'
          );
        }
        if (error.error.responseCode === 403) {
          this.isSubscribed = false;
        }
        this.loaderService.hide();
      },
    });
  }

  navigateToPremium() {
    this.closeDialog();
    this.router.navigate(['/business/get-premium']);
  }

  closeDialog() {
    this.matDialogRef.closeAll();
  }
}
