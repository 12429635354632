import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SliderService {
  getSliderValue(event: any) {
    return Math.floor(event / 20);
  }
}
