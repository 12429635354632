import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TokenService } from '../../core/service/tokenService/token.service';
import { UserService } from './../../core/service/userService/user.service';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { LoaderService } from './../../core/service/loader/loader.service';
import { BrandService } from './../../core/service/brandService/brand.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  contentVisible!: boolean;
  errorMessage: any;
  currentpath: any;
  constructor(
    public dialogRef: MatDialogRef<LogoutComponent>,
    public router: Router,
    public tokenService: TokenService,
    public cookieService: SsrCookieService,
    private userService: UserService,
    private brandService: BrandService,
    public dataTransferService: DataTransferService,
    public loaderService: LoaderService
  ) {}
  ngOnInit(): void {
    this.currentpath = this.router.url;
    this.contentVisible = this.tokenService.getContent();
    this.tokenService.getContent();
    this.userMatchingSessionId = this.dataTransferService.getUserSessionId();
    this.businessMatchingSessionId =
      this.dataTransferService.getBusinessSessionId();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  userSessionId: any;
  businessSessionId: any;
  initial_login_time: any;
  initial_business_login_time: any;
  businessMatchingSessionId: any;
  userMatchingSessionId: any;

  commonLogout(
    matchingSessionId: any,
    service: any,
    tokenKey: string,
    navigateRoute: string
  ) {
    this.loaderService.show();

    if (matchingSessionId) {
      service.logoutSession(matchingSessionId).subscribe({
        next: (response: any) => {
          if (response.body.responseCode == 200) {
            this.loaderService.hide();
            localStorage.clear();
            this.cookieService.delete(tokenKey);
            this.dialogRef.close(LogoutComponent);
            this.router.navigate([navigateRoute]);
          }
        },
        error: (response: any) => {
          this.errorMessage = response.error?.responseMessage;
        },
      });
    } else {
      this.loaderService.hide();
      localStorage.clear();
      this.cookieService.delete(tokenKey);
      this.dialogRef.close(LogoutComponent);
      this.router.navigate([navigateRoute]);
    }
  }

  userLogout() {
    this.commonLogout(
      this.userMatchingSessionId,
      this.userService,
      'ScremerToken',
      '/'
    );
  }

  businessLogout() {
    this.commonLogout(
      this.businessMatchingSessionId,
      this.brandService,
      'BusinessToken',
      '/business'
    );
  }

  businessLoggedIn(): boolean {
    return this.dataTransferService.isBusinessLoggedin();
  }

  unFollow() {
    this.dialogRef.close('unfollow');
  }
}
