<div
  class="landing-bg-color d-flex flex-column justify-content-center align-items-center"
>
  <div
    class="w-100 d-flex justify-content-between icons-flex max-width pt-5 px-5"
  >
    <div *ngFor="let pageLink of pageLinks">
      <div
        class="pb-3 fw-400 fs-16"
        [ngClass]="{ 'active-link': pageLink.routerLink === activeRoute }"
      >
        <a [routerLink]="pageLink.routerLink" class="ms-1 pointer">
          {{ pageLink.content }}
        </a>
      </div>
    </div>
  </div>
  <div
    class="w-100 d-flex justify-content-between icons-flex max-width p-5 pb-6"
  >
    <div class="pb-3">
      <p class="fw-400 fs-16">
        &copy; 2023<a class="ms-1" href="https://www.betteranalytics.in/"
          >Better Analytics pvt ltd.</a
        >
        All rights reserved.
      </p>
    </div>
    <div class="d-flex flex-row">
      <div *ngFor="let platform of platforms" class="fs-24 flex-nowrap">
        <a href="{{ platform.platformUrl }}" target="_blank">
          <mat-icon
            class="fa {{ platform.platformName }} me-2 active-link pointer"
          ></mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
