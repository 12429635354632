<div class="d-flex">
  <ng-container>
    <mat-icon
      class="material-symbols-outlined pointer"
      (click)="goBackToPreviousPage()"
      >arrow_back</mat-icon
    >
  </ng-container>
  <div *ngIf="getHeaderText()">
    <h4 class="h6 fw-bold ps-3 m-0">{{ getHeaderText() }}</h4>
  </div>
</div>
