import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class BusinessTimingsService {
  private api = AppConfig.getQUEUEAPIURI() + 'business-timings';

  constructor(private http: HttpClient) {}

  getdaySlotsAppointments(businessName: string, date: string): Observable<any> {
    const params = { date: date };
    return this.http.get(this.api + '/shift-timings/' + `${businessName}`, {
      params,
      observe: 'response',
    });
  }

  getAppointments(mobileNumber: string, date: string): Observable<any> {
    const params = { mobileNumber: mobileNumber, date: date };
    return this.http.get(this.api + `/appointments`, {
      params,
      observe: 'response',
    });
  }

  bookAppointment(data: any): Observable<any> {
    return this.http.post(this.api + `/book-appointment`, data, {
      observe: 'response',
    });
  }
}
