<div class="d-flex p-3">
  <div class="gr-content-container">
    <div class="d-flex justify-content-end">
      <mat-icon
        (click)="closeDialog()"
        class="material-symbols-outlined pointer black-color"
      >
        close
      </mat-icon>
    </div>
    <div class="d-flex justify-content-center mb-2">
      <img src="assets/images/google.png" alt="google-logo" />
    </div>
    <p class="fs-18 fw-600 text-center mb-2">
      Your Scream has been copied! Help {{ data.brandName }} with a Google
      review
    </p>

    <div class="d-flex justify-content-center mt-4 mb-3">
      <button
        mat-raised-button
        class="rounded-pill background-color white-color lato-font-family fw-600 fs-18 ms-2 btn-dimension"
        (click)="navigateToGoogleReview()"
      >
        Leave a review
      </button>
    </div>
  </div>
</div>
