<div class="container p-4 border">
  <div class="mb-2 p-0">
    <h3 class="d-flex align-items-center fw-500 fs-23" *ngIf="!contentVisible">
      Are you sure you want to logout?
    </h3>
    <h3 class="d-flex align-items-center fw-500 fs-23" *ngIf="contentVisible">
      Are you sure you want to unfollow?
    </h3>
  </div>
  <div class="d-flex justify-content-end py-1">
    <button
      mat-raised-button
      class="rounded-pill active-link br-color lato-font-family fw-600 fs-14"
      (click)="closeDialog()"
    >
      Cancel
    </button>
    <button
      *ngIf="!contentVisible && !businessLoggedIn()"
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2"
      type="submit"
      #btnFocus="matButton"
      [autofocus]="btnFocus.focus()"
      (click)="userLogout()"
    >
      Logout
    </button>
    <button
      *ngIf="!contentVisible && businessLoggedIn()"
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2"
      type="submit"
      #btnFocus="matButton"
      [autofocus]="btnFocus.focus()"
      (click)="businessLogout()"
    >
      Logout
    </button>
    <button
      *ngIf="contentVisible"
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2"
      type="submit"
      #btnFocus="matButton"
      [autofocus]="btnFocus.focus()"
      (click)="unFollow()"
    >
      Confirm
    </button>
  </div>
</div>
