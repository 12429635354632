import { SnackbarService } from '../../core/service/snackBar/snackbar.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TokenService } from '../../core/service/tokenService/token.service';
import { DataTransferService } from '../../core/service/data-transfer/data-transfer.service';
import { LoaderService } from '../../core/service/loader/loader.service';
import { HeaderService } from '../../core/service/header/header.service';
import { BottomNavBarService } from '../../core/service/bottomNavBar/bottom-nav-bar.service';
import { UserService } from '../../core/service/userService/user.service';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { GoogleAuthProvider } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SignUpComponent } from 'src/app/user/sign-up/sign-up.component';

@Component({
  selector: 'app-google-sso-button',
  templateUrl: './google-sso-button.component.html',
  styleUrls: ['./google-sso-button.component.scss'],
})
export class GoogleSsoButtonComponent implements OnInit {
  @ViewChild(SignUpComponent) validate!: SignUpComponent;
  fullName: any;
  password: any;
  email: any;
  platformId: Object;
  referralCode!: string | null;
  constructor(
    private userService: UserService,
    public router: Router,
    public readonly bottomService: BottomNavBarService,
    public readonly headerService: HeaderService,
    public loaderService: LoaderService,
    public service: DataTransferService,
    public tokenService: TokenService,
    public route: ActivatedRoute,
    public fireauth: AngularFireAuth,
    public dataTransferService: DataTransferService,
    private deviceDetectorService: DeviceDetectorService,
    private snackBarService: SnackbarService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    this.route.paramMap?.subscribe((params) => {
      this.referralCode = params.get('referralCode') ?? null;
      if (this.referralCode !== null) {
        this.validate?.validateReferralCode();
      }
    });
  }

  signUpWithGoogle() {
    const google = this.fireauth.signInWithPopup(new GoogleAuthProvider());
    google.then((res: any) => {
      this.fullName = res.additionalUserInfo?.profile.name?.substring(0, 30);
      (this.password = res.user?.uid + '@'),
        (this.email = res.additionalUserInfo?.profile?.email);
      this.signUpOrLogin();
    });
    return google;
  }

  signUpOrLogin() {
    this.loaderService.show();
    this.userService.registerUser(this.createUserBody()).subscribe({
      next: (response: any) => {
        this.loaderService.hide();
        if (response.body.responseCode == 201) {
          let token = response.body.token;
          this.tokenService.setToken(token);
          this.tokenService.setContent(false);
          this.tokenService.setPurpose('sign-up');
          this.settingLoginToken(response);
        } else if (response.body.responseCode == 200) {
          this.settingLoginToken(response);
        }
      },
      error: () => {
        this.loaderService.hide();
        this.snackBarService.openSnackBar(
          'Please try again !!',
          'error-snackbar'
        );
      },
    });
  }
  settingLoginToken(response: any) {
    const userDetails = response.body.responseBody;
    this.headerService.updateImage(userDetails?.profileImage);
    this.dataTransferService.setLoginToken(response.body.token);
    this.dataTransferService.setUserDetails(JSON.stringify(userDetails) || '');
    const redirectTo = localStorage.getItem('qrCodeUrl') || '/home';
    this.router.navigateByUrl(redirectTo);
    localStorage.removeItem('qrCodeUrl');
  }

  createUserBody() {
    return {
      signUpType: 'GOOGLE',
      userDetails: {
        fullName: this.fullName,
      },
      userAccount: {
        email: this.email,
        password: this.password,
        googlePassword: this.password,
      },
      referralCode: null || this.referralCode,
      logDTO: {
        operatingSystem: this.deviceDetectorService.getDeviceInfo().os,
        deviceType: this.deviceDetectorService.getDeviceInfo().deviceType,
      },
    };
  }
}
