import { Component, Input } from '@angular/core';
import { DataTransferService } from '../../../core/service/data-transfer/data-transfer.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent {
  @Input() result: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  constructor(private readonly dataTransferService: DataTransferService) {}
}
