import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchBarService {
  constructor() {}
  private search = new Subject<any>();
  search$ = this.search.asObservable();

  changeSearch(searchTerm: string, event: any) {
    let obj = { message: searchTerm, event: event };
    this.search.next(obj);
  }
  removeSearch() {
    let obj = { message: 'remove' };
    this.search.next(obj);
  }

  private userBlockedSource = new Subject<string>();
  userBlocked$ = this.userBlockedSource.asObservable();

  blockUser(userName: string) {
    this.userBlockedSource.next(userName);
  }
}
