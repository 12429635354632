import { OtpService } from './../../core/service/otpService/otp.service';
import {
  Component,
  EventEmitter,
  Output,
  ViewChildren,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-one-time-password',
  templateUrl: './one-time-password.component.html',
  styleUrls: ['./one-time-password.component.scss'],
})
export class OneTimePasswordComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  title = 'otp';
  form!: FormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6'];
  @ViewChildren('formRow') rows: any;
  formRow: any;

  constructor(private otpService: OtpService) {}

  ngOnInit(): void {
    this.form = this.toFormGroup(this.formInput);
  }

  toFormGroup(elements: any[]) {
    const group: any = {};
    elements.forEach((key) => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event: KeyboardEvent, index: number) {
    let pos = index;
    if (event.key === 'Backspace' || event.key === 'ArrowLeft') {
      pos = index - 1;
      if (pos > -1 && pos < this.formInput.length) {
        this.rows._results[pos].nativeElement.focus();
      }
    } else if (event.key === 'Tab') {
      if (event.key === 'Tab' && event.shiftKey) {
        pos = index - 1;
        if (pos < 0) {
          pos = 0;
        }
      } else {
        pos = index + 1;
        return pos;
      }
      if (pos > -1 && pos < this.formInput.length) {
        this.rows._results[pos].nativeElement.focus();
      }
    } else {
      pos = index + 1;
      if (pos > -1 && pos < this.formInput.length) {
        this.rows._results[pos].nativeElement.focus();
      }
    }
    return pos;
  }

  oneTimePassword() {
    let result = Object.values(this.form.value).join('');
    if (result.length === 6) {
      return parseInt(result);
    } else {
      return null;
    }
  }
}
