<div class="m-5">
  <div
    class="container d-flex justify-content-around align-items-center shadow p-3"
  >
    <div class="text-center">
      <div class="text-muted fw-700 fs-14 mb-3">Place in line</div>
      <div class="fw-700 fs-24">{{ placeInLine }}</div>
    </div>
    <div class="vr w-1"></div>
    <div class="text-center">
      <div class="text-muted fw-700 fs-14 mb-3">Estimated wait</div>
      <div class="fw-700 fs-24">{{ estimatedTime }}</div>
    </div>
  </div>

  <div class="text-center mt-4">
    <!-- <p class="mb-2 text-color fw-700 fs-14">
      {{ serviceNumber }}
    </p> -->
    <p class="mb-3 fw-700 fs-14">Your ticket</p>
    <p class="mb-2 fw-700 fs-32 active-link">#{{ ticketNumber }}</p>
  </div>
  <div class="d-flex flex-column align-items-center mt-3">
    <div class="list-group py-2 w-100">
      <div
        class="border d-flex align-items-center p-2 my-3"
        (click)="quitQueue()"
      >
        <span class="material-symbols-outlined me-2 ms-3 fs-20 icon-color">
          move_item
        </span>

        <span class="flex-grow-1 fw-400 fs-16">Leave Waitlist</span>
      </div>
    </div>

    <!-- <div class="list-group w-100">
      <div class="border d-flex align-items-center p-2 my-1">
        <span class="material-symbols-outlined me-2 ms-3 fs-20 icon-color">
          share
        </span>

        <span class="flex-grow-1 fw-400 fs-16"> Share Status</span>
      </div>
    </div> -->
  </div>
</div>
