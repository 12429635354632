import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  formInput: any;
  private api = AppConfig.getAPIURI() + 'otp';
  constructor(private http: HttpClient) {}

  setMessage(data: any) {
    this.formInput = data;
  }
  getMessage() {
    return this.formInput;
  }
  sendOTPToMobile(mobileNumber: string): Observable<any> {
    const params = new HttpParams().set('mobileNumber', mobileNumber);
    return this.http.post(
      `${this.api}/send-sms`,
      {},
      {
        params,
      }
    );
  }
  sendOtp(otp: any): Observable<any> {
    return this.http.post(AppConfig.getAPIURI() + 'otp/checkotp', otp, {
      observe: 'response',
    });
  }
  verifySMSOtp(user: any, otp: any): Observable<any> {
    let otpDTO = {
      code: otp,
      userDTO: user,
    };
    return this.http.post(`${this.api}/verify-sms`, otpDTO, {
      observe: 'response',
    });
  }

  resendOtpWithAuthorization(
    otpDTO: any,
    authorizationHeader: string
  ): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', authorizationHeader);
    return this.http.post(AppConfig.getAPIURI() + 'otp/resend', otpDTO, {
      headers,
    });
  }
  resendOtpWithoutAuthorization(otpDTO: any): Observable<any> {
    return this.http.post(AppConfig.getAPIURI() + 'otp/resend', otpDTO);
  }
  resendOtp(otp: any, authorizationHeader?: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (authorizationHeader) {
      headers = headers.append('Authorization', authorizationHeader);
    }
    return this.http.post(AppConfig.getAPIURI() + 'otp/resend', otp, {
      observe: 'response',
      headers: headers,
    });
  }
}
