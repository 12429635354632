import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/service/header/header.service';
import { HospitalPatientQueueService } from 'src/app/core/service/hospitalPatientQueue/hospital-patient-queue.service';
import { HospitalService } from 'src/app/core/service/hospitalService/hospital.service';
import { LoaderService } from 'src/app/core/service/loader/loader.service';
import { QueueWebSocketService } from 'src/app/core/service/websocketService/que.websocket.service';

@Component({
  selector: 'app-waiting',
  standalone: false,
  templateUrl: './waiting.component.html',
  styleUrl: './waiting.component.scss',
})
export class WaitingComponent implements OnInit {
  currentState: 'waitlist' | 'next' | 'turn' = 'waitlist';
  locationName: string = 'Fernandez';
  //placeInLine = 4;
  //estimatedTime = '30 min';
  serviceNumber = 'Service1';
  //ticketNumber = 12;
  displayTime: string = '';

  placeInLine: any;
  estimatedTime: any;
  ticketNumber: any;
  status: any;
  errorMessage: any;

  constructor(
    public readonly headerService: HeaderService,
    private router: Router,
    private hospitalService: HospitalService,
    private loaderService: LoaderService,
    private queueWebSocketService: QueueWebSocketService,
    private hosptialPatientQueue: HospitalPatientQueueService,
  ) {}
  ngOnInit(): void {
    this.queueWebSocketService.subscribeQueueStatus((message) => {
      console.log('Received message:', message.body);
      this.currentState = 'turn';
    });
    this.headerService.hide();
    this.fetchStatus();
  }

  fetchStatus() {
    this.loaderService.show();
    const queueid = localStorage.getItem('queueId');
    const quid = queueid ? JSON.parse(queueid) : null;
    if (this.currentState != 'turn') {
      this.hosptialPatientQueue.fetchAppointmentStatus(quid).subscribe({
        next: (response) => {
          if (response && response.body.responseCode === 200) {
            this.loaderService.hide();
            this.placeInLine = response.body.responseBody.queuePosition;
            this.estimatedTime = response.body.responseBody.estimatedTime;
            this.ticketNumber = response.body.responseBody.tokenNumber;
            this.status = response.body.responseBody.queueStatus;
            console.log(response);
            if (this.estimatedTime == 0) {
              this.estimatedTime = 5;
            }
            if (this.placeInLine <= 1) {
              this.currentState = 'next';
            }

            if (this.status == 'COMPLETED') {
              this.router.navigateByUrl('queue/thanks');
            }
            return true;
          }
        },
        error: (error) => {
          this.loaderService.hide();
          this.errorMessage = error.message || 'An unknown error occurred';
        },
      });
    }
  }
}
