import { Component, OnInit } from '@angular/core';
import { GetAppService } from '../core/service/getAppService/get-app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-download-scremer',
  templateUrl: './download-scremer.component.html',
  styleUrls: ['./download-scremer.component.scss'],
})
export class DownloadScremerComponent implements OnInit {
  constructor(private getAppService: GetAppService, private router: Router) {}
  private testing: boolean = false;
  public referralCode: any;
  ngOnInit(): void {
    this.referralCode = this.router.url?.split('/').pop();
    if (!this.testing) {
      this.getAppService.downloadApp(this.referralCode);
    }
  }

  enableTesting() {
    this.testing = true;
  }

  disableTesting() {
    this.testing = false;
  }
}
