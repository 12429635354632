import { SnackbarService } from './../../core/service/snackBar/snackbar.service';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { LoaderService } from './../../core/service/loader/loader.service';
import { TokenService } from './../../core/service/tokenService/token.service';
import { OtpService } from './../../core/service/otpService/otp.service';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OneTimePasswordComponent } from './../../shared/one-time-password/one-time-password.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { OtpTimerService } from './../../core/service/otpTimer/otp-timer.service';
import { BrandService } from './../../core/service/brandService/brand.service';
import { HeaderService } from './../../core/service/header/header.service';
import { UserService } from '../../core/service/userService/user.service';
import { SubscriptionService } from '../../core/service/subscriptionService/subscriptionService';
import { Location } from '@angular/common';

@Component({
  selector: 'app-otp-transfer',
  templateUrl: './otp-transfer.component.html',
  styleUrls: ['./otp-transfer.component.scss'],
})
export class OtpTransferComponent implements OnInit, OnDestroy {
  isContentVisible!: boolean;
  resendOtpResponseMessage: any;
  @ViewChild('onetimepassword')
  onetimepasswordcomponentstub!: OneTimePasswordComponent;
  currentState: boolean = true;
  errorMessage!: string;
  currentPath: any;
  localTime: any;
  userDetails: any;
  verifyOtpResponseMessage: any;
  subscriptionId: any;
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private readonly userService: UserService,
    public router: Router,
    public otpService: OtpService,
    private tokenService: TokenService,
    public loaderService: LoaderService,
    public otpTimerService: OtpTimerService,
    private readonly brandService: BrandService,
    private dataTransferService: DataTransferService,
    private snackBarService: SnackbarService,
    private headerService: HeaderService,
    private subscriptionService: SubscriptionService,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public tokendata: any,
    public dialogRefs: MatDialogRef<OtpTransferComponent>,
    @Inject(MAT_DIALOG_DATA) public updatedUserDetails: any,
  ) {}
  ngOnInit(): void {
    this.isContentVisible = this.tokenService.getContent();
    this.currentPath = this.router.url;
    this.subscriptionId = this.subscriptionService.getSubscriptionId();
  }

  ngOnDestroy(): void {
    this.userDetails = null;
    localStorage.removeItem('userDetails');
  }

  closeDialog() {
    this.dialogRef.close(LoginComponent);
  }

  sendSMS() {
    if (this.onetimepasswordcomponentstub.oneTimePassword) {
      this.loaderService.show();
      this.otpService
        .verifySMSOtp(
          this.updatedUserDetails.updateUserBody,
          this.onetimepasswordcomponentstub.oneTimePassword(),
        )
        .subscribe({
          next: (response) => {
            if (response.body.responseCode == 200) {
              this.loaderService.hide();
              JSON.parse(this.dataTransferService.getUserDetails() || '');
              this.updateItem('completeProfile', true);
              this.dialogRef.close(OtpTransferComponent);
              this.tokenService.updateFormValidity(false);
              this.userService.setEditProfileImage(
                this.updatedUserDetails?.updateUserBody?.userDetails
                  ?.profileImage,
              );
              let userDetails = localStorage.getItem('UserDetails')
                ? JSON.parse(localStorage.getItem('UserDetails') as string)
                : {};
              this.headerService.updatefullName(
                this.updatedUserDetails?.updateUserBody?.userDetails.fullName,
              );
              userDetails.fullName =
                this.updatedUserDetails?.updateUserBody?.userDetails.fullName;

              userDetails.userName =
                this.updatedUserDetails?.updateUserBody?.userAccount.userName;
              this.dataTransferService.setUserDetails(
                JSON.stringify(userDetails) || '',
              );

              this.snackBarService.openSnackBar(
                response?.body?.responseMessage,
                'success-snackbar',
              );
            } else {
              this.loaderService.hide();
              this.errorMessage = response?.body?.responseMessage;
            }
          },
          error: (response: any) => {
            this.loaderService.hide();
            this.verifyOtpResponseMessage = response?.error?.responseMessage;
          },
        });
    }
  }
  updateItem(key: string, value: any) {
    const userDetails = localStorage.getItem('UserDetails');
    if (userDetails) {
      const currentValue = JSON.parse(userDetails || '');
      if (currentValue !== null && currentValue.hasOwnProperty(key)) {
        currentValue[key] = value;
        localStorage.setItem('UserDetails', JSON.stringify(currentValue));
      }
    }
  }

  next() {
    this.loaderService.show();
    const useremailString = localStorage.getItem('useremail') || ' ';
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
    const matches = useremailString.match(emailRegex);
    const userEmail = matches ? matches[0] : '';
    if (useremailString) {
      const otpDTO = {
        code: this.onetimepasswordcomponentstub.oneTimePassword(),
        email: userEmail,
      };
      this.otpService.sendOtp(otpDTO).subscribe({
        next: (response: { body: { responseCode: number } }) => {
          if (response.body.responseCode == 200) {
            this.loaderService.hide();
            this.currentState = false;
          }
          this.otpTimerService.resetTimer();
          this.otpTimerService.startTimer();
        },
        error: (data) => {
          this.loaderService.hide();
          this.errorMessage = data.error?.responseMessage;
        },
      });
    }
  }

  resendSMSOtp() {
    this.loaderService.show();
    const updateUserBody = this.updatedUserDetails?.updateUserBody;
    this.otpService
      .sendOTPToMobile(updateUserBody?.userAccount?.mobileNumber)
      .subscribe({
        next: (response: any) => {
          if (response.responseCode == 201) {
            this.loaderService.hide();
            this.resendOtpResponseMessage = response.responseMessage;
          }
          this.otpTimerService.resetTimer();
          this.otpTimerService.startTimer();
        },
        error: (response) => {
          this.loaderService.hide();
          this.resendOtpResponseMessage = response.error.responseMessage;
        },
      });
  }
  resend() {
    this.loaderService.show();
    if (this.tokenService.getPurpose() == 'sign-up') {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails') || '');
      const userDTO = {
        referralCode: null,
        userAccount: {
          userName: this.userDetails.userAccount.userName,
          email: this.userDetails.userAccount.email,
          password: this.userDetails.userAccount.password,
        },
        userDetails: {
          fullName: this.userDetails.userDetails.fullName,
        },
        logDTO: {
          deviceType: 'Mobile',
          operatingSystem: 'Android',
        },
        signUpType: 'SCREMER',
      };

      const otpDTO = {
        purpose: 'sign-up',
        uuid: null,
        email: this.userDetails.userAccount.email,
        fullName: this.userDetails.userDetails.fullName,
        logDTO: this.dataTransferService.logDtoBody().logDTO,
        userDTO: userDTO,
      };
      this.otpService.resendOtpWithoutAuthorization(otpDTO).subscribe({
        next: (res: any) => {
          if (res.responseCode == 200) {
            this.loaderService.hide();
            this.resendOtpResponseMessage = res.responseMessage;
          }
          this.otpTimerService.resetTimer();
          this.otpTimerService.startTimer();
        },
        error: (res) => {
          this.loaderService.hide();
          this.resendOtpResponseMessage = res.error.responseMessage;
        },
      });
    }
    if (this.tokenService.getPurpose() == 'forgot-password') {
      this.loaderService.show();
      const resendotp = {
        purpose: this.tokenService.getPurpose(),
      };
      const authorizationHeader = this.tokenService.getToken();
      this.otpService
        .resendOtpWithAuthorization(resendotp, authorizationHeader)
        .subscribe({
          next: (res: any) => {
            if (res.responseCode == 200) {
              this.loaderService.hide();
              this.resendOtpResponseMessage = res.responseMessage;
            }
            this.otpTimerService.resetTimer();
            this.otpTimerService.startTimer();
          },
          error: (res) => {
            this.loaderService.hide();
            this.resendOtpResponseMessage = res.error.responseMessage;
          },
        });
    }
  }

  isResendOTPVisible() {
    return this.otpTimerService.isResendOTPVisible();
  }

  submitForgotPasswordOtp() {
    if (this.onetimepasswordcomponentstub.oneTimePassword) {
      const otp = this.onetimepasswordcomponentstub.oneTimePassword();
      const token = this.tokenService.getToken();
      const logDtoBody = this.dataTransferService.logDtoBody();
      const purpose = this.tokenService.getPurpose();

      this.loaderService.show();

      let request;
      if (purpose === 'forgot-password') {
        request = this.brandService.sendBusinessOtp(otp, token, logDtoBody);
      } else if (purpose === 'cancel-plan') {
        request = this.brandService.sendBusinessOtpWithoutHeader(
          otp,
          logDtoBody,
        );
      }
      if (request) {
        request.subscribe({
          next: (response) => {
            if (response.body.responseCode === 200) {
              this.loaderService.hide();
              if (purpose === 'forgot-password') {
                this.currentState = false;
              } else {
                this.currentState = true;
                this.dialogRef.close(OtpTransferComponent);
                this.cancelSubscription();
              }
            }
          },
          error: (data) => {
            this.loaderService.hide();
            this.errorMessage = data.error.responseMessage;
          },
        });
      }
    }
  }
  cancelSubscription() {
    this.loaderService.show();
    this.subscriptionService.cancelSubscription(this.subscriptionId).subscribe({
      next: (res: any) => {
        if (res.body.responseCode === 200) {
          this.snackBarService.openSnackBar(
            'Subscription cancelled successfully!',
            'success-snackbar',
          );
          this.router.navigate(['business/manage'], { replaceUrl: true });
          this.location.back();
          this.loaderService.hide();
        }
      },
      error: (error) => {
        this.router.navigate(['business/manage'], { replaceUrl: true });
        this.location.back();
        this.snackBarService.openSnackBar(
          error.error.responseMessage,
          'error-snackbar',
        );
        this.loaderService.hide();
      },
    });
  }
  resendBusinessForgotPasswordOtp() {
    if (
      this.tokenService.getPurpose() === 'forgot-password' ||
      this.tokenService.getPurpose() === 'cancel-plan'
    ) {
      this.loaderService.show();
      const purpose = this.tokenService.getPurpose();
      const token = this.tokenService.getToken();
      const method =
        purpose === 'forgot-password' ? 'resendOtp' : 'resendOtpWithoutHeader';

      this.brandService[method](token, purpose).subscribe({
        next: (res: any) => {
          if (res.body.responseCode === 200) {
            this.loaderService.hide();
            this.resendOtpResponseMessage = res.body.responseMessage;
          }
        },
        error: (res) => {
          this.loaderService.hide();
          this.resendOtpResponseMessage = res.error.responseMessage;
        },
      });
      this.otpTimerService.resetTimer();
      this.otpTimerService.startTimer();
    }
  }

  isBusinessResendOTPVisible() {
    return this.otpTimerService.isBusinessResendOTPVisible();
  }

  verifyBusinessAccount() {
    if (this.onetimepasswordcomponentstub.oneTimePassword) {
      this.loaderService.show();
      this.brandService
        .sendBusinessOtp(
          this.onetimepasswordcomponentstub.oneTimePassword(),
          this.tokenService.getToken(),
          this.dataTransferService.logDtoBody(),
        )
        .subscribe({
          next: (response) => {
            if (response.body.responseCode == 200) {
              this.loaderService.hide();
              this.dialogRef.close(OtpTransferComponent);
              this.dataTransferService.setBusinessToken(
                this.tokenService.getToken(),
              );
              this.dataTransferService.setBrandDetails(
                JSON.stringify(response.body.responseBody),
              );
              this.headerService.updateImage('');
              this.router.navigate(['/business/home']);
            }
          },
          error: (response) => {
            this.loaderService.hide();
            this.errorMessage = response.error.responseMessage;
          },
        });
    }
  }
  createDTO(
    userDetails: {
      referralCode: string;
      userAccount: { userName: any; email: any; password: any };
      userDetails: { fullName: any };
      fullName: any;
    },
    onetimepasswordcomponentstub: OneTimePasswordComponent,
    dataTransferService: {
      logDTO?: { operatingSystem: string; deviceType: string };
      logDtoBody?: any;
    },
  ) {
    const userDTO = {
      referralCode: userDetails.referralCode,
      userAccount: {
        userName: userDetails.userAccount.userName,
        email: userDetails.userAccount.email,
        password: userDetails.userAccount.password,
      },
      userDetails: {
        fullName: userDetails.userDetails.fullName,
      },
      signUpType: 'SCREMER',
    };
    return {
      code: onetimepasswordcomponentstub.oneTimePassword(),
      uuid: null,
      email: userDetails.userAccount.email,
      fullName: userDetails.fullName,
      logDTO: this.dataTransferService.logDtoBody().logDTO,
      userDTO: userDTO,
    };
  }

  verifyUserAccount() {
    if (this.onetimepasswordcomponentstub.oneTimePassword()) {
      this.loaderService.show();
      const userDetailsString = localStorage.getItem('userDetails');

      if (userDetailsString) {
        this.userDetails = JSON.parse(userDetailsString);
        const dataTransferService = this.dataTransferService.logDtoBody();
        const otpDTO = this.createDTO(
          this.userDetails,
          this.onetimepasswordcomponentstub,
          dataTransferService,
        );
        this.otpService.sendOtp(otpDTO).subscribe({
          next: (response) => {
            if (response.body.responseCode == 200) {
              this.loaderService.hide();
              this.dialogRef.close(OtpTransferComponent);
              this.dataTransferService.setLoginToken(response.body.token);
              this.dataTransferService.setUserDetails(
                JSON.stringify(response.body.responseBody) || '',
              );
              const redirectTo =
                this.dataTransferService.getQRCodeUrl() || '/home';
              this.router.navigateByUrl(redirectTo);
              this.dataTransferService.removeQrCodeUrl();
            }
          },
          error: (response) => {
            this.loaderService.hide();
            this.errorMessage = response.error.responseMessage;
          },
        });
      }
    } else {
      this.errorMessage = 'please enter correct otp';
    }
  }

  businessVerifyResend() {
    this.loaderService.show();
    this.brandService
      .resendOtp(this.tokenService.getToken(), this.tokenService.getPurpose())
      .subscribe({
        next: (res: any) => {
          if (res.body.responseCode == 200) {
            this.loaderService.hide();
            this.resendOtpResponseMessage = res.body.responseMessage;
          }
        },
        error: (res) => {
          this.loaderService.hide();
          this.resendOtpResponseMessage = res.error.responseMessage;
        },
      });
    this.otpTimerService.resetTimer();
    this.otpTimerService.startTimer();
  }
}
