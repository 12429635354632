<div class="container-fluid px-3">
  <div class="mt-3 d-flex align-items-center justify-content-center p-0">
    <div class="container-fluid p-0 m-0">
      <div class="d-flex">
        <div class="col-4">
          <scremer-shimmer [shape]="'circle'" [width]="'76px'">
          </scremer-shimmer>
        </div>
        <div class="col-8">
          <div class="py-3">
            <scremer-shimmer
              [shape]="'rect'"
              width="100"
              [borderRadius]="'20px'"
              [height]="'20px'"
            ></scremer-shimmer>
          </div>
          <div class="pb-3">
            <scremer-shimmer
              [shape]="'rect'"
              width="100"
              [borderRadius]="'20px'"
              [height]="'20px'"
            ></scremer-shimmer>
          </div>
        </div>
      </div>

      <scremer-shimmer
        [shape]="'rect'"
        [width]="'173px'"
        [borderRadius]="'20px'"
        [height]="'14px'"
      ></scremer-shimmer>

      <p>
        <scremer-shimmer
          [shape]="'rect'"
          [width]="'173px'"
          [borderRadius]="'20px'"
          [height]="'14px'"
        ></scremer-shimmer>
      </p>
    </div>
  </div>
</div>
