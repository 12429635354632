<mat-card class="my-3 shadow border-radius p-3 px-4 mt-6">
  <div class="mb-2">
    <scremer-shimmer
      [shape]="'rect'"
      [width]="'120px'"
      [borderRadius]="'10px'"
      [height]="'15px'"
    ></scremer-shimmer>
  </div>
  <div class="m-0 p-0 mb-2">
    <scremer-shimmer
      [shape]="'rect'"
      [width]="'100'"
      [borderRadius]="'10px'"
      [height]="'15px'"
    ></scremer-shimmer>
  </div>
  <div>
    <scremer-shimmer
      [shape]="'rect'"
      [width]="'100'"
      [borderRadius]="'10px'"
      [height]="'15px'"
    ></scremer-shimmer>
  </div>
</mat-card>
