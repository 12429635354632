import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { CreateAScreamComponent } from '../../scream/create-a-scream/create-a-scream.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HateRateSliderComponent } from '../hate-rate-slider/hate-rate-slider.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile-and-hate-rate-slider',
  templateUrl: './profile-and-hate-rate-slider.component.html',
  styleUrls: ['./profile-and-hate-rate-slider.component.scss'],
})
export class ProfileAndHateRateSliderComponent implements OnInit {
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  @ViewChild(HateRateSliderComponent) hateRateSlider!: HateRateSliderComponent;
  userProfileImage: any;
  constructor(
    private readonly dataTransferService: DataTransferService,
    public dialog: MatDialog,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.userProfileImage = this.dataTransferService.getEditProfileImage();
  }
  getSliderValue(event: any) {
    this.dialog
      .open(CreateAScreamComponent, {
        data: { event: event, isSliderSelected: true },
        panelClass: ['scream-dialog-box', 'border-radius'],
        disableClose: true,
      })
      ?.afterClosed()
      .subscribe((event) => {
        event = this.hateRateSlider.reset();
      });
  }
}
