import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortNumberSuffix' })
export class Count implements PipeTransform {
  transform(value: any): any {
    let result: string;
    switch (true) {
      case value >= 1000000000:
        result = (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
        break;
      case value >= 1000000:
        result = (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        break;
      case value >= 1000:
        result = (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        break;
      default:
        result = value.toString();
        break;
    }
    return result;
  }
}
