<app-queue-header></app-queue-header>
<div class="total pt-3 mt-4">
  <div class="container d-flex justify-content-center align-items-center">
    <img
      class="welcome-queue"
      src="assets/images/welcome-queue.svg"
      alt="welcome-queue"
    />
  </div>

  <p class="wish fw-400 fs-24">Hello,</p>
  <p class="welcome fw-800 fs-40">Welcome!</p>
  <div>
    <form [formGroup]="welcomeForm" (ngSubmit)="welcome()">
      <!-- Full Name Field -->
      <mat-form-field
        class="w-100 d-flex justify-content-start align-content-start"
      >
        <mat-icon matPrefix class="grey-disable-color">account_circle</mat-icon>
        <mat-label>Full Name</mat-label>
        <input
          matInput
          placeholder="Full Name"
          type="text"
          formControlName="fullName"
          (input)="onFullNameInput()"
          autofocus
        />
        <mat-error
          *ngIf="welcomeForm.controls['fullName'].hasError('required')"
          class="lh-1"
        >
          Full Name <strong>cannot be empty</strong>
        </mat-error>
        <mat-error
          *ngIf="welcomeForm.controls['fullName'].hasError('pattern')"
          class="lh-1"
        >
          It should contain only alphabets
        </mat-error>
      </mat-form-field>

      <!-- Mobile Number Field -->
      <mat-form-field class="w-100">
        <mat-icon matPrefix class="grey-disable-color">phone</mat-icon>
        <mat-label>Mobile Number</mat-label>
        <input
          matInput
          placeholder="Mobile Number"
          type="tel"
          formControlName="mobileNumber"
          (input)="onMobileNumberInput()"
        />
        <mat-error
          *ngIf="welcomeForm.controls['mobileNumber'].hasError('required')"
          class="lh-1"
        >
          Mobile Number <strong>cannot be empty</strong>
        </mat-error>
        <mat-error
          *ngIf="welcomeForm.controls['mobileNumber'].hasError('pattern')"
          class="lh-1"
        >
          Please enter valid mobile number
        </mat-error>
      </mat-form-field>

      <div class="d-flex justify-content-center mt-2">
        <button
          mat-raised-button
          class="btn-primary pointer fw-600 fs-18 w-100 background-color lato-font-family white-color"
          type="submit"
          (click)="continue()"
          [disabled]="welcomeForm.invalid"
        >
          Continue
        </button>
      </div>
    </form>
  </div>
</div>
<app-queue-footer></app-queue-footer>
