<div class="container-fluid px-3">
  <div class="d-flex justify-content-start">
    <scremer-shimmer
      class="m-2"
      [shape]="'rect'"
      [width]="'70px'"
      [borderRadius]="'15px'"
      [height]="'15px'"
    ></scremer-shimmer>
    <scremer-shimmer
      class="m-2"
      [shape]="'rect'"
      [width]="'70px'"
      [borderRadius]="'15px'"
      [height]="'15px'"
    ></scremer-shimmer>
  </div>
  <app-scream-shimmer></app-scream-shimmer>
</div>
