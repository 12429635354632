<div class="pt-7">
  <div class="p-3">
    <mat-icon
      class="material-symbols-outlined pointer black-color"
      (click)="goBack()"
    >
      close
    </mat-icon>
  </div>
  <div class="d-grid gap-4">
    <div class="px-3 pb-1">
      <p class="fs-18 fw-500 black-color mb-3">Rate your Experience (1-5)</p>
      <div class="d-flex">
        <button
          *ngFor="let num of [1, 2, 3, 4, 5]"
          class="btn check-box me-3 btn-unchecked"
          [ngClass]="{
            'btn-checked': feedbackForm.get('rating')?.value === num,
          }"
          (click)="setRating(num)"
        >
          {{ num }}
        </button>
      </div>
    </div>

    <div class="px-3 pb-1">
      <h3 class="lh-sm black-color">
        What can we improve? (Select One or More)/What you loved the more(Select
        One or more)
      </h3>
      <div *ngFor="let option of checkBoxOptions">
        <div class="d-flex align-items-center pb-3">
          <input
            type="checkbox"
            class="form-check-input fs-20 m-0"
            [value]="option"
            (change)="onCheckBoxChange($event, option)"
          />
          <label class="black-color fs-14 fw-400 ps-2">{{ option }}</label>
        </div>
      </div>

      <form [formGroup]="feedbackForm" (ngSubmit)="submitForm()">
        <div class="mt-3">
          <mat-form-field appearance="fill" class="w-100 mb-2">
            <mat-icon class="grey-disable-color" matPrefix>
              <span class="material-symbols-outlined">
                account_circle
              </span></mat-icon
            >

            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="name" />
            <mat-error
              *ngIf="this.feedbackForm.controls['name'].hasError('required')"
            >
              Please enter name
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100 mb-2">
            <mat-icon
              class="material-icons-outlined grey-disable-color"
              matPrefix
              >phone</mat-icon
            >
            <mat-label>Mobile Number</mat-label>
            <input
              matInput
              placeholder="Mobile Number"
              formControlName="mobileNumber"
            />
            <mat-error
              *ngIf="
                this.feedbackForm.controls['mobileNumber'].hasError('required')
              "
            >
              Please enter mobile number
            </mat-error>
            <mat-error
              *ngIf="
                this.feedbackForm.controls['mobileNumber'].hasError('pattern')
              "
            >
              Please enter 10 digit mobile number
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100 mb-2">
            <mat-icon matPrefix class="grey-disable-color"
              >mail_outline</mat-icon
            >
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="email" />
            <mat-error
              *ngIf="this.feedbackForm.controls['email'].hasError('required')"
            >
              Please enter email
            </mat-error>
            <mat-error
              *ngIf="this.feedbackForm.controls['email'].hasError('email')"
            >
              Please enter valid email
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100 mb-2">
            <mat-icon matPrefix class="grey-disable-color">event_note</mat-icon>
            <mat-label>Event Name</mat-label>
            <input
              matInput
              placeholder="Event Name"
              formControlName="eventName"
            />
            <mat-error
              *ngIf="
                this.feedbackForm.controls['eventName'].hasError('required')
              "
            >
              Please enter event name
            </mat-error>
          </mat-form-field>
        </div>

        <p class="fs-16 fw-400 black-color m-0">
          We Value your feedback! Please share any additional comments or
          suggestions to help us improve
        </p>

        <mat-form-field appearance="outline" class="w-100 mt-3 pb-4">
          <textarea
            matInput
            #comments
            cdkTextareaAutosize
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="8"
            cols="15"
            placeholder="Type Here"
            formControlName="additionalComments"
            rows="3"
            maxlength="500"
          ></textarea>
          <mat-hint align="end">{{ comments.value.length }} / 500</mat-hint>
        </mat-form-field>

        <button
          type="submit"
          [disabled]="!isFormValid()"
          [ngClass]="{ 'disabled-button': !isFormValid() }"
          class="fw-600 fs-16 btn-primary background-color white-color lato-font-family px-4 py-2"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
