import { Component, Input } from '@angular/core';
import { DataTransferService } from '../../../core/service/data-transfer/data-transfer.service';

@Component({
  selector: 'app-search-history',
  templateUrl: './search-history.component.html',
  styleUrls: ['./search-history.component.scss'],
})
export class SearchHistoryComponent {
  @Input() search: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  constructor(private readonly dataTransferService: DataTransferService) {}
}
