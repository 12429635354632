<app-queue-header></app-queue-header>
<div
  class="p-3 responsive-h d-flex flex-column d-flex justify-content-center align-items-center"
>
  <div>
    <img src="../../../assets/images/thanks.svg" alt="business gratitide" />
    <p class="text-center fs-23 fw-600 pt-3">Thank You for your visit</p>
  </div>
</div>
<app-queue-footer></app-queue-footer>
