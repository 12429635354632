<div class="p-4">
  <div class="d-flex justify-content-start" *ngIf="data.scream !== 'delete'">
    <p class="m-0 fs-23 fw-500 lato-font-family">Delete Scream?</p>
  </div>
  <div class="d-flex justify-content-start" *ngIf="data.scream === 'delete'">
    <p class="m-0 fs-23 fw-500 lato-font-family">Delete Address?</p>
  </div>
  <div class="my-3" *ngIf="data.scream !== 'delete'">
    <p class="m-0 fw-400 fs-16 lato-font-family">
      Once you deleted the scream,it will be removed from your profile and all
      the timelines it appears
    </p>
  </div>
  <div class="my-3" *ngIf="data.scream === 'delete'">
    <p class="m-0 fw-400 fs-16 lato-font-family">
      Are you sure you want to delete address?
    </p>
  </div>
  <div class="d-flex justify-content-end pt-2">
    <button
      mat-raised-button
      class="br-color rounded-pill active-link lato-font-family fw-600 fs-14"
      (click)="closeDialog()"
    >
      Cancel
    </button>
    <button
      *ngIf="data.scream !== 'delete'"
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2"
      type="submit"
      #btnFocus="matButton"
      [autofocus]="btnFocus.focus()"
      (click)="confirm()"
    >
      Confirm
    </button>
    <button
      *ngIf="data.scream === 'delete'"
      mat-raised-button
      class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2"
      type="submit"
      #btnFocus="matButton"
      [autofocus]="btnFocus.focus()"
      (click)="confirmAddress()"
    >
      Confirm
    </button>
  </div>
</div>
