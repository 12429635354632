import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppConfig } from '../../config/app.config';
import { isPlatformServer } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Injectable()
export class DataTransferService {
  platformId: Object;
  hostname!: string;
  isPlatformBrowser: boolean | undefined;
  paymentSuccess: boolean = false;
  isDialogOpened: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public cookieService: SsrCookieService,
    private deviceDetectorService: DeviceDetectorService,
    private router: Router,
  ) {
    this.platformId = platformId;
  }

  jwt: any;
  userDetails: any;
  searchHistory: any;
  period: any;
  blobUrl = AppConfig.getBLOBURI();
  defaultImages = 'assets/images/rectangle-background.png';
  defaultImage = 'assets/images/no-profile.png';
  businessDefaultImage = 'assets/images/default_image_of_business.svg';
  screamDeleted = new EventEmitter<void>();
  private screamUpCountSubject = new Subject<number>();
  screamUpCount$: Observable<number> = this.screamUpCountSubject.asObservable();

  private followingCountSubject = new Subject<number>();
  followingCount$: Observable<number> =
    this.followingCountSubject.asObservable();
  private messageSource = new BehaviorSubject('true');
  currentMessage = this.messageSource.asObservable();

  private enableSearchSubject = new BehaviorSubject<boolean>(false);
  enableSearchCard = this.enableSearchSubject.asObservable();
  private imageUrlSource = new BehaviorSubject<string>(
    'assets/images/Group 485 obout.svg',
  );
  currentImageUrl = this.imageUrlSource.asObservable();

  updateImageUrl(newUrl: string) {
    this.imageUrlSource.next(newUrl);
  }

  updateScreamUpCount(count: number) {
    this.screamUpCountSubject.next(count);
  }
  updateFollowingCount(count: number) {
    this.followingCountSubject.next(count);
  }
  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  updateShowSearchCard(value: boolean) {
    this.enableSearchSubject.next(value);
  }

  isLoggedin() {
    if (isPlatformServer(this.platformId)) {
      this.jwt = this.cookieService.get('ScremerToken');
    } else {
      this.jwt = localStorage.getItem('ScremerToken');
    }
    if (this.jwt) {
      return true;
    } else {
      return false;
    }
  }

  isBusinessLoggedin() {
    if (isPlatformServer(this.platformId)) {
      this.jwt = this.cookieService.get('BusinessToken');
    } else {
      this.jwt = localStorage.getItem('BusinessToken');
    }
    if (this.jwt) {
      return true;
    } else {
      return false;
    }
  }
  getPaymentSuccess(): boolean {
    return this.paymentSuccess;
  }

  setPaymentSuccess(success: boolean): void {
    this.paymentSuccess = success;
  }
  setDialogOpened(opened: boolean) {
    this.isDialogOpened = opened;
  }

  getDialogOpened(): boolean {
    return this.isDialogOpened;
  }
  setLoginToken(jwt: any) {
    localStorage.setItem('ScremerToken', jwt);
    this.cookieService.set('ScremerToken', jwt);
  }

  setUserDetails(userDetails: any) {
    localStorage.setItem('UserDetails', userDetails);
  }

  getUserDetails() {
    if (isPlatformServer(this.platformId)) {
      return '{}';
    } else {
      return localStorage.getItem('UserDetails') || '{}';
    }
  }

  getUserToken() {
    if (isPlatformServer(this.platformId)) {
      return this.cookieService.get('ScremerToken');
    } else {
      return localStorage.getItem('ScremerToken');
    }
  }

  setBusinessToken(jwt: any) {
    localStorage.setItem('BusinessToken', jwt);
    this.cookieService.set('BusinessToken', jwt);
  }

  getBusinessToken() {
    if (isPlatformServer(this.platformId)) {
      return this.cookieService.get('BusinessToken');
    } else {
      return localStorage.getItem('BusinessToken');
    }
  }

  setPeriod(period: any) {
    this.period = period;
  }

  getPeriod(): string {
    return this.period;
  }

  setBrandDetails(brandDetails: any) {
    localStorage.setItem('BrandDetails', brandDetails);
  }

  setQrCodeUrl() {
    localStorage.setItem('qrCodeUrl', this.router.url);
  }

  getQRCodeUrl() {
    return localStorage.getItem('qrCodeUrl');
  }

  removeQrCodeUrl() {
    localStorage.removeItem('qrCodeUrl');
  }

  getBrandDetails(): any {
    if (isPlatformServer(this.platformId)) {
      return '{}';
    } else {
      return localStorage.getItem('BrandDetails') || '{}';
    }
  }

  getSearchHistory() {
    return localStorage.getItem('searchHistory');
  }

  getEditProfileImage() {
    const userDetailsString = this.getUserDetails();
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      return userDetails?.profileImage;
    }
  }

  getBusinessImage() {
    const brandDetailsString = this.getBrandDetails();
    if (brandDetailsString) {
      try {
        const brandDetails = JSON.parse(brandDetailsString);
        if (
          brandDetails.logoImage &&
          brandDetails.documentVerificationStatus === 'VERIFIED'
        ) {
          return this.blobUrl + brandDetails.logoImage;
        }
        return this.businessDefaultImage;
      } catch (error) {
        return this.businessDefaultImage;
      }
    }
    return null;
  }

  getProfileImage(filename: string | null) {
    if (filename) {
      return this.blobUrl + filename;
    } else {
      return this.businessDefaultImage;
    }
  }

  getUserSessionId() {
    const userDetailsString = this.getUserDetails();
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString) || '{}';
      return userDetails.sessionId;
    }
  }

  getBusinessSessionId() {
    const brandDetailsString = this.getBrandDetails();
    if (brandDetailsString) {
      const brandDetails = JSON.parse(brandDetailsString) || '{}';
      return brandDetails.sessionId;
    }
  }

  logDtoBody() {
    return {
      logDTO: {
        operatingSystem: this.deviceDetectorService.getDeviceInfo().os,
        deviceType: this.deviceDetectorService.getDeviceInfo().deviceType,
      },
    };
  }

  setVerificationIconColor(businessDetails: any | null = null) {
    let business = businessDetails;

    if (this.isBusinessLoggedin() && this.getBrandDetails) {
      business = JSON.parse(this.getBrandDetails() || '');
    }

    if (!business.subscription) {
      return '';
    }

    if (business.subscription === 'FREE') {
      return 'verification-color-blue';
    }

    if (business.subscription === 'BRONZE') {
      return 'verification-color-bronze';
    }

    if (business.subscription === 'SILVER') {
      return 'verification-color-silver';
    }

    if (business.subscription === 'GOLD') {
      return 'verification-color-gold';
    }

    return businessDetails.subscription;
  }
}
