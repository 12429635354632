import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  activeRoute: any;
  constructor(public readonly router: Router) {}
  ngOnit() {
    this.router.events.subscribe(() => {
      this.activeRoute = this.router.url;
    });
  }
  platforms = [
    {
      platformName: 'fa-facebook',
      platformUrl: 'https://www.facebook.com/scremer.official',
    },
    {
      platformName: 'fa-twitter',
      platformUrl: 'https://twitter.com/scremerdotcom',
    },
    {
      platformName: 'fa-instagram',
      platformUrl: 'https://www.instagram.com/scremerdotcom/',
    },
    {
      platformName: 'fa-linkedin',
      platformUrl: 'https://www.linkedin.com/company/scremer',
    },
    {
      platformName: 'fa-youtube-play',
      platformUrl: 'https://www.youtube.com/channel/UCdSkE7LDe_9jLPIcaaGj3Cw',
    },
  ];
  pageLinks = [
    {
      content: 'Terms and Conditions',
      routerLink: '/terms',
    },
    {
      content: 'About Us',
      routerLink: '/about-us',
    },
    {
      content: 'Privacy Policy',
      routerLink: '/terms',
    },
  ];
}
