import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-user-submit',
  templateUrl: './report-user-submit.component.html',
  styleUrls: ['./report-user-submit.component.scss'],
})
export class ReportUserSubmitComponent {
  constructor(private dialog: MatDialog) {}

  closeDialog() {
    this.dialog.closeAll();
  }
}
