<div class="container-fluid p-0 pt-7 pt-sm-8">
  <div
    class="w-100 landing-bg-color d-flex justify-content-center align-items-center"
  >
    <div
      class="main-container max-width p-16 px-5 d-flex flex-column justify-content-between align-items-center"
    >
      <h1 class="fw-700 fs-48 fs-36 line-height text-center mt-5 mb-0">
        How are you feeling?
      </h1>
      <h5 class="fw-600 fs-20 my-4 fs-24 line-height text-center">
        Tell us and we will make it heard.
      </h5>
      <div>
        <button
          mat-raised-button
          (click)="goToSignUp()"
          class="btn-primary background-color white-color lato-font-family px-4 mt-1 fs-16 fw-700"
        >
          Join now
        </button>
      </div>
      <div>
        <img
          class="w-100 p-0"
          alt="Scremer Business Consumer feedback Management"
          src="assets/images/business.svg"
        />
      </div>
    </div>
  </div>
  <div
    class="w-100 background-color d-flex justify-content-center align-items-center"
  >
    <div
      class="scream-it-container max-width row p-16 p-48 justify-content-center align-items-center margin text-center"
    >
      <div class="col-12 col-sm-6">
        <img
          class="w-100 p-0"
          alt="Customers Expressing Dissatisfaction and Sharing Product Reviews on Scremer"
          src="assets/images/Flying-iPhone.svg"
        />
      </div>
      <div class="col-12 col-sm-6 pt-3 pt-md-3">
        <h1 class="fw-700 fs-48 fs-36 line-height white-color">
          <span class="wrap">
            Scream it

            <img
              class="icon-size mb-2"
              src="assets/images/Flash.svg"
              alt="scremer-thunder"
            />
          </span>
        </h1>
        <p class="fw-600 fs-20 fs-24 line-height white-color">
          Scremer empowers individuals to share their diverse experiences,
          giving everyone a voice.
        </p>
      </div>
    </div>
  </div>
  <div
    class="w-100 d-flex justify-content-center align-items-center landing-bg-color"
  >
    <div
      class="insights-container max-width row justify-content-center align-items-center p-16 p-48 margin text-center"
    >
      <div class="col-12 col-sm-6">
        <h1 class="fw-700 fs-48 fs-36 line-height">Insights</h1>
        <p class="fw-600 fs-20 fs-24 line-height">
          We are providing concise and accurate analysis through
          <span class="active-link"> AI.</span>
        </p>
      </div>
      <div class="col-12 col-sm-6 ps-md-5 pt-3 pt-sm-3">
        <img
          class="w-100 p-0"
          alt="scremer-insights on customer feedback and issues"
          src="assets/images/Insights.svg"
        />
      </div>
    </div>
  </div>
  <div class="w-100 d-flex justify-content-center align-items-center">
    <div
      class="assurance-score-container max-width row justify-content-center align-items-center p-16 p-48 margin text-center"
    >
      <div class="col-12 col-sm-6">
        <img
          class="w-100 p-0"
          alt="Scremer Assurance Score Customer Problem Solving"
          src="assets/images/AssuranceScore.svg"
        />
      </div>
      <div class="col-12 col-sm-6 ps-md-5 pt-sm-3 pt-3">
        <div class="mb-3">
          <span class="fw-700 fs-48 fs-36 line-height"> Assurance </span>
          <span style="white-space: nowrap">
            <span class="fw-700 fs-48 fs-36 line-height"> Score </span>
          </span>
          <span class="fw-400 trademark fs-24">&trade; </span>
        </div>
        <p class="fw-600 fs-20 fs-24 line-height">
          Our unique scoring system evaluates companies based on their effort to
          solve customer problems.
        </p>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
