import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HospitalService {
  private api = AppConfig.getQUEUEAPIURI() + 'hospital/queue';

  constructor(private http: HttpClient) {}

  fetchTodayStatisticsForWaitingOrServing(queueStatus: any): Observable<any> {
    return this.http.get(this.api + `/${queueStatus}`, {
      observe: 'response',
    });
  }

  updateQueueStatusStartCancelCompleteDelete(queueStatus: any, queueUid: any) {
    return this.http.put(
      `${this.api}/${queueStatus}/${queueUid}`,
      {},
      {
        observe: 'response',
      },
    );
  }

  fetchQueueStatusWaitingOrServing(queueStatus: any) {
    return this.http.get(this.api + '/fetch' + `/${queueStatus}`, {
      observe: 'response',
    });
  }

  convertTo12HourFormat(time: string): string {
    if (!time) return '';

    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for midnight case

    return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
  }

  fetchQueueWaitingStatus(
    businessName: string,
    queueStatus: String,
  ): Observable<any> {
    return this.http.get(
      this.api + `/${businessName}/fetch/waiting-stats/${queueStatus}`,
      {
        observe: 'response',
      },
    );
  }
}
