import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { AppConfig } from '../../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class QueueWebSocketService {
  private queueStompClient!: Stomp.Client;
  private queueSubscriptions: Map<string, Stomp.Subscription> = new Map();
  private connectionPromise!: Promise<void>;

  private readonly QUEUE_SUBSCRIBE_PATH = '/update/fetch-waitlist';
  private readonly QUEUE_UNSUBSCRIBE_PATH = '/app/stop-updates';
  private readonly QUEUE_NOTIFICATION_TRIGGER = '/update/notification';

  constructor() {
    this.connectionPromise = this.connectToQueueSocket();
  }

  private connectToQueueSocket(): Promise<void> {
    return new Promise((resolve) => {
      const queueSocket = new SockJS(AppConfig.getQUEUEAPIURI() + 'queue');
      this.queueStompClient = Stomp.over(queueSocket);
      this.queueStompClient.heartbeat.outgoing = 2000;
      this.queueStompClient.debug = () => {};

      this.queueStompClient.connect({}, () => {
        console.log('Queue WebSocket Connected!');
        resolve();
      });
    });
  }

  public async subscribeQueue(callback: (message: any) => any) {
    console.log('Attempting to subscribe...');

    await this.connectionPromise;

    if (!this.queueStompClient || !this.queueStompClient.connected) {
      console.warn('Queue WebSocket not connected yet.');
      return;
    }

    if (this.queueSubscriptions.has(this.QUEUE_SUBSCRIBE_PATH)) {
      console.warn(`Already subscribed to: ${this.QUEUE_SUBSCRIBE_PATH}`);
      return;
    }

    const subscription = this.queueStompClient.subscribe(
      this.QUEUE_SUBSCRIBE_PATH,
      (message) => {
        callback(message);
      },
    );

    this.queueSubscriptions.set(this.QUEUE_SUBSCRIBE_PATH, subscription);
    console.log(`Subscribed to ${this.QUEUE_SUBSCRIBE_PATH}`);
  }

  public async subscribeQueueStatus(callback: (message: any) => any) {
    console.log('Attempting to subscribe...');

    await this.connectionPromise;

    if (!this.queueStompClient || !this.queueStompClient.connected) {
      console.warn('Queue WebSocket not connected yet.');
      return;
    }

    if (this.queueSubscriptions.has(this.QUEUE_NOTIFICATION_TRIGGER)) {
      console.warn(`Already subscribed to: ${this.QUEUE_NOTIFICATION_TRIGGER}`);
      return;
    }

    const subscription = this.queueStompClient.subscribe(
      this.QUEUE_NOTIFICATION_TRIGGER,
      (message) => {
        callback(message);
      },
    );

    this.queueSubscriptions.set(this.QUEUE_NOTIFICATION_TRIGGER, subscription);
    console.log(`Subscribed to ${this.QUEUE_NOTIFICATION_TRIGGER}`);
  }

  public async unsubscribeQueue() {
    await this.connectionPromise;

    if (!this.queueSubscriptions.has(this.QUEUE_SUBSCRIBE_PATH)) {
      console.warn(`Not subscribed to: ${this.QUEUE_SUBSCRIBE_PATH}`);
      return;
    }

    if (this.queueStompClient && this.queueStompClient.connected) {
      this.queueStompClient.send(this.QUEUE_UNSUBSCRIBE_PATH, {}, '');
      console.log(`Sent unsubscribe request to ${this.QUEUE_UNSUBSCRIBE_PATH}`);
    }

    const subscription = this.queueSubscriptions.get(this.QUEUE_SUBSCRIBE_PATH);
    if (subscription) {
      subscription.unsubscribe();
      this.queueSubscriptions.delete(this.QUEUE_SUBSCRIBE_PATH);
      console.log(`Unsubscribed from ${this.QUEUE_SUBSCRIBE_PATH}`);
    }
  }
}
