<div class="container-fluid text-center p-0 pt-7 pt-sm-8">
  <div class="container" *ngIf="!isContentVisible">
    <h4
      class="fs-18 fw-500 lato-font-family d-flex justify-content-center pt-5"
    >
      The invite link you are trying to access has been used or expired
    </h4>
    <div class="d-flex justify-content-center">
      <button
        (click)="goToSignup()"
        mat-raised-button
        class="btn-primary background-color lato-font-family white-color fw-500 fs-16 mb-3 mt-2 py-1"
      >
        Join Scremer
      </button>
    </div>
  </div>
  <div class="container mt-2 pt-4">
    <div class="row">
      <div
        class="col-12 col-lg-6 d-flex justify-content-center display-desktop display-desktop-only"
      >
        <img
          src="assets/images/Sign-Up-Image.svg"
          alt="login-image"
          class="img-fluid pt-3"
        />
      </div>
      <div class="col-12 col-lg-6 d-flex justify-content-center border-radius">
        <mat-card
          class="w-100 p-3 p-0 mt-1 mt-md-5 mt-lg-0 shadow card-border-radius box-shadow pt-lg-5 max-width py-3 pt-md-4 pb-md-3"
        >
          <div>
            <img src="assets/images/ScremerLogo.svg" alt="scremer-logo.svg" />
          </div>
          <form
            class="m-md-5 mt-md-0 mb-md-0"
            [formGroup]="signupForm"
            (ngSubmit)="signup()"
          >
            <div class="mt-3">
              <mat-form-field class="w-100">
                <mat-icon class="grey-disable-color" matPrefix
                  >person_outline</mat-icon
                >
                <mat-label>Full Name</mat-label>
                <input
                  matInput
                  placeholder="Full Name"
                  formControlName="fullName"
                />
                <mat-error
                  class="lh-1"
                  *ngIf="
                    this.signupForm.controls['fullName'].hasError('required')
                  "
                >
                  Please enter fullname
                </mat-error>
                <mat-error
                  class="lh-1"
                  *ngIf="
                    this.signupForm.controls['fullName'].hasError('pattern') &&
                    !signupForm.controls['fullName'].hasError('maxlength')
                  "
                  >It should contain only alphabets
                </mat-error>
                <mat-error
                  class="lh-1"
                  *ngIf="
                    this.signupForm.controls['fullName'].hasError(
                      'maxlength'
                    ) && !signupForm.controls['fullName'].hasError('pattern')
                  "
                  >It should contain less than 30 characters
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-icon matPrefix class="grey-disable-color"
                  >account_circle_outline</mat-icon
                >

                <mat-label>User Name</mat-label>
                <input
                  matInput
                  placeholder="User Name"
                  formControlName="userName"
                  (ngModelChange)="checkUser()"
                />
                <mat-hint
                  [ngClass]="{
                    success: isUserNameUnique,
                    error: !isUserNameUnique
                  }"
                  >{{ verifyUserNameResponseMessage }}</mat-hint
                >
                <mat-spinner
                  *ngIf="userNameLoader"
                  matSuffix
                  diameter="20"
                  class="mx-3"
                ></mat-spinner>
                <mat-error
                  class="lh-1"
                  *ngIf="
                    this.signupForm.controls['userName'].hasError('required')
                  "
                >
                  Please enter username
                </mat-error>
                <mat-error
                  class="lh-1"
                  *ngIf="
                    signupForm.controls['userName'].hasError('minlength') &&
                    !(
                      signupForm.controls['userName']?.hasError(
                        'periodAtStartEnd'
                      ) ||
                      signupForm.controls['userName']?.hasError(
                        'successivePeriods'
                      ) ||
                      signupForm.controls['userName']?.hasError(
                        'spacesNotAllowed'
                      ) ||
                      signupForm.controls['userName']?.hasError(
                        'invalidCharacters'
                      )
                    )
                  "
                >
                  Username should be 6 characters long
                </mat-error>
                <mat-error
                  class="lh-1"
                  *ngIf="
                    this.signupForm.controls['userName'].hasError(
                      'maxlength'
                    ) &&
                    !(
                      signupForm.controls['userName']?.hasError(
                        'periodAtStartEnd'
                      ) ||
                      signupForm.controls['userName']?.hasError(
                        'successivePeriods'
                      ) ||
                      signupForm.controls['userName']?.hasError(
                        'spacesNotAllowed'
                      ) ||
                      signupForm.controls['userName']?.hasError(
                        'invalidCharacters'
                      )
                    )
                  "
                  >It should contain less than 30 characters
                </mat-error>
                <mat-error
                  class="lh-1"
                  *ngIf="
                    signupForm.controls['userName']?.hasError(
                      'periodAtStartEnd'
                    )
                  "
                  >Username cannot start or end with a period</mat-error
                >
                <mat-error
                  class="lh-1"
                  *ngIf="
                    signupForm.controls['userName']?.hasError(
                      'successivePeriods'
                    )
                  "
                  >Username cannot have successive periods</mat-error
                >
                <mat-error
                  class="lh-1"
                  *ngIf="
                    signupForm.controls['userName']?.hasError(
                      'spacesNotAllowed'
                    )
                  "
                  >Username cannot contain spaces</mat-error
                >
                <mat-error
                  class="lh-1"
                  *ngIf="
                    signupForm.controls['userName']?.hasError(
                      'invalidCharacters'
                    ) && !signupForm.controls['userName'].hasError('required')
                  "
                  >Username can only contain letters, numbers, and
                  underscores</mat-error
                >
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-icon matPrefix class="grey-disable-color"
                  >mail_outline</mat-icon
                >

                <mat-label>Email</mat-label>
                <input
                  matInput
                  placeholder="Email"
                  formControlName="email"
                  (input)="clearVerifyEmailResponseMessage()"
                />
                <mat-hint class="error">{{
                  verifyEmailResponseMessage
                }}</mat-hint>
                <mat-error
                  class="m-1 lh-1"
                  *ngIf="
                    signupForm.controls['email'].hasError('pattern') &&
                    !signupForm.controls['email'].hasError('required')
                  "
                >
                  Please enter a valid email address
                </mat-error>
                <mat-error
                  *ngIf="signupForm.controls['email'].hasError('required')"
                >
                  Email is required
                </mat-error>
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-icon matPrefix class="grey-disable-color"
                  >lock_outline</mat-icon
                >
                <mat-label>Password</mat-label>
                <input
                  matInput
                  [type]="isHidePassword ? 'password' : 'text'"
                  placeholder="Password"
                  formControlName="password"
                />
                <mat-icon
                  matSuffix
                  class="grey-disable-color"
                  (click)="togglePasswordVisibility()"
                  >{{
                    isHidePassword ? "visibility_off" : "visibility"
                  }}</mat-icon
                >
                <mat-error
                  *ngIf="
                    this.signupForm.controls['password'].hasError('required')
                  "
                >
                  Password cannot be empty
                </mat-error>
                <mat-error
                  *ngIf="
                    this.signupForm.controls['password'].hasError(
                      'minlength'
                    ) && !signupForm.hasError('pattern')
                  "
                >
                  Password should be 6 characters long
                </mat-error>

                <mat-error
                  class="lh-1"
                  *ngIf="
                    this.signupForm.controls['password'].hasError('pattern') &&
                    !signupForm.controls['password'].hasError('minlength')
                  "
                  >Password should contain one uppercase, one lowercase, one
                  special character and one number
                </mat-error>
                <mat-error
                  *ngIf="
                    this.signupForm.controls['password'].hasError(
                      'maxlength'
                    ) && !signupForm.controls['password'].hasError('pattern')
                  "
                  >Password should contain less than 30 characters
                </mat-error>
              </mat-form-field>

              <div class="mt-4 text-center">
                <p>
                  By signing in , you agree to the
                  <a href="/terms and conditions" [routerLink]="['/terms']">
                    Terms and Conditions
                  </a>
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button
                mat-raised-button
                type="submit"
                [disabled]="!isFormValid"
                [ngClass]="{ 'disabled-button': !isFormValid }"
                class="btn-primary background-color w-100 lato-font-family white-color fw-600 fs-16 mb-3 mt-2 px-5 py-1"
              >
                Sign up
              </button>
            </div>
          </form>

          <app-google-sso-button></app-google-sso-button>
          <div class="d-flex mt-4 justify-content-center">
            <p class="fw-400 fs-14">Already having account ?</p>
            <a class="mx-2" [routerLink]="['/login']">Log in</a>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
