import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-block-user',
  templateUrl: './block-user.component.html',
  styleUrls: ['./block-user.component.scss'],
})
export class BlockUserComponent {
  userData: any;
  constructor(
    private dialogRef: MatDialogRef<BlockUserComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { scream: any; user: any; profile: any }
  ) {
    this.userData = data;
  }
  contentVisible!: boolean;

  closeDialog() {
    this.dialogRef.close(BlockUserComponent);
  }

  block(): void {
    this.dialogRef.close('block');
  }
}
