import { TokenService } from './../../core/service/tokenService/token.service';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { UploadImageComponent } from '../upload-image/upload-image.component';

@Component({
  selector: 'app-scream-toolbar',
  templateUrl: './scream-toolbar.component.html',
  styleUrls: ['./scream-toolbar.component.scss'],
})
export class ScreamToolbarComponent implements OnInit {
  @ViewChild(UploadImageComponent) uploadImages!: UploadImageComponent;
  screamImage: any[] = [];
  noOfUploadImages = 3;
  @Output() emitImages = new EventEmitter();
  constructor(private token: TokenService) {}
  ngOnInit(): void {
    this.token.setContent(true);
  }
  uploadImageToScream() {
    this.emitImages.emit();
  }
}
