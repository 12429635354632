import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../core/material/material.module';
import { BrandProfileShimmerComponent } from './brand-profile-shimmer/brand-profile-shimmer.component';
import { BrandShimmerComponent } from './brand-shimmer/brand-shimmer.component';
import { CategoriesShimmerComponent } from './categories-shimmer/categories-shimmer.component';
import { HomeShimmerComponent } from './home-shimmer/home-shimmer.component';
import { ScreamShimmerComponent } from './scream-shimmer/scream-shimmer.component';
import { AddressShimmerComponent } from './address-shimmer/address-shimmer.component';
import { InsightsShimmerComponent } from './insights-shimmer/insights-shimmer.component';
import { FaqShimmerComponent } from './faq-shimmer/faq-shimmer.component';
import { MatTabsShimmerComponent } from './mat-tabs-shimmer/mat-tabs-shimmer.component';
import { FollowerShimmerComponent } from './follower-shimmer/follower-shimmer.component';
import { NotificationShimmerComponent } from './notification-shimmer/notification-shimmer.component';
import { ResponseShimmerComponent } from './response-shimmer/response-shimmer.component';
import { ScremerShimmerModule } from 'scremer-shimmer';
import { PaymentHistoryShimmerComponent } from './payment-history-shimmer/payment-history-shimmer.component';
import { EventShimmerComponent } from './event-shimmer/event-shimmer.component';
import { EventSectionShimmerComponent } from './event-section-shimmer/event-section-shimmer.component';
@NgModule({
  declarations: [
    BrandProfileShimmerComponent,
    BrandShimmerComponent,
    CategoriesShimmerComponent,
    HomeShimmerComponent,
    ScreamShimmerComponent,
    AddressShimmerComponent,
    InsightsShimmerComponent,
    FaqShimmerComponent,
    MatTabsShimmerComponent,
    FollowerShimmerComponent,
    NotificationShimmerComponent,
    ResponseShimmerComponent,
    PaymentHistoryShimmerComponent,
    EventShimmerComponent,
    EventSectionShimmerComponent,
  ],
  imports: [NgbModule, CommonModule, MaterialModule, ScremerShimmerModule],
  exports: [
    BrandProfileShimmerComponent,
    BrandShimmerComponent,
    CategoriesShimmerComponent,
    HomeShimmerComponent,
    ScreamShimmerComponent,
    AddressShimmerComponent,
    InsightsShimmerComponent,
    FaqShimmerComponent,
    MatTabsShimmerComponent,
    FollowerShimmerComponent,
    NotificationShimmerComponent,
    ResponseShimmerComponent,
    PaymentHistoryShimmerComponent,
    EventShimmerComponent,
    EventSectionShimmerComponent,
  ],
})
export class ShimmerModule {}
