<ng-container *ngIf="currentState">
  <div class="top">
    <div class="container p-4">
      <mat-icon class="material-icons pointer" (click)="closeDialog()"
        >close</mat-icon
      >

      <h1 class="pt-3 fw-600">Forgot Password ?</h1>

      <h4 class="fs-16" *ngIf="currentRoutePath === userLogin">
        Please enter your email address to receive a code to reset the password
      </h4>

      <h4 class="fs-16" *ngIf="currentRoutePath === businessLogin">
        Please enter your registered email address to receive a code to reset
        the password
      </h4>

      <form [formGroup]="sendForm" (ngSubmit)="sendMail()">
        <mat-form-field class="w-100">
          <mat-icon class="grey-disable-color" matPrefix>mail_outline</mat-icon>

          <mat-label>Email</mat-label>

          <input
            matInput
            placeholder="Email"
            type="email"
            formControlName="email"
          />

          <mat-error
            *ngIf="
              this.sendForm.controls['email'].hasError('email') &&
              !this.sendForm.controls['email'].hasError('required')
            "
          >
            Please enter a valid email address
          </mat-error>

          <mat-error
            *ngIf="this.sendForm.controls['email'].hasError('required')"
          >
            Email address <strong> cannot be empty</strong>
          </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center mt-1">
          <button
            mat-raised-button
            [disabled]="sendForm.invalid"
            [ngClass]="{ 'disabled-button': sendForm.invalid }"
            class="button btn-primary background-color w-100 lato-font-family white-color fw-500 fs-16 px-5 py-1"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<app-otp-transfer *ngIf="!currentState"></app-otp-transfer>
