import { HateRateSliderComponent } from './../../shared/hate-rate-slider/hate-rate-slider.component';
import { HeaderService } from './../../core/service/header/header.service';
import { BottomNavBarService } from './../../core/service/bottomNavBar/bottom-nav-bar.service';
import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { ScreamService } from './../../core/service/screamService/scream.service';
import { catchError, map, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../core/service/snackBar/snackbar.service';
import { Scream } from './../../core/interfaces/scream';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterContentInit {
  @ViewChild(HateRateSliderComponent) hateRateSlider!: HateRateSliderComponent;
  homeShimmer = false;
  isSliderSelected: boolean = false;
  pageIndex = 0;
  pageSize = 10;
  homePageIndex = 0;
  homePageSize = 12;
  totalScreams: any;
  totalPages!: number;
  screamShimmer: boolean = false;
  screams: Scream[] = [];
  userProfile: any;
  errorMessage: any;
  userImage = 'userImage';
  date: any;
  queryParamValue: any;
  constructor(
    private readonly bottomService: BottomNavBarService,
    public readonly headerService: HeaderService,
    private readonly screamService: ScreamService,
    public readonly route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getUserScreams();
  }
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.headerService.show();
      this.bottomService.show();
    }, 0);
  }
  fetchScreams(
    pageIndex: number,
    pageSize: number,
    date: string,
    homePageIndex: number,
    homePageSize: number
  ) {
    return this.screamService
      .fetchHomeScream({
        pageIndex,
        pageSize,
        homePageIndex,
        homePageSize,
        date,
      })

      .pipe(
        map((response: any) => this.convertScreamResponseToArray(response)),
        catchError((error): any => this.handleErrorResponse(error))
      );
  }
  convertScreamResponseToArray(response: any) {
    return [
      response.body.responseBody.screamDTOList,
      response.body.responseBody.loginResponseDTO,
      response.body.responseBody.uuid,
      response.body.responseBody.totalItems,
      response.body.responseBody.pageIndex,
      response.body.responseBody.homePageIndex,
      response.body.responseBody.startDate,
      response.body.responseBody.totalPages,
    ];
  }
  handleErrorResponse(error: any) {
    if (error.status === 0) {
      this.snackbarService.openSnackBar(
        'Please try again later!',
        'error-snackbar'
      );
    } else if (error.error.responseCode === 404) {
      this.errorMessage = error.error?.responseMessage;
      this.snackbarService.openSnackBar(
        'All caught up. Please try again later!',
        'success-snackbar'
      );
    }
    this.homeShimmer = false;
    return of(this.errorMessage);
  }
  getUserScreams() {
    this.homeShimmer = true;
    this.fetchScreams(
      this.pageIndex,
      this.pageSize,
      this.date,
      this.homePageIndex,
      this.homePageSize
    ).subscribe((screams: any) => {
      this.screamShimmer = false;
      this.updatePageInformation(screams);
    });
  }
  updatePageInformation(screams: any) {
    this.screams = screams != undefined ? screams[0] : null;
    this.userProfile = JSON.parse(
      JSON.stringify(screams != undefined ? screams[1] : null)
    );
    this.totalScreams = screams != undefined ? screams[3] : null;
    this.homeShimmer = false;
    this.pageIndex++;
    // Commented code will be used later for fetching random screams
    // this.pageIndex = screams != undefined ? screams[4] + 1 : null;
    this.homePageIndex = screams != undefined ? screams[5] + 1 : null;
    this.date = screams != undefined ? screams[6] : null;
    this.totalPages = screams != undefined ? screams[7] : null;
  }
  onScroll() {
    if (this.totalPages >= this.pageIndex) {
      this.homePageIndex++;
      this.screamShimmer = true;
      this.fetchScreams(
        this.pageIndex,
        this.pageSize,
        this.date,
        this.homePageIndex,
        this.homePageSize
      ).subscribe((screams: any) => {
        this.pageIndex++;
        screams[0].forEach((element: any) => {
          if (!this.isObjectAlreadyExists(element)) {
            this.screams.push(element);
          }
        });
        this.screamShimmer = false;
        this.date = screams[6];
        this.homePageIndex = screams[5];
      });
    }
  }
  isObjectAlreadyExists(newObj: any): boolean {
    return this.screams.some((obj: any) => obj.suid === newObj.suid);
  }
}
