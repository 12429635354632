<ngx-gauge
  type="semi"
  [value]="assuranceScore"
  [thick]="gaugeThick"
  [size]="size"
  [max]="max"
  [foregroundColor]="'#2B60A4'"
  [backgroundColor]="'#b8b9ba'"
>
</ngx-gauge>
