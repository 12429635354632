import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DataTransferService } from '../../core/service/data-transfer/data-transfer.service';
import { ScreamService } from '../../core/service/screamService/scream.service';
import { SnackbarService } from '../../core/service/snackBar/snackbar.service';
import { TokenService } from '../../core/service/tokenService/token.service';
import { UserService } from '../../core/service/userService/user.service';
import { ReportSubmitComponent } from '../report-submit/report-submit.component';
import { ReportUserSubmitComponent } from '../report-user-submit/report-user-submit.component';
import { ComponentType } from '@angular/cdk/portal';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  name: any;
  reporter!: FormGroup;
  contentVisible!: boolean;
  constructor(
    public dialogRef: MatDialogRef<ReportComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { scream: any; user: any; profile: any },
    private screamService: ScreamService,
    private userService: UserService,
    private snackBarService: SnackbarService,
    private dataTransfer: DataTransferService,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.reporter = this.reportForm();
    this.contentVisible = this.tokenService.getContent();
  }

  isReportValid(): boolean {
    return this.reporter.value['report'].trim().length > 0;
  }

  reportForm(): FormGroup {
    return new FormGroup({
      report: new FormControl(''),
    });
  }

  closeDialog() {
    this.dialogRef.close(ReportComponent);
  }

  createScreamReportBody() {
    return {
      reportedSuid: this.data.scream.suid,
      report: this.reporter.value['report'].trim(),
    };
  }

  createUserReportBody() {
    return {
      reportedUuid: this.data.user,
      profile: this.data.profile?.userName,
      report: this.reporter.value['report'].trim(),
    };
  }

  reportScrem() {
    this.screamService.reportScream(this.createScreamReportBody()).subscribe({
      next: (data: any) => {
        if (data.body.responseCode == 201) {
          this.dialogRef.close(ReportComponent);
          this.reportedScream();
          this.dataTransfer.changeMessage('reported');
        }
      },
      error: (data) => {
        if (data.status == 0) {
          this.snackBarService.openSnackBar(
            'Internal server error. Please try again!',
            'error-snackbar'
          );
        } else {
          this.snackBarService.openSnackBar(
            data.error.responseMessage,
            'error-snackbar'
          );
        }
      },
    });
  }

  openReportDialog(component: ComponentType<any>): void {
    this.dialog.open(component, {
      width: '348px',
      panelClass: ['dialog-boxes'],
      disableClose: true,
    });
  }

  reportedScream(): void {
    this.openReportDialog(ReportSubmitComponent);
  }

  reportedUser(): void {
    this.openReportDialog(ReportUserSubmitComponent);
  }

  reportUser() {
    this.userService.reportUser(this.createUserReportBody()).subscribe({
      next: (data: any) => {
        if (data.body.responseCode == 201) {
          this.dialogRef.close(ReportComponent);
          this.reportedUser();
          this.dataTransfer.changeMessage('reported');
        }
      },
      error: (data) => {
        if (data.status == 0) {
          this.snackBarService.openSnackBar(
            'Internal server error. Please try again!',
            'error-snackbar'
          );
        } else {
          this.snackBarService.openSnackBar(
            data.error.responseMessage,
            'error-snackbar'
          );
        }
      },
    });
  }
}
