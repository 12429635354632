import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { DataTransferService } from '../data-transfer/data-transfer.service';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../config/app.config';
import { Router } from '@angular/router';
import { SnackbarService } from '../snackBar/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  updateNotificationSubject = new BehaviorSubject('true');
  updateNotificationSubject$ = this.updateNotificationSubject.asObservable();
  updateBrandNotificationSubject = new BehaviorSubject('any');
  updateBrandNotificationSubject$ =
    this.updateBrandNotificationSubject.asObservable();
  paymentSuccessSubject = new BehaviorSubject<{ message: string }>({
    message: '',
  });
  paymentSuccessSubject$ = this.paymentSuccessSubject.asObservable();
  stompClient: Stomp.Client;
  user: any;
  brand: any;

  constructor(
    private data: DataTransferService,
    private router: Router,
  ) {
    if (data) {
      try {
        this.user = JSON.parse(data.getUserDetails() || '{}');
      } catch (error) {
        console.error('Error parsing user details:', error);
        this.user = {};
      }

      try {
        this.brand = JSON.parse(data.getBrandDetails() || '{}');
      } catch (error) {
        console.error('Error parsing brand details:', error);
        this.brand = {};
      }
    }

    const socket = new SockJS(AppConfig.getAPIURI() + '/scremer-socket');
    this.stompClient = Stomp.over(socket);
    this.stompClient.heartbeat.outgoing = 2000;
    this.stompClient.debug = (str) => {};
    this.stompClient.connect({}, () => {
      this.subscribe('/notifications', (message: any) => {
        const messageBody = JSON.parse(message.body);
        if (
          this.data.isBusinessLoggedin() &&
          messageBody.paymentStatus === 'PAYMENT_SUCCESS' &&
          this.brand?.ubid === messageBody.brandUbid
        ) {
          this.paymentStatus({
            message: 'Payment Successful',
          });
        } else if (
          this.data.isBusinessLoggedin() &&
          messageBody.paymentStatus === 'EVENT_PAYMENT_SUCCESS' &&
          this.brand?.ubid === messageBody.brandUbid
        ) {
          this.paymentStatus({
            message: 'Event Payment Successful',
          });
        } else if (
          this.data.isLoggedin() &&
          this.user?.uuid === messageBody.uuid
        ) {
          this.updateNotification('hi');
        } else if (
          this.data.isBusinessLoggedin() &&
          this.brand?.ubid === messageBody.brandUbid
        ) {
          this.updateBrandNotification('send message');
        }
      });
    });
  }

  public subscribe(destination: string, callback: (message: any) => any): any {
    return this.stompClient.subscribe(destination, callback);
  }

  updateNotification(message: any) {
    this.updateNotificationSubject.next(message);
  }

  updateBrandNotification(message: any) {
    this.updateBrandNotificationSubject.next(message);
  }

  paymentStatus(message: any) {
    this.paymentSuccessSubject.next(message);
  }
}
