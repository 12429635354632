import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResponseComponent } from '../response/response/response.component';

export const routes: Routes = [
  {
    path: '',
    component: ResponseComponent,
    title: 'Preview',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScreamRoutingModule {}
