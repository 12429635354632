<div class="shimmer-container-wrapper">
  <div class="mb-2 shimmer-container">
    <scremer-shimmer
      [shape]="'rect'"
      [width]="'100%'"
      [borderRadius]="'10px'"
      [height]="'100px'"
      *ngFor="let _ of [1, 2, 3]"
    ></scremer-shimmer>
  </div>
</div>
