<div class="p-3">
  <div class="d-flex">
    <mat-icon class="material-icons pointer" (click)="closeBottomSheet()"
      >close</mat-icon
    >
    <div class="d-flex justify-content-center w-100">
      <p class="h5 text-center">Sharing Options</p>
    </div>
  </div>
  <div class="d-flex pt-2 pointer" (click)="copyLink()">
    <i class="material-icons">link</i>
    <p class="h5 px-3">Copy Link</p>
  </div>
</div>
