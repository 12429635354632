<div class="p-4">
  <div class="timer-container m-4">
    <div class="circular-timer">
      <svg viewBox="0 0 36 36" class="circular-chart blue">
        <path
          class="circle-bg"
          d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          [attr.stroke-dasharray]="getProgress()"
          d="M18 2.0845
     a 15.9155 15.9155 0 0 1 0 31.831
     a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="timer-text">{{ displayTime }}</text>
      </svg>
    </div>
  </div>
  <div>
    <p class="fw-400 fs-16 text-center mt-5 text-color">
      Proceed to Natural’s before the timer runs out. Once time’s up, you’ll go
      into the queue automatically
    </p>
  </div>
</div>
