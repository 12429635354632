<app-queue-header></app-queue-header>
<div class="container pb-5 p-0">
  <h1 class="fw-700 fs-20 pt-4 mb-0 px-3">Select Services</h1>

  <mat-tab-group
    [(selectedIndex)]="selectedIndex"
    (selectedIndexChange)="onTabChange($event)"
  >
    <mat-tab label="Male" class="fw-500 fs-16">
      <mat-divider></mat-divider>
      <div class="p-4">
        <div class="w-100 border white-div d-flex align-items-center py-2">
          <mat-icon
            class="material-icons-outlined pointer icon-color fs-20 mx-2 mt-1"
            >search</mat-icon
          >
          <input
            type="text"
            placeholder="Search Service"
            class="fw-400 fs-16 p-0 me-2"
            [(ngModel)]="searchText"
            (input)="filterServices()"
          />
          <mat-icon
            class="material-icons-outlined pointer icon-color fs-20 me-3 ms-2 mt-1"
            (click)="clearAllSearchResults()"
            *ngIf="searchText"
            >clear</mat-icon
          >
        </div>

        <div class="list-group p-3">
          <div
            class="border d-flex align-items-center py-3 px-2 my-2"
            *ngFor="let service of filteredServices"
            [class.active]="service.selected"
            (click)="toggleSelection(service)"
          >
            <mat-icon
              [ngClass]="[
                service.selected ? 'material-icons' : 'material-icons-outlined',
                service.selected ? 'active-link' : 'text-muted',
              ]"
              class="me-2 mt-1 fs-20"
            >
              check_circle
            </mat-icon>

            <span class="text-center flex-grow-1">{{ service.name }}</span>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Female" class="fw-500 fs-16">
      <mat-divider></mat-divider>
      <div class="p-4 box-size">
        <div class="w-100 border white-div d-flex align-items-center py-2">
          <mat-icon
            class="material-icons-outlined pointer icon-color fs-20 mx-2 mt-1"
            >search</mat-icon
          >
          <input
            type="text"
            placeholder="Search Service"
            class="fw-400 fs-16 p-0 me-2"
            [(ngModel)]="searchText"
            (input)="filterServices()"
          />
          <mat-icon
            class="material-icons-outlined pointer icon-color fs-20 me-3 ms-2 mt-1"
            (click)="clearAllSearchResults()"
            *ngIf="searchText"
            >clear</mat-icon
          >
        </div>

        <div class="list-group p-3">
          <div
            class="border d-flex align-items-center py-3 px-2 my-2"
            *ngFor="let service of filteredServices"
            [class.active]="service.selected"
            (click)="toggleSelection(service)"
          >
            <mat-icon
              [ngClass]="[
                service.selected ? 'material-icons' : 'material-icons-outlined',
                service.selected ? 'active-link' : 'text-muted',
              ]"
              class="me-2 mt-1 fs-20"
            >
              check_circle
            </mat-icon>

            <span class="text-center flex-grow-1">{{ service.name }}</span>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="fixed-bottom-container px-5 py-2">
    <div class="text-center">
      <button
        class="btn-primary fixed-width fw-600 fs-18 w-100 background-color lato-font-family white-color"
        [class.disabled]="!isAnyServiceSelected"
        (click)="sendSelectedServices()"
      >
        Continue
      </button>
    </div>
  </div>
</div>
<app-queue-footer></app-queue-footer>
