<mat-grid-list cols="4" class="mt-2" [gutterSize]="'8px'">
  <mat-grid-tile
    *ngFor="let tile of tiles; let imageUrl = index"
    [colspan]="tile.cols"
    [rowspan]="tile.rows"
  >
    <img
      class="img img_border pointer preview-image"
      [src]="tile.image"
      (click)="showImagePreviewCarousel(imageUrl)"
    />
  </mat-grid-tile>
</mat-grid-list>

<ng-template #imagePreview let-modal class="hide">
  <div
    class="modal-header preview-image background p-0"
    (click)="modal.dismiss()"
  >
    <mat-icon class="close white-color pointer">close</mat-icon>
  </div>
  <div
    class="d-flex justify-content-center align-items-center background modal-body"
  >
    <ngb-carousel
      #carousel
      [interval]="0"
      [showNavigationArrows]="tiles.length > 1"
      [activeId]="activeIndex"
    >
      <ng-template
        ngbSlide
        *ngFor="let tile of tiles; let index = index"
        [id]="'ngb-slide-' + index"
      >
        <img
          [src]="tile.image"
          alt="slide {{ tile.image }}"
          class="preview-image img-responsive"
      /></ng-template>
    </ngb-carousel>
  </div>
</ng-template>
