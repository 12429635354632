export const environment = {
  production: false,
  development: false,
  local: false,
  stage: true,
  apiUrl: 'https://staging-api.scremer.com/',
  restaurantAPI: 'https://dev-scremer-restaurant-service.azurewebsites.net/',
  baseUrl: 'https://staging.scremer.com/',
  blobUrl: 'https://scremerdev.blob.core.windows.net/images/',
  firebaseConfig: {
    apiKey: 'AIzaSyD17MKWCCFNvrtN6LRfv4Bvc8RTqBdCRIY',
    authDomain: 'scremer-dev.firebaseapp.com',
    projectId: 'scremer-dev',
    storageBucket: 'scremer-dev.appspot.com',
    messagingSenderId: '742992023316',
    appId: '1:742992023316:web:149cd24fe0794a6d9a7c01',
    measurementId: 'G-GELVGD3H7D',
  },
};
