<div *ngIf="bottomService.visible && loggedIn()">
  <div class="d-flex justify-content-center shadow-lg display-mobiles-only">
    <div class="button-container">
      <button
        mat-fab
        class="background-white-color"
        (click)="openScreamDialog()"
      >
        <img
          class="pointer fab-icon-size"
          src="assets/images/fabIconThunders.svg"
          alt="scremer thunder"
        />
      </button>
    </div>
  </div>
  <app-bottom-nav-bar-lister
    *ngIf="bottomService.visible"
  ></app-bottom-nav-bar-lister>
</div>

<mat-toolbar
  *ngIf="!loggedIn() && bottomService.visible && !isBusinessLoggedIn()"
  class="button-log background-white-color w-100 d-flex justify-content-evenly display-mobile-only px-5"
>
  <div>
    <span>
      <button
        (click)="login()"
        mat-raised-button
        class="btn button fw-700 lato-font-family py-2 fs-16 bottom-padding"
      >
        <div class="active-link px-4">Log in</div>
      </button>
    </span>
  </div>
  <div class="ms-3">
    <button
      (click)="openPlayStoreOrAppStore()"
      mat-raised-button
      class="btn btn-primary background-color fw-700 fs-16 lato-font-family py-2 white-color px-4 bottom-padding"
    >
      <div class="px-4">Get app</div>
    </button>
  </div>
</mat-toolbar>
