<div class="container-fluid my-3 p-0">
  <div class="row container-fluid m-0 p-0">
    <div class="col-2 mt-2">
      <scremer-shimmer [shape]="'circle'" [width]="'48px'"> </scremer-shimmer>
    </div>
    <div class="col-7 row mt-2 sm-px-3">
      <div class="px-0">
        <scremer-shimmer
          [shape]="'rect'"
          [width]="'180px'"
          [height]="'15px'"
          [borderRadius]="'10px'"
        ></scremer-shimmer>
      </div>
      <div class="px-0">
        <scremer-shimmer
          [shape]="'rect'"
          [width]="'120px'"
          [height]="'15px'"
          [borderRadius]="'10px'"
        ></scremer-shimmer>
      </div>
    </div>
  </div>
  <div class="container-fluid my-2">
    <scremer-shimmer
      [shape]="'rect'"
      width="100"
      [height]="'150px'"
      [borderRadius]="'10px'"
    ></scremer-shimmer>
  </div>
  <div class="container-fluid">
    <scremer-shimmer
      [shape]="'rect'"
      width="100"
      [height]="'15px'"
      [borderRadius]="'10px'"
    ></scremer-shimmer>
  </div>
</div>
