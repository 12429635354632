import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Scream } from './../../core/interfaces/scream';
import { BrandService } from './../../core/service/brandService/brand.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-brand-branch-location',
  templateUrl: './brand-branch-location.component.html',
  styleUrls: ['./brand-branch-location.component.scss'],
})
export class BrandBranchLocationComponent implements OnInit {
  @Input() selectedBranch!: string;

  @Output() branchSelected = new EventEmitter<string>();
  @Output() branchCleared = new EventEmitter<void>();

  homeShimmer = false;
  pageIndex = 0;
  pageSize = 10;
  totalScreams: any;
  screamShimmer: any;
  errorMessage: any;
  userImage = 'userImage';
  brandName: any;
  isContentVisible!: boolean;
  screamsPageIndex = 0;
  isFollow!: boolean;
  closedCount: any;
  reviewScreams: Scream[] = [];
  searchControl = new FormControl('');
  filteredBranches!: Observable<string[]>;
  branches!: string[];
  isDropdownOpen = false;
  staticBranches: any;
  isBranchesExist: boolean = false;

  constructor(private brandService: BrandService, public router: Router) {}

  ngOnInit(): void {
    this.fetchBranches();
    this.searchControl.valueChanges.subscribe((value: any) => {
      this.filterBranchOptions(value || '');
    });
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    if (this.isDropdownOpen == true) {
      let clickedElement = event.target as HTMLElement;
      if (clickedElement.id != 'dropdown') {
        this.isDropdownOpen = false;
        if (
          this.selectedBranch === null ||
          this.selectedBranch === undefined ||
          this.selectedBranch === ''
        ) {
          this.searchControl.setValue('');
        }
      }
    }
  }

  fetchBranches() {
    this.brandService.fetchBranches()?.subscribe(
      (response: any) => {
        if (response.body.responseCode == 200) {
          this.branches = response.body.responseBody.filter(
            (res: any) => res != null
          );

          if (this.isDashboard()) {
            this.branches.splice(0, 0, 'All Locations');
          }

          this.staticBranches = this.branches;
          this.isBranchesExist = true;
        }
      },
      (error: any) => {
        if (error.error?.responseCode === 404) {
          this.isBranchesExist = false;
        }
        this.errorMessage = error.errorMessage;
      }
    );
  }

  filterBranchOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.branches = this.staticBranches?.filter((option: any) =>
      option?.toLowerCase().includes(filterValue)
    );
    return this.branches;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  isDashboard(): boolean {
    if (this.router?.url == '/business/dashboard') {
      return true;
    }
    return false;
  }

  selectBranch(branch: string) {
    this.branchSelected.emit(branch);
    this.isDropdownOpen = false;
    this.selectedBranch = branch;
    this.branches = this.staticBranches;
  }

  clearBranch() {
    this.branchSelected.emit('');
    this.branchCleared.emit();
    this.branches = this.staticBranches;
    this.searchControl.setValue('');
    this.selectedBranch = '';
  }
}
