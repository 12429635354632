<div
  class="mt-6 container-fluid col-12 col-lg-6 d-flex align-items-center justify-content-center"
>
  <div class="container px-2">
    <div class="d-flex mt-2">
      <h1 class="mx-3">
        <scremer-shimmer
          [shape]="'rect'"
          [width]="'160px'"
          [height]="'30px'"
          [borderRadius]="'10px'"
        ></scremer-shimmer>
      </h1>
    </div>
    <div *ngFor="let number of [0, 1, 2, 3, 4, 5...8]">
      <mat-card class="brand-card m-2 d-flex rounded p-3">
        <div class="row">
          <div class="col-3 d-flex justify-content-center align-items-center">
            <div class="d-flex align-items-center h-100">
              <scremer-shimmer [shape]="'circle'" [width]="'60px'">
              </scremer-shimmer>
            </div>
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-8">
                <mat-card-title>
                  <h2 class="mb-0">
                    <scremer-shimmer
                      [shape]="'rect'"
                      [width]="'100px'"
                      [borderRadius]="'10px'"
                      [height]="'15px'"
                    ></scremer-shimmer>
                  </h2>
                </mat-card-title>
                <mat-card-content class="m-0 p-0 mb-2">
                  <p>
                    <scremer-shimmer
                      [shape]="'rect'"
                      [width]="'150px'"
                      [borderRadius]="'10px'"
                      [height]="'15px'"
                    ></scremer-shimmer>
                  </p>
                </mat-card-content>
              </div>
            </div>
            <mat-card-footer class="m-1 d-flex">
              <scremer-shimmer
                [shape]="'rect'"
                [width]="'80px'"
                [borderRadius]="'10px'"
              ></scremer-shimmer>
            </mat-card-footer>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
