<div class="slider-md-lg-only">
  <mat-divider></mat-divider>
  <div class="d-flex mt-3 mx-3">
    <img
      class="profile-image-40 rounded-object profile-border p-0"
      [src]="
        userProfileImage ? blobStorageLink + userProfileImage : defaultImage
      "
      alt="user"
    />
    <p class="scream-icon-color mt-2 mx-3">How are you feeling?</p>
  </div>
  <div class="px-3 pt-3">
    <app-hate-rate-slider
      (sliderValue)="getSliderValue($event)"
    ></app-hate-rate-slider>
  </div>
  <mat-divider></mat-divider>
</div>
