import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-assurance-meter',
  templateUrl: './assurance-meter.component.html',
  styleUrls: ['./assurance-meter.component.scss'],
})
export class AssuranceMeterComponent {
  @Input() assuranceScore: any;
  gaugeThick = 7;
  size = 60;
  max = 10;
}
