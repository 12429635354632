import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private api = AppConfig.getAPIURI() + 'notification';

  constructor(private http: HttpClient) {}
  fetchNotifications(params: any): Observable<any> {
    return this.http.get(this.api + '/get-notifications', {
      params,
      observe: 'response',
    });
  }
  fetchBrandNotifications(params: any): Observable<any> {
    return this.http.get(this.api + '/get-brand-notifications', {
      params,
      observe: 'response',
    });
  }

  fetchNotificationsCount(): Observable<any> {
    return this.http.get(this.api + '/unread', {
      observe: 'response',
    });
  }

  fetchBrandNotificationsCount(): Observable<any> {
    return this.http.get(this.api + '/brand-unread', {
      observe: 'response',
    });
  }
  markAsAllRead() {
    return this.http.put(this.api + '/mark-as-read', {
      observe: 'response',
    });
  }
  markAsAllBrandRead() {
    return this.http.put(this.api + '/mark-as-read-brand', {
      observe: 'response',
    });
  }
}
