import { Component, OnInit } from '@angular/core';
import { BottomNavBarService } from '../../core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from '../../core/service/header/header.service';
import { QueueService } from 'src/app/core/service/queue/queue.service';
import { LoaderService } from 'src/app/core/service/loader/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-join-queue',
  templateUrl: './join-queue.component.html',
  styleUrl: './join-queue.component.scss',
})
export class JoinQueueComponent implements OnInit {
  welcomePayload: any;
  fullName: any;
  mobileNumber: any;
  servicePayload: any;
  offeringsUid: any;
  stylistPayload: any;
  fuid: any;
  stylistUid: any;
  errorMessage: any;
  brandName: any;
  waitingData: any;
  joined: boolean = false;
  fcmToken: any;
  constructor(
    public readonly bottomService: BottomNavBarService,
    public readonly headerService: HeaderService,
    private queueService: QueueService,
    private loaderService: LoaderService,
    private router: Router,
  ) {}
  ngOnInit() {
    this.hideHeader();
    const fcmTokenString = localStorage.getItem('fcmToken');
    this.fcmToken = fcmTokenString ? JSON.parse(fcmTokenString) : null;
    this.retrieveStepOneData();
    this.retrieveStepTwoData();
    this.retrieveStepThreeData();
    this.sendQueueData();
    this.fetchWaitingData();
  }

  private retrieveStepOneData() {
    const stepOneData = localStorage.getItem('stepOneFormData');
    if (stepOneData) {
      this.welcomePayload = JSON.parse(stepOneData || '');
      this.fullName = this.welcomePayload.fullName;
      this.mobileNumber = this.welcomePayload.mobileNumber;
      this.fuid = this.welcomePayload.fuid;
      this.brandName = this.welcomePayload.brandName;
    }
  }
  private retrieveStepTwoData() {
    const stepTwoData = localStorage.getItem('stepTwoFormData');
    if (stepTwoData) {
      try {
        if (typeof stepTwoData === 'string') {
          this.servicePayload = JSON.parse(stepTwoData);
        } else {
          this.servicePayload = stepTwoData;
        }
        if (
          Array.isArray(this.servicePayload) &&
          this.servicePayload.length > 0
        ) {
          const uuids = this.servicePayload.map((item) => item.uuid);
          this.offeringsUid = uuids;
        }
      } catch (error) {
        console.error('Error parsing stepTwoData:', error);
      }
    }
  }
  private retrieveStepThreeData() {
    const stepThreeData = localStorage.getItem('stepThreeFormData');
    if (stepThreeData) {
      this.stylistPayload = JSON.parse(stepThreeData || '');
      this.stylistUid = this.stylistPayload.uuid;
    }
  }
  sendQueueData() {
    const queueData = {
      fullName: this.fullName,
      mobileNumber: this.mobileNumber,
      offeringsUid: this.offeringsUid,
      stylistUid: this.stylistUid,
      userUid: null,
      fcmToken: this.fcmToken ? this.fcmToken : null,
      flashcardUid: this.fuid,
    };
    return queueData;
  }
  hideHeader() {
    if (window.screen.width < 577) {
      setTimeout(() => {
        this.headerService.hide();
        this.bottomService.hide();
      }, 0);
    }
  }

  joinQueue(): void {
    localStorage.clear();
    const queueData = this.sendQueueData();
    this.queueService.joinQueue(queueData).subscribe({
      next: (response) => {
        if (response && response.body.responseCode === 201) {
          this.router.navigateByUrl('queue/waiting');
          return true;
        }
      },
      error: (error) => {
        this.errorMessage = error.message || 'An unknown error occurred';
      },
    });
  }
  fetchWaitingData() {
    this.loaderService.show();
    this.queueService.fetchWaitingStatus(this.brandName).subscribe({
      next: (response) => {
        if (response && response.body.responseCode === 200) {
          this.loaderService.hide();
          this.waitingData = response.body.responseBody;
        }
      },
      error: (error) => {
        this.loaderService.hide();
        this.errorMessage = error.message || 'An unknown error occurred';
      },
    });
  }
}
