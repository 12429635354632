<app-queue-header></app-queue-header>
<div class="p-3">
  <div class="pb-3">
    <app-back-arrow></app-back-arrow>
  </div>
  <div class="py-3">
    <p class="pb-3 m-0 fw-700 fs-20 black-color">Select Date and Time</p>
    <p class="m-0 fw-400 fs-14 scream-icon-color">
      Please select the date and time for booking your appointment
    </p>
  </div>
  <div class="py-3">
    <div class="form-container">
      <form [formGroup]="slotForm">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Date</mat-label>
          <input
            matInput
            formControlName="date"
            [matDatepicker]="singleDatePicker"
            [matDatepickerFilter]="filterSingleDate"
            (click)="singleDatePicker.open()"
          />
          <mat-datepicker-toggle
            matPrefix
            [for]="singleDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #singleDatePicker></mat-datepicker>
          <mat-error *ngIf="slotForm.get('date')?.hasError('required')">
            Please select date
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-icon matPrefix class="grey-disable-color"
            ><span class="material-symbols-outlined"> timer </span></mat-icon
          >
          <mat-label>Time</mat-label>

          <mat-select formControlName="time" required>
            <mat-option
              *ngIf="availableTimes.length === 0"
              class="font-color py-3 my-1"
            >
              No slots available, all slots are completely filled
            </mat-option>
            <mat-option *ngFor="let time of availableTimes" [value]="time">
              {{ time }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="slotForm.get('time')?.hasError('required')">
            Please select time
          </mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center mt-32">
          <button
            mat-raised-button
            class="btn-primary pointer fw-600 fs-18 w-100 lato-font-family white-color"
            type="submit"
            [ngClass]="{
              'invalid-btn': slotForm.invalid,
              'valid-btn': slotForm.valid,
            }"
            [disabled]="slotForm.invalid"
            (click)="clicked()"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<app-queue-footer></app-queue-footer>
