<div class="px-3 pl-32">
  <img src="assets/images/verified.svg" alt="brandCreated.svg" />
  <h1 class="m-0 pb-2 font-color pt-3 fs-16 fw-700 lato-font-family">
    Profile reported successfully
  </h1>

  <p class="m-0 fw-400 fs-16 lato-font-family justify-text">
    Thank you for reporting the profile. Your feedback helps us maintain a
    positive environment for all users
  </p>

  <div class="pt-4">
    <button
      mat-raised-button
      #btnFocus="matButton"
      [autofocus]="btnFocus.focus()"
      type="submit"
      (click)="closeDialog()"
      class="btn-primary d-flex justify-content-end background-color white-color lato-font-family py-2 px-3 fs-16 fw-500 w-100"
    >
      Okay
    </button>
  </div>
</div>
