import { HeaderService } from './../../core/service/header/header.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ScreamService } from './../../core/service/screamService/scream.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  providers: [Location],
})
export class PageNotFoundComponent {
  constructor(
    public location: Location,
    private readonly headerService: HeaderService,
    private screamService: ScreamService
  ) {
    this.headerService.show();
  }

  goBack() {
    this.location.back();
  }
}
