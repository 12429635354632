<div class="pt-7 padding-top-56">
  <app-profile-and-hate-rate-slider></app-profile-and-hate-rate-slider>
  <app-home-shimmer *ngIf="homeShimmer"></app-home-shimmer>
</div>

<div [infiniteScrollDistance]="2" infiniteScroll (scrolled)="onScroll()">
  <div *ngIf="!homeShimmer">
    <div class="mb-6 mb">
      <div *ngFor="let scream of screams; index as i">
        <app-scream [scream]="scream"></app-scream>
        <div *ngIf="screams.length - 1 > i">
          <mat-divider></mat-divider>
        </div>
      </div>
      <app-scream-shimmer *ngIf="screamShimmer"></app-scream-shimmer>
    </div>
  </div>
</div>
