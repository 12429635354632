<div class="d-flex justify-content-center mt-6">
  <img src="assets/images/page-not-found.svg" alt="page-not-found image" />
</div>

<h4 class="fs-18 fw-500 lato-font-family d-flex justify-content-center pt-5">
  The page you are looking for does not exist
</h4>

<div class="d-flex justify-content-center">
  <button
    (click)="goBack()"
    mat-raised-button
    class="btn-primary background-color lato-font-family white-color fw-500 fs-16 mb-3 mt-2 py-1"
  >
    Go back
  </button>
</div>
