import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceSelectionComponent } from './service-selection/service-selection.component';
import { WaitingComponent } from './waiting/waiting.component';
import { StylistSelectionComponent } from '../user/stylist-selection/stylist-selection.component';
import { WelcomeQueueComponent } from '../shared/welcome-queue/welcome-queue.component';
import { JoinQueueComponent } from '../user/join-queue/join-queue.component';
import { ThankYouComponent } from '../shared/thank-you/thank-you.component';

export const routes: Routes = [
  {
    path: 'service',
    component: ServiceSelectionComponent,
    canActivate: [],
    title: 'service-selection',
  },
  {
    path: 'waiting',
    component: WaitingComponent,
    canActivate: [],
    title: 'Waitlist',
  },
  {
    path: 'stylist-selection',
    component: StylistSelectionComponent,
    canActivate: [],
    title: 'stylist-selection',
  },
  {
    path: 'brand/:brandname',
    component: WelcomeQueueComponent,
    canActivate: [],
    title: 'welcome-queue',
  },
  {
    path: 'join-queue',
    component: JoinQueueComponent,
    canActivate: [],
    title: 'join-queue',
  },
  {
    path: 'thanks',
    component: ThankYouComponent,
    canActivate: [],
    title: 'be-happy',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QueueRoutingModule {}
