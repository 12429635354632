<div
  class="mt-6 container-fluid col-12 col-lg-6 d-flex align-items-center justify-content-center"
>
  <div class="container mt-2">
    <h1 class="mb-0">
      <scremer-shimmer
        [shape]="'rect'"
        [width]="'160px'"
        [height]="'30px'"
        [borderRadius]="'10px'"
      ></scremer-shimmer>
    </h1>
    <div class="row my-3">
      <div
        [ngClass]="{ 'display-mobile-only': true }"
        class="col-4 col-md-3 px-2 my-1"
        *ngFor="
          let number of [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17...21
          ]
        "
      >
        <scremer-shimmer
          [shape]="'rect'"
          width="100"
          [height]="'105px'"
          [borderRadius]="'10px'"
        >
          <mat-card
            class="text-centerd-flex align-items-center bg-grey py-6 py-md-5"
          >
          </mat-card>
        </scremer-shimmer>
      </div>
      <div
        [ngClass]="{ 'display-desktop-only': true }"
        class="col-4 col-md-3 px-2 my-1"
        *ngFor="
          let number of [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17...21
          ]
        "
      >
        <scremer-shimmer
          [shape]="'rect'"
          [width]="'140px'"
          [height]="'140px'"
          [borderRadius]="'10px'"
        >
          <mat-card
            class="text-centerd-flex align-items-center bg-grey py-6 py-md-5"
          >
          </mat-card>
        </scremer-shimmer>
      </div>
    </div>
  </div>
</div>
