import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private firebaseApp = initializeApp(environment.firebaseConfig);
  private messaging = getMessaging(this.firebaseApp);

  constructor() {}

  registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/assets/firebase-messaging-sw.js')
        .then((registration) => {
          // ✅ Pass service worker registration to Firebase Messaging
          this.requestPermission(registration);
        })
        .catch((error) =>
          console.error('Service Worker registration failed:', error),
        );
    } else {
      console.warn('Service Workers are not supported in this browser.');
    }
  }

  requestPermission(registration: ServiceWorkerRegistration) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        this.getToken(registration);
      } else {
        console.error('Notification permission denied');
      }
    });
  }

  private getToken(registration: ServiceWorkerRegistration) {
    getToken(this.messaging, {
      vapidKey:
        'BInjdqkSDwmWkLB5YYq6QPEtmzdnHinungkUV71c5UJzX0KFnCgvzC2SVdnAZH98qtz3rd4h5JzesKPv_ziUuXo',
      serviceWorkerRegistration: registration, // ✅ Use the registered SW
    })
      .then((token) => {
        if (token) {
          console.log('fcmToken', token);
          localStorage.setItem('fcmToken', JSON.stringify(token));
          return token;
          // Send token to backend for push notifications
        } else {
          console.warn(
            'No registration token available. Request permission to generate one.',
          );
        }
      })
      .catch((err) => console.error('Error getting FCM token:', err));
  }

  receiveMessages() {
    onMessage(this.messaging, (payload) => {
      console.log('Foreground Message Received:', payload);
      alert(`Notification: ${payload.notification?.title}`);
    });
  }
}
