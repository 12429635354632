<div *ngFor="let number of [0, 1]">
  <div class="display-mobile-only p-0 my-2">
    <div class="container-fluid my-3">
      <div class="row container-fluid m-0 p-0">
        <div class="col-2 p-0 mt-2">
          <scremer-shimmer [shape]="'circle'" [width]="'40px'">
          </scremer-shimmer>
        </div>
        <div class="col-7 row mt-2 p-0 container-fluid">
          <div class="p-0">
            <scremer-shimmer
              [shape]="'rect'"
              [width]="'140px'"
              [height]="'15px'"
              [borderRadius]="'10px'"
            ></scremer-shimmer>
          </div>
          <div class="p-0">
            <scremer-shimmer
              [shape]="'rect'"
              [width]="'100px'"
              [height]="'15px'"
              [borderRadius]="'10px'"
            ></scremer-shimmer>
          </div>
        </div>
        <div class="col-3 mt-2">
          <scremer-shimmer
            [shape]="'rect'"
            [width]="'90px'"
            [height]="'20px'"
            [borderRadius]="'10px'"
          ></scremer-shimmer>
        </div>
      </div>
      <div class="container-fluid p-0">
        <scremer-shimmer
          [shape]="'rect'"
          width="100"
          [height]="'150px'"
          [borderRadius]="'10px'"
        ></scremer-shimmer>
      </div>
      <div class="container-fluid py-2 px-0">
        <scremer-shimmer
          [shape]="'rect'"
          width="100"
          [height]="'15px'"
          [borderRadius]="'10px'"
        ></scremer-shimmer>
      </div>
    </div>
  </div>

  <div class="display-desktop-only p-0">
    <div class="container-fluid my-3 p-0">
      <div class="row container-fluid m-0 p-0">
        <div class="col-2 mt-2 pad-scream">
          <scremer-shimmer [shape]="'circle'" [width]="'48px'">
          </scremer-shimmer>
        </div>
        <div class="col-7 row mt-2 px-0">
          <div class="px-0">
            <scremer-shimmer
              [shape]="'rect'"
              [width]="'180px'"
              [height]="'15px'"
              [borderRadius]="'10px'"
            ></scremer-shimmer>
          </div>
          <div class="px-0">
            <scremer-shimmer
              [shape]="'rect'"
              [width]="'120px'"
              [height]="'15px'"
              [borderRadius]="'10px'"
            ></scremer-shimmer>
          </div>
        </div>
        <div class="col-3 mt-2 px-0">
          <scremer-shimmer
            [shape]="'rect'"
            [width]="'140px'"
            [height]="'20px'"
            [borderRadius]="'10px'"
          ></scremer-shimmer>
        </div>
      </div>
      <div class="container-fluid my-2">
        <scremer-shimmer
          [shape]="'rect'"
          width="100"
          [height]="'150px'"
          [borderRadius]="'10px'"
        ></scremer-shimmer>
      </div>
      <div class="container-fluid">
        <scremer-shimmer
          [shape]="'rect'"
          width="100"
          [height]="'15px'"
          [borderRadius]="'10px'"
        ></scremer-shimmer>
      </div>
    </div>
  </div>
</div>
