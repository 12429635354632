import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OtpTimerService {
  public timer: any;
  public timerDisplay = '30 sec';
  public timerCount = 30;
  public showResendOTPButton = false;

  startTimer() {
    this.timer = setInterval(() => {
      if (this.timerCount > 0) {
        this.timerCount--;
        this.timerDisplay = `${this.timerCount} sec`;
      } else {
        this.showResendOTPButton = true;
        clearInterval(this.timer);
      }
    }, 1000);
  }

  resetTimer() {
    this.timerCount = 30;
    this.showResendOTPButton = false;
    this.timerDisplay = '30 sec';
  }

  isResendOTPVisible() {
    return this.showResendOTPButton;
  }
  isBusinessResendOTPVisible() {
    return this.showResendOTPButton;
  }
}
