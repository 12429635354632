<div *ngFor="let number of [1, 2, 3]">
  <div class="container-fluid py-3">
    <scremer-shimmer
      [shape]="'rect'"
      [width]="'180px'"
      [borderRadius]="'20px'"
      [height]="'20px'"
    ></scremer-shimmer>
  </div>
  <div class="container-fluid pb-3">
    <scremer-shimmer
      [shape]="'rect'"
      width="100"
      [borderRadius]="'10px'"
      [height]="'120px'"
    ></scremer-shimmer>
  </div>
</div>
