import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BottomNavBarService } from 'src/app/core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from 'src/app/core/service/header/header.service';
import { QueueService } from 'src/app/core/service/queue/queue.service';

@Component({
  selector: 'app-service-selection',
  standalone: false,
  templateUrl: './service-selection.component.html',
  styleUrl: './service-selection.component.scss',
})
export class ServiceSelectionComponent implements OnInit {
  selectedGender: string = 'male';
  selectedIndex: number = 0;
  searchText: string = '';
  services: any[] = [];
  filteredServices: any[] = [];
  welcomePayload: any;
  errorMessage: any;

  constructor(
    private router: Router,
    private queueService: QueueService,
    private headerService: HeaderService,
    private bottomService: BottomNavBarService,
  ) {}

  ngOnInit(): void {
    this.hideHeader();
    this.retrieveStepOneData();
  }

  hideHeader() {
    setTimeout(() => {
      this.headerService.hide();
      this.bottomService.hide();
    }, 0);
  }

  private retrieveStepOneData() {
    const stepOneData = localStorage.getItem('stepOneFormData');
    if (stepOneData) {
      this.welcomePayload = JSON.parse(stepOneData || '');
      this.fetchOfferings(this.welcomePayload.brandName);
    }
  }

  fetchOfferings(brandName: any) {
    this.queueService.fetchOfferings(brandName)?.subscribe({
      next: (response) => {
        if (response.body.responseCode === 200) {
          this.services = (response.body.responseBody || [])
            .filter((item: { status: string }) => item.status === 'AVAILABLE')
            .map((item: any) => ({
              ...item,
              selected: false,
            }));

          this.retrieveSelectedServices();
          this.filterServices();
        }
      },
      error: (data) => {
        this.errorMessage =
          data.error.responseMessage || 'Error fetching offerings';
        console.error('Error fetching offerings:', data);
      },
    });
  }

  retrieveSelectedServices() {
    const selectedServices = localStorage.getItem('stepTwoFormData');
    if (selectedServices) {
      const parsedSelectedServices = JSON.parse(selectedServices);
      this.services.forEach((service) => {
        const isServiceSelected = parsedSelectedServices.some(
          (selectedService: any) => selectedService.uuid === service.uuid,
        );
        service.selected = isServiceSelected;
      });
    }
  }

  toggleSelection(service: any): void {
    service.selected = !service.selected;
    const selectedServices = this.services.filter(
      (service) => service.selected,
    );
    localStorage.setItem('stepTwoFormData', JSON.stringify(selectedServices));
    this.filterServices();
  }

  onTabChange(index: number): void {
    this.selectedIndex = index;
    this.selectedGender = index === 0 ? 'male' : 'female';
    this.filterServices();
  }

  filterServices(): void {
    this.filteredServices = this.services.filter(
      (service) =>
        service.gender?.toLowerCase() === this.selectedGender &&
        service.name.toLowerCase().includes(this.searchText.toLowerCase()),
    );
  }

  clearAllSearchResults() {
    this.searchText = '';
    this.filterServices();
  }

  get isAnyServiceSelected(): boolean {
    return this.services.some((service) => service.selected);
  }

  sendSelectedServices(): void {
    const selectedServices = this.services.filter(
      (service) => service.selected,
    );
    localStorage.setItem('stepTwoFormData', JSON.stringify(selectedServices));
    this.router.navigateByUrl('queue/stylist-selection');
  }
}
