<app-queue-header></app-queue-header>
<div class="p-3 mt-2">
  <app-back-arrow></app-back-arrow>
</div>
<div class="container">
  <p class="title d-flex justify-content-start align-items-start fw-700 fs-20">
    Select Stylist
  </p>
  <p
    class="description w-100 mb-20 d-flex justify-content-start align-items-start text-start fw-400 fs-14"
  >
    By using the drop down button provided below you can select the stylist
    according to your choice and will make sure that he/she is available at your
    service for the booked slot.
  </p>

  <div class="dropdown-container pt-2 mb-3" (click)="toggleDropdown()">
    <div
      [ngClass]="{ 'theme-border': isDropdownOpen }"
      class="dropdown-header p-10 d-flex justify-content-between align-items-center border-radius pointer"
    >
      <span [ngClass]="{ 'default-text': !selectedStylist }">
        {{ selectedStylist || "Select" }}
      </span>
      <span class="material-symbols-outlined me-2"> stat_minus_1 </span>
    </div>

    <ul
      class="dropdown-options w-100 p-10 border-radius"
      *ngIf="isDropdownOpen"
    >
      <li
        *ngFor="let stylist of list"
        (click)="selectStylist(stylist); isDropdownOpen = false"
        class="dropdown-item fs-16 py-3 fw-400 pointer"
      >
        {{ stylist.name }}
      </li>
    </ul>
  </div>
  <img
    class="illustration w-100"
    src="assets/images/que-stylist.svg"
    alt="Illustration"
  />
</div>
<div class="button-container d-flex justify-content-center">
  <button
    (click)="onSkipClick()"
    class="btn-primary w-100 p-10 pointer background-color lato-font-family white-color fw-500 p-2 fs-16"
  >
    Skip
  </button>

  <button
    class="btn-continue w-100 fs-16 p-10 pointer"
    [disabled]="!selectedStylist"
    (click)="continue()"
    [ngStyle]="{
      'background-color': selectedStylist ? '#2b60af' : '#979797',
    }"
  >
    Continue
  </button>
</div>
<app-queue-footer></app-queue-footer>
